import { FC } from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import tw from "twin.macro";
import { MONTH_NAMES } from "src/constants";
import { BlockFlowerCollectionFieldsFragment } from "src/generated/datocms-types";
import { FlowerCollectionSection } from "src/features/FlowerCollectionSection";

export type BlockFlowerCollectionProps = {
  data: BlockFlowerCollectionFieldsFragment;
};
export const BlockFlowerCollection: FC<BlockFlowerCollectionProps> = ({
  data,
}) => {
  const collections = data.collection ? [data.collection] : [];
  const currentMonth = new Date().getMonth();
  const activeMonth = MONTH_NAMES[currentMonth].toLowerCase();
  const flowerCollection = collections.find((collection) => {
    return collection.month?.toLowerCase() === activeMonth;
  }) as BlockFlowerCollectionFieldsFragment["collection"];
  const flowerList = flowerCollection?.flowerList || [];
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {flowerList.length > 0 && (
        <FixedContainer css={tw`container mx-auto px-4`}>
          <FlowerCollectionSection flowerList={flowerList} />
        </FixedContainer>
      )}
    </>
  );
};
