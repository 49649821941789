/* eslint-disable react/function-component-definition */
import { FC } from "react";
import { BlockFeatureQuoteFieldsFragment } from "src/generated/datocms-types";
import tw from "twin.macro";
import {
  BlockSpacingContainer,
  BlockSpacingContainerProps,
} from "src/shared/components/BlockSpacingContainer";

export type BlockFeatureQuoteProps = {
  data: BlockFeatureQuoteFieldsFragment;
};
export const BlockFeatureQuote: FC<BlockFeatureQuoteProps> = ({ data }) => {
  return (
    <BlockSpacingContainer
      spacing={data.blockSpacing as BlockSpacingContainerProps["spacing"]}
    >
      <div css={tw`text-center px-4 md:px-0`}>
        <h4
          css={tw`mb-4 text-2xl sm:text-4xl font-roboto leading-tight px-4 sm:px-0`}
        >
          {data.title}
        </h4>
        <blockquote
          css={tw`font-playfair text-lg md:text-xl max-w-4xl mx-auto`}
        >
          <p>&ldquo;{data.quote}&rdquo;</p>
          <p css={tw`block mt-4 not-italic`}>&mdash;&nbsp;{data.author}</p>
        </blockquote>
        <span css={tw`block bg-bloomsy-red h-1 mb-6 w-12 mx-auto mt-4`}>
          &nbsp;
        </span>
      </div>
    </BlockSpacingContainer>
  );
};
