/* eslint-disable react/function-component-definition */
import { FC } from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockTextImageButtonFieldsFragment } from "src/generated/datocms-types";
import { ImagesTextButton } from "./ImagesTextButton";
import { ImageTextButton } from "./ImageTextButton";

export type BlockFeatureTextImageButtonProps = {
  data: BlockTextImageButtonFieldsFragment;
};

export type ImagSection = {
  title: string;
  description: string;
  url: string;
  alt: string;
};
export const BlockFeatureTextImageButton: FC<
  BlockFeatureTextImageButtonProps
> = ({ data }) => {
  const { hasAnImageAndText } = data;
  return (
    <FixedContainer>
      {hasAnImageAndText ? (
        <ImageTextButton data={data} />
      ) : (
        <ImagesTextButton data={data} />
      )}
    </FixedContainer>
  );
};
