import { FC } from "react";
import tw from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockProseColumnFieldsFragment } from "src/generated/datocms-types";

export type BlockProseColumnProps = {
  data: BlockProseColumnFieldsFragment;
};
export const BlockProseColumn: FC<BlockProseColumnProps> = ({ data }) => {
  if (!data.firstColumn) return null;
  return (
    <FixedContainer css={tw`flex flex-wrap`}>
      <div
        css={[
          tw`py-2 md:py-10 prose prose-sm max-w-full md:pr-4`,
          !data.secondColumn && tw`w-full`,
          data.secondColumn && tw`w-full md:w-2/4`,
        ]}
        dangerouslySetInnerHTML={{ __html: data.firstColumn }}
      />
      {data.secondColumn && (
        <div
          css={tw`py-2 md:py-10 prose prose-sm w-full max-w-full md:w-2/4 md:pl-4`}
          dangerouslySetInnerHTML={{ __html: data.secondColumn }}
        />
      )}
    </FixedContainer>
  );
};
