import { DiscountCardFieldsFragment } from "src/generated/datocms-types";
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";

type CouponCardProps = {
  data: DiscountCardFieldsFragment;
};
export const CouponCard: FC<CouponCardProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const {
    productName,
    discountCode,
    discountToApply,
    disclaimer,
    text,
    image,
  } = data;
  const title = "-special discount-";
  const codeText = "Use code at checkout:";
  const infoText = `${text} $${discountToApply} off`;

  const imageCard = {
    src: `${image?.url}?w=300&h=300`,
    width: !isDesktop ? 150 : 180,
    height: !isDesktop ? 150 : 180,
    alt: image?.alt || "",
  };
  return (
    <div
      css={[
        tw`font-roboto rounded-2xl h-full bg-bloomsy-lightgray p-2.5 sm:p-5 space-y-10`,
      ]}
    >
      <div css={tw`relative border border-gray-300  text-center`}>
        <div
          css={tw`w-full uppercase font-roboto text-xs p-2 text-gray-800 border-b`}
        >
          <span css={[{ letterSpacing: "0.5em" }]}>{title}</span>
        </div>

        <div
          css={tw`flex w-full justify-center uppercase font-bold divide-x divide-gray-300 border-b text-sm sm:(text-xl) md:(text-2xl)`}
        >
          <div css={tw`flex flex-shrink items-center w-7/12 p-1 md:(h-24 p-6)`}>
            <span css={tw`w-full`}>{productName}</span>
          </div>
          <div css={tw`flex items-center  w-1/5 md:w-5/12 lg:w-1/2 xl:w-1/3`}>
            <div
              css={tw` w-full font-roboto sm:text-xl md:text-6xl text-bloomsy-red`}
            >
              <span css={tw` ml-2 sm:text-xl  md:text-4xl`}>$</span>
              <span css={tw`tracking-tighter sm:text-xl  md:text-7xl`}>
                {discountToApply}
              </span>
            </div>
            <div
              css={[
                tw` ml-1 sm:(text-lg) md:(text-3xl transform -rotate-90 -ml-2) text-black `,
              ]}
            >
              off
            </div>
          </div>
        </div>
        <div
          css={tw` p-0.5 sm:(p-6 h-48 space-y-14)  md:h-56 w-full text-left space-y-2`}
        >
          <div
            css={tw`capitalize text-sm mb-2 sm:text-base md:text-lg font-bold  text-gray-700`}
          >
            {codeText}
          </div>
          <span
            css={tw`uppercase font-roboto text-sm p-1 sm:(text-base) md:(p-2 text-lg) border border-red-600 `}
          >
            {discountCode}
          </span>
          <div
            css={tw` w-full text-sm sm:(w-4/6 p-1)  md:(text-base p-2) lg:(w-1/2)`}
          >
            {infoText}
          </div>
          <div
            css={tw`relative flex justify-center sm:(absolute -right-5 top-12) md:top-28 `}
          >
            <Image
              css={tw`opacity-90`}
              src={imageCard.src}
              width={imageCard.width}
              height={imageCard.height}
              quality={IMAGE_QUALITY}
              objectFit="cover"
              layout="intrinsic"
              alt={imageCard.alt}
            />
          </div>
        </div>
      </div>
      <div css={tw`flex justify-center pb-4`}>
        <div
          css={tw`w-10/12 text-xs text-center pt-6 md:(text-base )  border-t-2 border-dashed border-gray-400 `}
        >
          {disclaimer}
        </div>
      </div>
    </div>
  );
};
