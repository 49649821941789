/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImagSection } from "src/ui/components/BlockFeatureTextImagesButton";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { FC,useRef,useEffect, useState } from "react";
import { getBasicFourSixtyscript } from "src/shared/utils";
import {instagramSliderMobileScript, instagramSliderMobileStyle } from "./embed";

export const MobileSlider: FC= () => {
    const [renderInstragramEl, setRenderInstagramEl] = useState(false);
    const fourSixtyWidget = useRef<HTMLDivElement>(null);
    const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const [instagramStyle, setInstagramStyle] = useState("");
  const [hydrationCompleted, setHydrationCompleted] = useState(false);
  
   useEffect(() => {
    setHydrationCompleted(true);
  }, []); 
  useEffect(() => {
   
    if (hydrationCompleted) {
            const script = getBasicFourSixtyscript();
            const currentFourSixtyWidget = fourSixtyWidget.current;
            script.src = instagramSliderMobileScript.src;
            script.setAttribute("data-theme", instagramSliderMobileScript.dataTheme);
            script.setAttribute(
                "data-page-size", "6");
            fourSixtyWidget.current?.appendChild(script);
                 return () => {
                    currentFourSixtyWidget?.removeChild(script);
                }
                
          }
      },[isDesktop, renderInstragramEl, setRenderInstagramEl, setInstagramStyle, hydrationCompleted]);
  return (
      <div
          id="MobileSlider"
          ref={fourSixtyWidget}
          dangerouslySetInnerHTML={{ __html:instagramSliderMobileStyle }}
          css={tw`mt-2`} />
  );
};
