import { FC } from "react";
import Image from "next/legacy/image";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { ProductFieldsFragment, ProductFieldsShortFragment } from "src/generated/datocms-types";
import { freeShippingIcon, slugsPrefixMap } from "src/services/datocms/constants";
import { IMAGE_QUALITY } from "src/constants";
import { copy } from "src/features/ProductForm/constants";
import { getProductRecurringLabel } from "../utils/products";
import { ProductTag } from "../types";
import { formatShopifyPrice } from "../helpers";

type Props = {
 product: ProductFieldsFragment | ProductFieldsShortFragment;
};
export const ProductCard: FC<Props> = ({ product }) => {
    const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
    const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
    const isXl = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
    const hasStemsValue = product.bouquetSize?.trim() !== "-";
         const recurringFrequency = getProductRecurringLabel(
          product.title ?? "",
          product.tags ?? ""
        );
         const hasFreeShipping = product.tags?.find((tag: ProductTag) => {
    return tag?.slug?.includes(freeShippingIcon);
  });
        const productUrl = `${slugsPrefixMap.product}${product.slug}`;
        const salesPrice =
          formatShopifyPrice(
            product?.shopifyProduct.compareAtPriceRange?.minVariantPrice ?? 0
          ) ?? "";
        const hasSalesPrice =
          Number(
            product?.shopifyProduct?.compareAtPriceRange?.minVariantPrice?.amount ?? 0
          ) > 0;
        const minPrice =
          formatShopifyPrice(
            product?.shopifyProduct?.priceRange?.minVariantPrice ?? 0
          ) ?? "";
          const smallerDeskDimensions = isXl ? 295 : 240; 
          const smallerDeskDimensionsH = isXl ? 280 : 260; 
          const productImageSize = {
            w: isLargeDesktop ? 333 : smallerDeskDimensions,
            h: isLargeDesktop ? 331 : smallerDeskDimensionsH,
          };
  return (
    <div
      css={tw`flex relative items-center snap-start shrink-0  justify-center bg-bloomsy-lightgray h-auto lg:h-[373px] xl:(h-[411px])`}
      key={product.id}
    >
      <a href={productUrl}>
        <div css={tw`pt-2 lg:pt-0`}>
        <Image
           src={`${product.gallery[0].url}?w=${500}&h=${500}&crop=focalpoint`}
            width={productImageSize.w}
           height={productImageSize.h}
           quality={IMAGE_QUALITY}
           layout="intrinsic"
           alt="product"
            css={tw`object-cover `}
      />
        </div>
        
        <div css={tw`pb-2 lg:pb-0`}>
         {hasFreeShipping && (
          <div css={tw`w-5/6 capitalize leading-7`}>
            <span css={[tw`font-roboto block`, {color:"#41A317"}]}>{copy.freeShipping.toLowerCase()}</span>
          </div>
          )}
          <div css={[!hasFreeShipping && tw`pt-2`, tw`w-full`]}>
            <span css={tw`capitalize font-roboto`}>{product.title}</span>
          </div>
          <div css={[tw`leading-7`, tw`w-full`]}>
            <span>{minPrice}</span>
            {hasSalesPrice && (
              <span css={tw`ml-1 text-base text-gray-500 line-through`}>
                {salesPrice}
              </span>
            )}
            {product.isSubscription && (<span css={tw`lowercase`}>/{recurringFrequency}</span>)}
              <div>
                {product.isSubscription && hasStemsValue && (
                  <span css={tw`capitalize font-roboto text-sm inline text-bloomsy-midgray leading-6`}>
                    {`Includes approx. ${product.bouquetSize} stems`}
                  </span>
                )}
              </div>
          </div>
        </div>  
      </a>
    </div>
  );
};
