import * as React from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { BlockInstagramFullFieldsFragment } from "src/generated/datocms-types";
import { getBasicFourSixtyscript } from "src/shared/utils";
import { instagramFullPageScript, instagramFullPageStyle } from "./embed";

export type BlockFeatureInstagramFullProps = {
  data: BlockInstagramFullFieldsFragment;
};
export const BlockFeatureInstagramFull: React.FC<BlockFeatureInstagramFullProps> =
  () => {
    const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
    const isMobile = useMediaQuery(`(max-width: ${twTheme`screens.sm`})`);
    const fourSixtyWidget = React.useRef<HTMLDivElement>(null);
    const instagramStyle = `${instagramFullPageStyle}`;
    React.useEffect(() => {
      const container = document.querySelector(`div[id^="fs_"]`);
      if (isDesktop) {
        container?.classList.remove("fs-narrow-timeline");
        container?.classList.add("fs-normal-timeline");
      } else if (isMobile) {
        container?.classList.remove("fs-wide-timeline");
        container?.classList.remove("fs-narrow-timeline");
      } else {
        container?.classList.remove("fs-normal-timeline");
        container?.classList.add("fs-narrow-timeline");
      }
    }, [isDesktop, isMobile]);
    React.useEffect(() => {
      const script = getBasicFourSixtyscript();
      const currentFourSixtyWidget = fourSixtyWidget.current;
      script.src = instagramFullPageScript.src;
      script.setAttribute("data-theme", instagramFullPageScript.dataTheme);
      script.setAttribute(
        "data-page-size",
        instagramFullPageScript.dataPageSize || ""
      );
      fourSixtyWidget.current?.appendChild(script);
      return () => {
        currentFourSixtyWidget?.removeChild(script);
      };
    }, []);
    return (
      <FixedContainer>
        <div
          ref={fourSixtyWidget}
          dangerouslySetInnerHTML={{ __html: instagramStyle }}
          css={tw`mt-2`}
        />
      </FixedContainer>
    );
  };
