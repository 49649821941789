import { FC } from "react";
import tw from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureDiscountCardFieldsFragment } from "src/generated/datocms-types";
import { CouponCard } from "./CouponCard";

export type BlockFeatureDiscountProps = {
  data: BlockFeatureDiscountCardFieldsFragment;
};
export const BlockFeatureDiscount: FC<BlockFeatureDiscountProps> = ({
  data,
}) => {
  return (
    <FixedContainer>
      <div css={[tw`flex w-full flex-wrap lg:flex-nowrap justify-center`]}>
        {data.couponCard?.map((c) => {
          return (
            <div key={c?.id} css={tw`flex-shrink w-96 lg:w-1/3 m-2`}>
              <CouponCard data={c!} />
            </div>
          );
        })}
      </div>
    </FixedContainer>
  );
};
