/* eslint-disable react/function-component-definition */
import { FC, useState } from "react";
import tw from "twin.macro";
import {
  BlockFlowerItemMonthlyFieldsFragment,
  BlockFlowerItemWeeklyFieldsFragment,
  Maybe,
} from "src/generated/datocms-types";
import { slugsPrefixMap } from "src/services/datocms/constants";
import { Card } from "../ProductCenterPage/Card";

type Props = {
  flowerList:
    | Maybe<
        Maybe<
          | BlockFlowerItemWeeklyFieldsFragment
          | BlockFlowerItemMonthlyFieldsFragment
        >[]
      >
    | undefined;
};
export const FlowerCollectionSection: FC<Props> = ({ flowerList }) => {
  const monthlyFlowerList:
    | Maybe<BlockFlowerItemMonthlyFieldsFragment>[]
    | undefined = flowerList?.filter(
    (flower) => flower?._modelApiKey === "flower_item_monthly"
  ) as BlockFlowerItemMonthlyFieldsFragment[];
  const weeklyFlowerList = flowerList?.filter(
    (flower) => flower?._modelApiKey === "flower_item_weekly"
  ) as BlockFlowerItemWeeklyFieldsFragment[];
  const planFrequencies = ["MONTHLY", "WEEKLY"];
  const [activeFrequency, setActiveFrequency] = useState(planFrequencies[0]);
  const handleChangeFrequency = (frequency: string) => {
    setActiveFrequency(frequency);
  };
  return (
    <div css={tw`mt-4 lg:mt-8`}>
      {flowerList && flowerList?.length > 0 ? (
        <div
          css={tw`p-4 flex items-center overflow-auto w-full space-x-2 justify-center`}
        >
          {planFrequencies.map((planFrequency) => {
            return (
              <button
                onClick={() => handleChangeFrequency(planFrequency)}
                type="button"
                key={planFrequency}
                css={[
                  tw`font-roboto uppercase tracking-wider bg-white px-3 py-1 rounded-full text-xs border border-gray-200 whitespace-nowrap focus:outline-none`,
                  activeFrequency === planFrequency &&
                    tw`bg-gray-500 text-white`,
                ]}
              >
                {`${planFrequency} PLANS`}
              </button>
            );
          })}
        </div>
      ) : null}
      <div css={tw`flex flex-wrap`}>
        {activeFrequency === "MONTHLY"
          ? monthlyFlowerList?.map((flowerItem) => {
              const cardData = {
                title: flowerItem?.flower?.product?.title || "",
                name: flowerItem?.flower?.name || "",
                imageUrl: flowerItem?.flower?.primaryImage?.url || "",
                alt: flowerItem?.flower?.primaryImage?.alt ?? "",
                imageXPosition: flowerItem?.flower?.primaryImage?.focalPoint?.x,
                imageYPosition: flowerItem?.flower?.primaryImage?.focalPoint?.y,
                slug: flowerItem?.flower?.slug || "",
                slugsPrefix: slugsPrefixMap.flower_page,
                hasBadge: flowerItem?.flower?.hasBadge,
                badge: flowerItem?.flower?.badge?.url || "",
              };

              return <Card key={flowerItem?.id} cardData={cardData} />;
            })
          : null}
        {activeFrequency === "WEEKLY"
          ? weeklyFlowerList?.map((flowerItem) => {
              const title = flowerItem?.weekNumber
                ? `WEEK ${flowerItem?.weekNumber}`
                : flowerItem?.weekDate;
              const cardData = {
                title: title || "",
                name: flowerItem?.flower?.name || "",
                imageUrl: flowerItem?.flower?.primaryImage?.url || "",
                alt: flowerItem?.flower?.primaryImage?.alt ?? "",
                imageXPosition: flowerItem?.flower?.primaryImage?.focalPoint?.x,
                imageYPosition: flowerItem?.flower?.primaryImage?.focalPoint?.y,
                slug: flowerItem?.flower?.slug || "",
                slugsPrefix: slugsPrefixMap.flower_page,
                hasBadge: flowerItem?.flower?.hasBadge,
                badge: flowerItem?.flower?.badge?.url || "",
              };

              return <Card key={flowerItem?.id} cardData={cardData} />;
            })
          : null}
      </div>
    </div>
  );
};
