/* eslint-disable react/no-array-index-key */
import { IconButton } from "@mui/material";
import { IoClose } from "react-icons/io5";
import tw from "twin.macro";
import { useCurrentFilterSelection } from "src/features/Cart/state";

export const FilterList = () => {
  const { currentFilterSelection, addCurrentFilterSelection } =
    useCurrentFilterSelection();

  const handleClickClearOne = (filterTagId: number) => {
    const removeFilterProducts = currentFilterSelection?.filter(
      (f) => f.tagId !== filterTagId
    );

    if (removeFilterProducts && removeFilterProducts.length > 0)
      addCurrentFilterSelection([...removeFilterProducts]);
    else addCurrentFilterSelection(undefined);
  };

  return (
    <div
      css={[
        tw`grid grid-cols-3 pt-3 mt-5`,
        (currentFilterSelection?.length ?? 0) > 0 &&
          tw`border-t border-[#E9E9EB]`,
      ]}
    >
      {currentFilterSelection?.map((filter, index) => {
        return (
          <div
            key={index}
            css={tw`flex items-center text-[#666666] font-roboto text-sm tracking-normal leading-3 capitalize mt-2`}
          >
            <div>
              <span>{filter.filterName}</span>
            </div>

            <div>
              <IconButton
                onClick={() => handleClickClearOne(filter.tagId)}
                size="large"
                css={tw`p-0 pl-0.5`}
              >
                <IoClose size={20} />
              </IconButton>
            </div>
          </div>
        );
      })}
    </div>
  );
};
