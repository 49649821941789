/* eslint-disable react/function-component-definition */

import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureTestimonialFieldsFragment } from "src/generated/datocms-types";
import { FeatureTestimonial } from "src/ui/components/BlockFeatureTestimonial/FeatureTestimonial";
import Image from "next/legacy/image";
import { IMAGE_QUALITY } from "src/constants";
import { useMediaQuery } from "@mui/material";

export type BlockFeatureTestimonialProps = {
  data: BlockFeatureTestimonialFieldsFragment;
};
export const BlockFeatureTestimonial: FC<BlockFeatureTestimonialProps> = ({
  data: { content, hasTestimonyWithImage, image, testimony },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const imageURL = image?.url;
  const imageSize = [
    !isDesktop ? { width: 300, height: 300 } : { width: 400, height: 400 },
  ];
  return (
    <FixedContainer>
      {hasTestimonyWithImage && imageURL && testimony.length > 0 && (
        <div
          css={[
            tw` w-full lg:ml-40 flex flex-wrap justify-center lg:justify-start`,
          ]}
        >
          <div css={[tw` w-full lg:w-2/5 flex justify-center`]}>
            <div css={[tw`relative w-1/3`, imageSize]}>
              <Image
                css={tw`object-cover rounded-full`}
                src={imageURL}
                layout="fill"
                quality={IMAGE_QUALITY}
                priority
                alt={image?.alt ?? ""}
              />
            </div>
          </div>
          <div
            css={tw`w-full lg:w-1/2 flex mt-6 lg:mt-0 lg:justify-center xl:justify-start`}
          >
            <div css={tw`lg:w-5/6 xl:w-3/4 `}>
              <blockquote
                css={[
                  tw`font-playfair text-xl  lg:text-4xl  mb-2 text-center lg:text-left`,
                ]}
              >
                <q>{testimony[0].quote}</q>
              </blockquote>
              <p
                css={tw`mt-4 xl:mt-6 font-roboto text-xs font-bold uppercase text-center lg:text-left`}
              >
                &mdash;&nbsp;{testimony[0].author}
              </p>
            </div>
          </div>
        </div>
      )}
      {!hasTestimonyWithImage && (
        <div css={tw`flex flex-wrap mt-8`}>
          {content &&
            content.map((record) => {
              if (!record) return null;
              return <FeatureTestimonial data={record} key={record.id} />;
            })}
        </div>
      )}
    </FixedContainer>
  );
};
