/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { FC, useEffect, useState } from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import {
  collectionResult,
  getCollectionByTags,
  getCollectionProductsByTags,
} from "src/shared/utils/products";
import tw, { theme as twTheme } from "twin.macro";
import {
  FlowerShopSection,
  FlowerShopSectionItems,
} from "src/features/FlowerShopPage/styled";
import {
  BlockPlantCollectionFieldsFragment,
  PlantPageFieldsFragment,
} from "src/generated/datocms-types";
import { slugsPrefixMap } from "src/services/datocms/constants";
import { Card } from "src/features/ProductCenterPage/Card";
import { InputAdornment, TextField, useMediaQuery } from "@mui/material";
import { ProductImageType } from "src/features/ProductCenterPage/types";
import { CollectionProductsMap } from "src/shared/types";
import { ContentSection } from "src/shared/components/ContentSection";
import { SearchIcon } from "src/shared/components/Icons";
import { CollectionNav } from "src/shared/components/CollectionNav";

type Props = {
  collections: PlantPageFieldsFragment[];
  collectionProducts: CollectionProductsMap;
  sectionOnChange?: (inView: boolean, entry: IntersectionObserverEntry) => void;
  sortByFn?: (a: PlantPageFieldsFragment, b: PlantPageFieldsFragment) => number;
  isInFlowerPage?: boolean;
};

type plantCollectionType = {
  collectionId: string;
  name: string;
  plantListC: PlantPageFieldsFragment[];
};
export type BlockPlantCollectionProps = {
  data: BlockPlantCollectionFieldsFragment;
};
export const BlockPlantCollection: FC<BlockPlantCollectionProps> = ({
  data,
}) => {
  const [plantList, setPlantList] = useState<PlantPageFieldsFragment[]>(
    data.collection?.plantList as PlantPageFieldsFragment[]
  );
  const [searchValue, setSearchValue] = useState<string>();
  const [tagList, setTagsList] = useState<string[]>([]);
  const [collections, setCollections] = useState<collectionResult[]>([]);
  const [originalCollections, setOriginalCollections] = useState<
    collectionResult[]
  >([]);
  const [collectionsPCPage, setcollectionsPCPage] = useState<
    plantCollectionType[]
  >([]);
  const [originalCollectionsPCPage, setOriginalCollectionsPCPage] = useState<
    plantCollectionType[]
  >([]);
  const [sectionInView, setSectionInView] = useState<HTMLElement | null>(null);
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    setPlantList(data.collection?.plantList as PlantPageFieldsFragment[]);
    const dataList = data.collection?.plantList as PlantPageFieldsFragment[];
    const collectionL = dataList
      .map((p) =>
        p.product?.tags.filter((f) => f.isAFlowerPlantCenterCollection === true)
      )
      .filter((r) => (r?.length ?? 0) > 0)
      .flat()
      .map((m) => m?.id);
    setTagsList(collectionL);
  }, [data.collection?.plantList]);

  useEffect(() => {
    if ((searchValue ?? "").length > 0) {
      const _plantListt = originalCollectionsPCPage;
      const _plantLisF: plantCollectionType[] = [];
      const collectionsTagFiltered: collectionResult[] = [];
      _plantListt.forEach((m) => {
        const collectionI = m.collectionId;
        const nameCol = m.name;
        const filtered = m.plantListC.filter((p) => {
          const name = (p.name ?? "").trim().toLowerCase();
          const title = (p.product?.title ?? "").trim().toLowerCase();
          const intro = (p.introText ?? "").trim().toLowerCase();
          const ending = (p.endingText ?? "").trim().toLowerCase();
          const searchText = (searchValue ?? "").trim().toLowerCase();
          return (
            name.includes(searchText) ||
            title.includes(searchText) ||
            intro.includes(searchText) ||
            ending.includes(searchText)
          );
        });
        if (filtered.length) {
          const pageR: plantCollectionType = {
            collectionId: collectionI,
            name: nameCol,
            plantListC: filtered,
          };
          const colToPush = originalCollections
            .filter((f) => f.id === collectionI)
            .shift();
          if (colToPush) {
            collectionsTagFiltered.push(colToPush);
          }
          _plantLisF.push(pageR);
        }
      });
      setCollections(collectionsTagFiltered);
      setcollectionsPCPage(_plantLisF);
    } else {
      setcollectionsPCPage(originalCollectionsPCPage);
      setCollections(originalCollections);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    (async () => {
      if (tagList.length) {
        let collectionsTag: collectionResult[] = [];
        const tagListData = await getCollectionByTags(tagList, false);
        collectionsTag = tagListData as collectionResult[];

        if (collectionsTag) {
          const collectionProducts: CollectionProductsMap =
            await getCollectionProductsByTags(collectionsTag, false);
          if (collectionProducts) {
            const collectionsProductPage: plantCollectionType[] = [];
            const productPlantsColList: PlantPageFieldsFragment[] = [];
            setCollections(collectionsTag);
            setOriginalCollections(collectionsTag);
            collectionsTag.forEach((c) => {
              const productList: PlantPageFieldsFragment[] = [];
              let collectionId = c.id;
              let collectionName = c.title;
              const productsByTag =
                collectionProducts[c.id].map((f) => f.id) ?? [];
              plantList.forEach((pt) => {
                if (
                  productsByTag.includes(pt.product?.id) &&
                  !productList.includes(pt)
                ) {
                  productList.push(pt);
                } else if (!productPlantsColList.includes(pt)) {
                  productPlantsColList.push(pt);
                }
              });
              const pageR: plantCollectionType = {
                collectionId,
                name: collectionName,
                plantListC: productList,
              };
              collectionsProductPage.push(pageR);
            });
            setcollectionsPCPage(collectionsProductPage);
            setOriginalCollectionsPCPage(collectionsProductPage);
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagList, setcollectionsPCPage]);

  return (
    <>
      <ContentSection
        sectionPadded={false}
        css={[tw`py-10 text-left `, isDesktop ? tw`px-10` : tw`px-5`]}
      >
        <div css={[isDesktop && tw`flex w-full`]}>
          <div css={[isDesktop && { width: "16%" }]}>
            <CollectionNav
              collections={collections}
              activeNode={sectionInView}
            />
          </div>
          <div css={[isDesktop && { width: "84%" }]}>
            <div css={tw`mb-10 sticky top-[30px]`}>
              <TextField
                label="Search title or description"
                type="search"
                fullWidth
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" css={tw`!outline-none`}>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => handleChangeSearch(e.target.value)}
                sx={{
                  "& input": {
                    boxShadow: "none !important",
                  },
                  "& label.Mui-focused": {
                    color: "#000",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#B2BAC2",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#E0E3E7",
                    },
                    "&:hover fieldset": {
                      borderColor: "#B2BAC2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#6F7E8C",
                    },
                    "&.Mui-focused .MuiInputAdornment-root": {
                      color: "#000",
                    },
                  },
                }}
                autoComplete="off"
              />
            </div>
            <FixedContainer
              CustomContainer
              css={[
                tw`mt-10`,
                tw`mx-auto px-0`,
                { maxWidth: isDesktop ? "1360px" : "1536px" },
              ]}
            >
              {collectionsPCPage && (
                <div css={tw`mt-4 lg:mt-8`}>
                  <div css={tw`flex flex-wrap`}>
                    {collectionsPCPage.map((cpc) => {
                      const products = cpc.plantListC;
                      return (
                        <FlowerShopSection
                          css={tw`w-full`}
                          key={cpc.collectionId}
                        >
                          <FlowerShopSectionItems
                            id={cpc.collectionId}
                            rootMargin="-100px 0px"
                          >
                            {products?.map((planItem) => {
                              const { product } = planItem;
                              const cardData = {
                                title: "",
                                name: planItem?.name || "",
                                imageUrl: planItem?.primaryImage?.url || "",
                                imageXPosition:
                                  planItem?.primaryImage?.focalPoint?.x,
                                imageYPosition:
                                  planItem?.primaryImage?.focalPoint?.y,
                                slug: planItem?.slug || "",
                                slugsPrefix: slugsPrefixMap.plant_page,
                                alt: planItem?.primaryImage?.alt || "",
                                hasBadge: planItem?.hasBadge,
                                badge: planItem?.badge?.url || "",
                                product: product as unknown as ProductImageType,
                              };
                              return (
                                <Card
                                  key={`${planItem?.id} ${cpc.collectionId} `}
                                  cardData={cardData}
                                />
                              );
                            })}
                          </FlowerShopSectionItems>
                        </FlowerShopSection>
                      );
                    })}
                  </div>
                </div>
              )}

              {collectionsPCPage.length === 0 &&
                (searchValue ?? "").length > 0 && (
                  <div
                    css={tw`flex justify-center text-2xl text-bloomsy-midgray font-roboto`}
                  >
                    <span>
                      Sorry, there are no results for &quot;{searchValue}&quot;
                    </span>
                  </div>
                )}
            </FixedContainer>
          </div>
        </div>
      </ContentSection>
    </>
  );
};
