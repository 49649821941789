/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unescaped-entities */
import { FC, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { BlockMainFeatureContainerFieldsFragment } from "src/generated/datocms-types";
import { useKeenSlider } from "keen-slider/react";
import FixedContainer from "src/shared/components/FixedContainer";
import tw, { theme as twTheme } from "twin.macro";
import {
  BlockMainFeature,
  BlockMainFeatureProps,
} from "src/ui/components/MainFeature";

export type BlockMainFeatureContainerProps = {
  data: BlockMainFeatureContainerFieldsFragment;
};

export const BlockMainFeatureContainer: FC<BlockMainFeatureContainerProps> = ({
  data: { mainFeatureContainerContent },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    slidesPerView: 1,
    spacing: 1,
    loop: true,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  const styles = [
    isDesktop ? tw` w-16 top-1/2` : tw`w-12 top-40`,
    tw`absolute cursor-pointer fill-current text-gray-400`,
  ];
  return (
    <FixedContainer>
      <div>
        {isDesktop && <div css={tw`w-1/12`} />}
        <div ref={sliderRef} className="keen-slider">
          {mainFeatureContainerContent &&
            mainFeatureContainerContent.map((mainFeature) => {
              if (!mainFeature) return null;
              return (
                <div
                  className="keen-slider__slide"
                  key={mainFeature?.id}
                  css={[
                    tw`overflow-scroll lg:px-12 lg:h-auto lg:overflow-hidden`,
                    { height: "530px" },
                  ]}
                >
                  <BlockMainFeature
                    key={mainFeature?.id}
                    data={mainFeature as BlockMainFeatureProps["data"]}
                  />
                </div>
              );
            })}
        </div>
        {slider && (
          <>
            {isDesktop ? (
              <>
                <div css={[styles, tw` left-0 `]}>
                  <svg onClick={() => slider.prev()} viewBox="0 0 100 125">
                    <path d="M66.006,82.742L40.377,52l25.629-30.742c1.133-1.361,0.947-3.379-0.41-4.514s-3.381-0.951-4.514,0.408L33.744,49.947   C33.246,50.541,33,51.271,33,52c0,0.73,0.246,1.459,0.744,2.053l27.338,32.795c1.133,1.359,3.156,1.543,4.514,0.408   C66.957,86.119,67.141,84.105,66.006,82.742z" />
                  </svg>
                </div>
                <div css={tw`w-1/12`} />
                <div css={[styles, tw` right-0`]}>
                  <svg onClick={() => slider.next()} viewBox="0 0 100 125">
                    <path d="M66.254,46.947L38.916,14.152c-1.131-1.359-3.154-1.543-4.512-0.408c-1.361,1.137-1.545,3.152-0.412,4.512L59.621,49   L33.992,79.742c-1.131,1.363-0.947,3.379,0.412,4.514c1.357,1.135,3.381,0.951,4.512-0.408l27.338-32.793   C66.752,50.459,67,49.729,67,49C67,48.27,66.752,47.541,66.254,46.947z" />
                  </svg>
                </div>
              </>
            ) : (
              <div css={tw`flex justify-center pt-2 w-full`}>
                {/* @ts-ignore */}
                {[...Array(slider.details().size).keys()].map((idx) => {
                  return (
                    <button
                      css={[
                        tw`w-2.5 h-2.5 mx-1 mt-2 bg-bloomsy-gray50 block rounded-full lg:(w-3 h-3) focus:outline-none`,
                        currentSlide === idx && tw`bg-bloomsy-midgray`,
                      ]}
                      type="button"
                      key={idx}
                      onClick={() => {
                        slider.moveToSlideRelative(idx);
                      }}
                    />
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>
    </FixedContainer>
  );
};
