import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import {
  ContentSectionSubtitle,
  ContentSectionTitle,
} from "src/shared/components/ContentSection";
import { useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockProseColumnTitleBgimageFieldsFragment } from "src/generated/datocms-types";
import { IMAGE_QUALITY } from "src/constants";

const fontFamilyMap = {
  rcondensed: tw`font-roboto`,
  roboto: tw`font-roboto`,
  playfair: tw`font-playfair`,
};

const columnsWidthMap = {
  leftColumnWider: [tw`prose prose-sm max-w-3xl`, tw`prose prose-sm max-w-lg`],
  sameColumnWidth: [tw`prose prose-sm w-full`, tw`prose prose-sm w-full`],
  rightColumnWider: [
    tw`prose prose-sm max-w-lg px-2 lg:(pl-28)`,
    tw`prose prose-sm max-w-3xl sm:px-2 lg:(pl-24)`,
  ],
  bothSmall: [
    tw`max-w-lg text-xs sm:pl-20 lg:(pl-10 w-1/4) xl:(w-1/5)`,
    tw` max-w-lg text-xs sm:pl-20 lg:( pl-0 pr-0 w-1/4) xl:(pr-2 pl-14 w-1/5)`,
  ],
};

const ColumnPositionMap = {
  left: tw`justify-start`,
  center: tw`justify-center`,
  right: tw`justify-end`,
};
export type BlockProseColumnTitleProps = {
  data: BlockProseColumnTitleBgimageFieldsFragment;
};
export const BlockProseColumnTitle: FC<BlockProseColumnTitleProps> = ({
  data,
}) => {
  const {
    bgImage,
    mobileImage,
    mainTitle,
    mainSubtitle,
    subtitlePosition,
    fontSize,
    fontFamily,
    fontSizeFirstTitle,
    fontSizeSecondTitle,
    titleFontFamily,
    columnWidth,
    contentPosition,
    titleFirstCol,
    firstColumn,
    titleSecondCol,
    secondColumn,
  } = data;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const [leftColumnStyle, rightColumnStyle] = columnsWidthMap[
    columnWidth as keyof typeof columnsWidthMap
  ] ?? [tw`max-w-sm`, tw`max-w-xl`];

  const ColPosition =
    ColumnPositionMap[contentPosition as keyof typeof ColumnPositionMap] ??
    tw`justify-center`;

  const hasFirstTitleSmallText = fontSizeFirstTitle === "small";
  const hasSeconTitleSmallText = fontSizeSecondTitle === "small";

  const hasLargeText = fontSize === "large";
  const hasSmallText = fontSize === "small";

  const fontFamilyKey = titleFontFamily ?? "roboto";
  const fontFamilyStyle =
    fontFamilyMap[fontFamilyKey as keyof typeof fontFamilyMap];

  const activeFontFamilyTitle =
    fontFamilyMap[fontFamily as keyof typeof fontFamilyMap] ?? tw`font-roboto`;

  const stylesFirstTitle = [
    tw`text-xs m-0 leading-tight`,
    hasFirstTitleSmallText ? tw`lg:text-lg` : tw`lg:text-3xl`,

    fontFamilyStyle,
  ];

  const stylesSecondTitle = [
    tw`text-xs m-0 leading-tight`,
    hasSeconTitleSmallText ? tw`lg:text-lg` : tw`lg:text-3xl`,
    fontFamilyStyle,
  ];

  const renderImage = (
    image:
      | BlockProseColumnTitleBgimageFieldsFragment["bgImage"]
      | BlockProseColumnTitleBgimageFieldsFragment["mobileImage"]
  ) => {
    const imageSize = { h: !isDesktop ? 300 : 700 };
    const imageSrc = `${image?.url}?h=${imageSize.h}`;

    return (
      <div css={[tw`relative`, { height: imageSize.h }]}>
        <Image
          css={tw`object-cover`}
          src={imageSrc}
          layout="fill"
          quality={IMAGE_QUALITY}
          alt={image?.alt || ""}
        />
      </div>
    );
  };

  const renderBgImage = () => {
    if (mobileImage?.responsiveImage && !isDesktop) {
      return renderImage(mobileImage);
    }
    return renderImage(bgImage);
  };
  const renderTitlefirstCol = () => {
    return hasFirstTitleSmallText ? (
      <p css={[tw`text-center lg:text-left`, stylesFirstTitle]}>
        {titleFirstCol}
      </p>
    ) : (
      <h1 css={[tw`text-center lg:text-left`, stylesFirstTitle]}>
        {titleFirstCol}
      </h1>
    );
  };
  const renderTitleSecondCol = () => {
    return hasSeconTitleSmallText ? (
      <p css={[tw`text-center lg:text-left`, stylesSecondTitle]}>
        {titleSecondCol}
      </p>
    ) : (
      <h1 css={[tw`text-center lg:text-left`, stylesSecondTitle]}>
        {titleSecondCol}
      </h1>
    );
  };
  if (!firstColumn && !titleFirstCol) return null;
  return (
    <div css={[tw`w-full h-full relative overflow-hidden`]}>
      {bgImage?.url && renderBgImage()}
      <div
        css={[
          tw`w-full h-auto`,
          bgImage?.url && [
            !isDesktop ? tw`py-8` : tw`absolute`,
            !isDesktop
              ? {}
              : { top: "44%", left: "50%", transform: "translate(-50%,-50%)" },
          ],
        ]}
      >
        <FixedContainer
          css={[tw`w-full h-auto lg:mt-4 xl:mt-0`, bgImage?.url && []]}
        >
          <div>
            {mainSubtitle && subtitlePosition === "before" && (
              <ContentSectionSubtitle css={tw`text-center mb-2`}>
                {mainSubtitle}
              </ContentSectionSubtitle>
            )}
            {mainTitle && (
              <ContentSectionTitle
                css={[
                  tw`text-center lg:(max-w-4xl mx-auto)`,
                  hasLargeText && tw`sm:text-4xl`,
                  hasSmallText && tw`sm:text-base`,
                  activeFontFamilyTitle,
                ]}
              >
                {mainTitle}
              </ContentSectionTitle>
            )}
            {mainSubtitle && subtitlePosition === "after" && (
              <ContentSectionSubtitle css={tw`text-center mt-2`}>
                {mainSubtitle}
              </ContentSectionSubtitle>
            )}
          </div>
        </FixedContainer>
        <FixedContainer css={[tw`flex flex-wrap mx-auto`, ColPosition]}>
          <div
            css={[
              tw` pr-6 lg:py-10  md:pr-4`,
              !data.secondColumn && tw``,
              data.secondColumn && tw``,
              leftColumnStyle,
            ]}
          >
            {titleFirstCol && renderTitlefirstCol()}
            {firstColumn && (
              <div
                css={[
                  tw`text-base pt-7 lg:pt-0 `,
                  { "&>p>strong": { fontSize: "22px" } },
                ]}
                dangerouslySetInnerHTML={{ __html: firstColumn }}
              />
            )}
          </div>
          {secondColumn && (
            <div css={[tw`pt-7 lg:pt-4 lg:py-10`, rightColumnStyle]}>
              {titleSecondCol && renderTitleSecondCol()}
              {secondColumn && (
                <div
                  css={[
                    !firstColumn && !isDesktop
                      ? tw`text-center`
                      : tw`text-left`,
                    tw`text-base`,
                    { "&>p>strong": { fontSize: "22px" } },
                  ]}
                  dangerouslySetInnerHTML={{ __html: secondColumn }}
                />
              )}
            </div>
          )}
        </FixedContainer>
      </div>
    </div>
  );
};
