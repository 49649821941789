import { makeVar, useReactiveVar } from "@apollo/client";

export const currentEntryObject = makeVar<
  IntersectionObserverEntry | undefined
>(undefined);

type UseEntryObjectHook = () => {
  currentEntryObject: IntersectionObserverEntry | undefined;
  addEntryObject: (value: IntersectionObserverEntry | undefined) => void;
};
export const useEntryObject: UseEntryObjectHook = () => {
  const _setEntryObject = (value: IntersectionObserverEntry | undefined) => {
    currentEntryObject(value);
  };
  return {
    currentEntryObject: useReactiveVar(currentEntryObject),
    addEntryObject: _setEntryObject,
  };
};
