/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockCtaFieldsFragment, BlockHeroButtonsFragment } from "src/generated/datocms-types";
import { useMediaQuery } from "@mui/material";
import { renderInternalExternalLinks } from "src/shared/helpers";
import { CTAButton } from "src/shared/types";

export type BlockCTAProps = {
  data: BlockCtaFieldsFragment;
};
export const BlockCTA: FC<BlockCTAProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
    const { buttons, hasLightTheme = false,colorButton } = data;
    const firstButton: BlockHeroButtonsFragment[] =
    buttons?.length && buttons?.[0] ? [buttons[0]] : [];
  const buttonStyle = [
    tw`w-auto text-sm`,
  ];
  const ctaProps: CTAButton = {
    whiteColor: hasLightTheme,
    hasBiggerButton:false,
    colorButton,
  };
  return (
    <FixedContainer css={tw`text-center`}>
    {isDesktop && buttons?.length ? (
      <div>
        {renderInternalExternalLinks(
          buttons as BlockHeroButtonsFragment[],
          ctaProps,
        )}
      </div>
          ) : (
            <div>
            {firstButton?.length > 0 && (
              <div css={[{ "&>a": buttonStyle }]}>
                {renderInternalExternalLinks(
                  firstButton,
                  ctaProps,
                )}
              </div>
            )}
          </div>
    )
    }
</FixedContainer>
  );
};
