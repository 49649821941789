/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { PromotionalCardFieldsFragment } from "src/generated/datocms-types";
import { IMAGE_QUALITY } from "src/constants";
import { getInternalLink } from "src/shared/helpers";
import { useMediaQuery } from "@mui/material";

export type BlockPromotionalCardeProps = {
  data: PromotionalCardFieldsFragment;
  isFlowerShopPage?: boolean;
  hasSlider: boolean;
  gridType: string;
  totalProducts: number;
  cardType: string;
};
const productGridMap = {
  default: tw`w-1/2 lg:w-1/4`,
  secondGrid: tw`md:w-1/2`,
  flowerShopPage: tw`w-[49%] lg:w-1/3 xl:w-1/4`,
};
export const PromotionalCard: FC<BlockPromotionalCardeProps> = ({ data, isFlowerShopPage, hasSlider, gridType, totalProducts, cardType }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const isXl = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const classNameForSliderPage = hasSlider ? "keen-slider__slide" : "";
  const hasDefaultGrid = gridType === "default";
  const squareCard = cardType === "square";
  const smallerDeskDimensions = isXl ? 300 : 400; 
  const smallerDeskDimensionsH = isXl ? 280 : 360; 
  const productImageSize = {
    w: isLargeDesktop ? 333 : smallerDeskDimensions,
    h: isLargeDesktop ? 331 : smallerDeskDimensionsH,
  };
  const productImageSizeMobile = {
    w: hasDefaultGrid  ? 409 : 650,
    h: squareCard ? 606 : 492,
   };
     const imagemobileSrc = `${data?.image?.url}?h=${productImageSize.h}&w=${productImageSize.w}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`;
  const imageSrc = `${data?.image?.url}?h=${productImageSize.h}&w=${productImageSize.w}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`;
  const specialGridProducts = totalProducts < 3 && gridType === "default";
  const gridTypeValue = isFlowerShopPage ? gridType : "flowerShopPage";
  const gridProductStyle =
    productGridMap[gridTypeValue as keyof typeof productGridMap];

  return (
    <div
    css={[
      tw`bg-white flex lg:pr-1.5 md:mb-8`,
      specialGridProducts ? tw`w-1/2 lg:w-2/5` : gridProductStyle,
    ]}
    className={classNameForSliderPage}
  >
    <div css={tw`relative rounded my-4 md:my-2`}>
        <div css={tw`relative`}>
          <a
            href={getInternalLink(data.pageLinkUrl[0].link)}
            css={tw`block relative`}
          >
              <Image
              css={tw`object-cover`}
              src={isDesktop ? imageSrc : imagemobileSrc}
              width={isDesktop ? productImageSize.w : productImageSizeMobile.w}
              height={isDesktop ? productImageSize.h : productImageSizeMobile.h}
              quality={IMAGE_QUALITY}
              layout="intrinsic"
              alt={data?.image?.alt ?? ""}
            />
          </a>
        </div>
      </div>
      </div>
  );
};

