/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw from "twin.macro";
import Image from "next/legacy/image";
import FixedContainer from "src/shared/components/FixedContainer";
import {
  BlockFeaturedInFieldsFragment,
  MenuLinksFragment,
} from "src/generated/datocms-types";
import { getInternalLink } from "src/shared/helpers";
import {
  BlockSpacingContainer,
  BlockSpacingContainerProps,
} from "src/shared/components/BlockSpacingContainer";
import { IMAGE_QUALITY } from "src/constants";

const LogoFeatured = ({
  coverImage,
  isExternal,
  url,
  internalLink,
}: MenuLinksFragment) => {
  if (!coverImage?.url) return null;
  const imageSrc = `${coverImage.url}?w=120&dpr=2`;
  const hasExternalLink = isExternal && (url?.length ?? 0) > 0;
  const hasInternalLink = internalLink?.slug?.length;
  const hasLink = hasExternalLink || hasInternalLink;
  const externalURL = hasExternalLink && url;
  let href = "";
  if (hasLink) {
    href = hasExternalLink ? externalURL : getInternalLink(internalLink);
  }
  const styles = [
    tw`block relative w-1/2 lg:w-full mx-0 my-4 lg:(mx-8 my-0)`,
    { height: 30 },
    !hasLink && tw`cursor-default pointer-events-none`,
  ];
  return (
    <a
      href={`${href}`}
      rel="noreferrer"
      css={styles}
      key={coverImage?.id}
      target="_blank"
    >
      <Image
        css={tw`object-contain`}
        src={imageSrc}
        layout="fill"
        quality={IMAGE_QUALITY}
        alt={coverImage?.alt || ""}
      />
    </a>
  );
};

export type BlockFeaturedInProps = {
  data: BlockFeaturedInFieldsFragment;
};
export const BlockFeaturedIn: FC<BlockFeaturedInProps> = ({ data }) => {
  if (!data?.galleryImages?.length) return null;
  return (
    <BlockSpacingContainer
      spacing={data.blockSpacing as BlockSpacingContainerProps["spacing"]}
    >
      <FixedContainer>
        <div css={tw`relative border-t border-b border-gray-300 text-center`}>
          <h4
            css={[
              { top: "-14px" },
              tw`text-sm font-roboto font-bold uppercase tracking-wider px-4 bg-white inline-block relative text-center`,
            ]}
          >
            {data.title}
          </h4>
          <div
            css={tw`pb-4 flex items-center flex-wrap lg:(flex-row flex-nowrap) justify-center`}
          >
            {data.galleryImages.map((l) => {
              return (
                <LogoFeatured
                  key={l.id}
                  coverImage={l.coverImage}
                  isExternal={l.isExternal}
                  url={l.url}
                  id={l.id}
                  title={l.title}
                  shortDescription={l.shortDescription}
                  internalLink={l.internalLink}
                />
              );
            })}
          </div>
        </div>
      </FixedContainer>
    </BlockSpacingContainer>
  );
};
