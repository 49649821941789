/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureImageFullFieldsFragment } from "src/generated/datocms-types";
import { FeatureTextIcon } from "src/ui/components/BlockFeatureTextIcon/FeatureTextIcon";

export type BlockFeatureTextIconFullProps = {
  data: BlockFeatureImageFullFieldsFragment;
};
export const BlockFeatureTextIconFull: FC<BlockFeatureTextIconFullProps> = ({
  data: { content },
}) => {
  return (
    <FixedContainer css={tw`px-5 lg:px-0`}>
      <div css={tw`flex flex-wrap justify-between mt-6`}>
        {content &&
          content.map((record) => {
            if (!record) return null;
            return <FeatureTextIcon data={record} key={record.id} />;
          })}
      </div>
    </FixedContainer>
  );
};
