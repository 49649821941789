import { FC } from "react";
import tw from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureImageCardFieldsFragment } from "src/generated/datocms-types";
import { FeatureImageCard } from "src/ui/components/BlockFeatureImageCard/FeatureImageCard";
import { ProductCardWithTitle } from "src/features/BlogPostPage/ProductCardWithTitle";

export type BlockFeatureImageCardProps = {
  data: BlockFeatureImageCardFieldsFragment;
};
// eslint-disable-next-line react/function-component-definition
export const BlockFeatureImageCard: FC<BlockFeatureImageCardProps> = ({
  data: { content, hasLargerCardWithoutBorder = false },
}) => {
  return (
    <FixedContainer>
      {hasLargerCardWithoutBorder ? (
        <div css={tw`lg:flex pt-6 pb-4`}>
          {content.map((imageCard) => {
            return <ProductCardWithTitle key={imageCard.id} data={imageCard} />;
          })}
        </div>
      ) : (
        <div css={tw`flex flex-wrap mt-8`}>
          {content &&
            content.map((record) => {
              if (!record) return null;
              return <FeatureImageCard data={record} key={record.id} />;
            })}
        </div>
      )}
    </FixedContainer>
  );
};
