import { FC } from "react";
import tw, { theme as twTheme, TwStyle } from "twin.macro";
import Image from "next/legacy/image";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockHerobanner2columnFieldsFragment, BlockHeroButtonsFragment, ProductFieldsFragment } from "src/generated/datocms-types";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";
import { CTAButton } from "src/shared/types";
import { renderInternalExternalLinks } from "src/shared/helpers";
import { ProductCard } from "src/shared/components/ProductCard";
import { ScrollSnapComponent } from "../ScrollSnapComponent";

const fontFamilyMap = {
  lato: tw`font-lato`,
  roboto: tw`font-roboto`,
  playfair: tw`font-playfair`,
};
const contentPositionMap = {
  top: [{
            top: "20%" ,
            left: "50%",
            transform: "translate(-50%,-50%)",
          }],
  center: [{
            top: "50%" ,
            left: "50%",
            transform: "translate(-50%,-50%)",
          }],
  bottom: [{
            bottom: "5%" ,
            left: "50%",
            transform: "translate(-50%,-50%)",
          }],
};

export type BlockHerobanner2columnProps = {
  data: BlockHerobanner2columnFieldsFragment;
};
export const BlockHeroBannerColumn: FC<BlockHerobanner2columnProps> = ({ data }) => {

    const { bgImage, mobileImage, products, titleFontFamily, titleHtml, contentPosition, theme, subTitleHtml, subtitleAfter, subtitleFontFamily, buttons, hasLightTheme, colorButton, imagePosition } = data;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const xlUp = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
    const imageSize = { w: isDesktop ? 800 : 500, h: isDesktop ? 750 : 500 };
    const imageSrc = `${bgImage?.url}?w=${imageSize.w}&h=${imageSize.h}&fit=crop`;
    const mobileImageSrc =  `${mobileImage?.url}?w=${imageSize.w}&h=${imageSize.h}&fit=crop`;
    const productsList = products as ProductFieldsFragment[];
    const isDarkMode = theme === "dark";
    const firstButton: BlockHeroButtonsFragment[] =
    buttons?.length && buttons?.[0] ? [buttons[0]] : [];
    const buttonStyle = [
    tw`w-auto text-sm`,
  ];
    const contentPositionKey = contentPosition ?? "bottom";
    const contentPositionStyle =
    contentPositionMap[contentPositionKey as keyof typeof contentPositionMap];
    const isSectionProductFirst = imagePosition === "right";
    const ctaProps: CTAButton = {
      whiteColor: hasLightTheme,
      hasBiggerButton: false,
      colorButton,
    };
  const isMobile = !isDesktop && !xlUp;
  const containerWidth = !isLargeDesktop ? "1200px" : "1360px";
  const hasUnderlineStyle = firstButton[0]?.variant === "link" ?? false;
  const linkbuttonStyle = [{ color: isDarkMode ? "white" :"black" }, tw`underline `,];
    const productSection = () => {
     return (
        <div css={[tw`w-full sm:w-[81%] lg:w-1/2 flex flex-wrap mt-6 lg:mt-0 justify-around`]}>
          {productsList.map((product) => {
            return (
              <div key={ product.id} css={tw` pb-1.5 px-0.5`}>
                <ProductCard key={product.id} product={product} />
              </div>
            )  
          })}
        </div>
      );

    
   };
   const renderTitle = () => {
     const fontFamilyKey = titleFontFamily ?? "roboto";
  
    const fontFamilyStyle =
      fontFamilyMap[fontFamilyKey as keyof typeof fontFamilyMap];

     return (
        <h3
          css={[fontFamilyStyle, tw`text-3xl lg:text-4xl`]}
          dangerouslySetInnerHTML={{
            __html: titleHtml ?? "",
          }}
        />
      );

    
   };
  
  const renderSubHeading = (props: TwStyle = {}) => {
    const SubTitlefontFamilyKey = subtitleFontFamily ?? "roboto";
    const SubtitlefontFamilyStyle =
    fontFamilyMap[SubTitlefontFamilyKey as keyof typeof fontFamilyMap];
const stylesSubtitle = [SubtitlefontFamilyStyle];
    return (
      <p
        css={[
          tw`lg:text-sm uppercase tracking-wider my-2 font-bold`,
          props,
          stylesSubtitle
        ]}
        dangerouslySetInnerHTML={{
          __html: subTitleHtml ?? "",
        }}
      />
    );
  };
  return (
    <>
      {isDesktop && (
        <FixedContainer
        CustomContainer
        css={[
          tw`text-center hidden lg:block mt-4`,
          isMobile
            ? tw`container px-4 sm:px-0`
            : [tw`mx-auto px-0`, { maxWidth: !xlUp ? "980px" : containerWidth }],
        ]}
        >
         <div css={tw`w-full flex flex-wrap mt-4 justify-center lg:justify-between`}>
           {isSectionProductFirst && isDesktop && productSection()}
           <div css={tw`relative w-[98%] sm:w-[80%] lg:w-[49.5%] relative h-[400px] sm:h-[500px] md:h-[600px] lg:h-[750px] xl:h-[827px] px-0.5 `}>
             <Image
               css={tw`block object-cover`}
               src={imageSrc}
               layout="fill"
               quality={IMAGE_QUALITY}
               priority
               alt={bgImage?.alt ?? ""}
             />
               <div css={[tw`w-full h-auto absolute`,
                 contentPositionStyle,
                 isDarkMode ? tw`text-white` : tw`text-black`,
                 ]}>
                 <FixedContainer 
                   css={[
                   tw`text-center relative`,
                   !isDesktop ? tw`px-9` : tw`px-0`,
                   tw`mx-auto`,   
                 ]}>
                  {subTitleHtml && !subtitleAfter && renderSubHeading(tw`mb-2`)}
                  {renderTitle()}
                  {subTitleHtml && subtitleAfter && renderSubHeading()}
                  {isDesktop && buttons?.length ? (
                    <div css={[tw` lg:mt-8`,
                      hasUnderlineStyle && { "&>a": linkbuttonStyle },
                    ]}>
                     {renderInternalExternalLinks(
                       buttons as BlockHeroButtonsFragment[],
                       ctaProps,
                     )}
                   </div>
                   ) : (
                   <div>
                     {firstButton.length > 0 && (
                          <div css={[
                            hasUnderlineStyle && { "&>a": linkbuttonStyle },
                            { "&>a": buttonStyle }, tw`my-2`]}>
                         {renderInternalExternalLinks(
                         firstButton,
                         ctaProps,
                     )}
                       </div>
                     )}
                   </div>
                     )}
                    </FixedContainer>
                       
               </div>     
           </div>
           {!isSectionProductFirst && isDesktop && productSection()}
             </div>
       </FixedContainer>
      )}
      {!isDesktop && (
         <div>
         <div css={tw`w-full flex flex-wrap mt-4 justify-center`}>
           <div css={tw`w-full relative h-[550px] sm:h-[500px] md:h-[600px]`}>
             <Image
               css={tw`block object-cover`}
               src={mobileImageSrc}
               layout="fill"
               quality={IMAGE_QUALITY}
               priority
               alt={bgImage?.alt ?? ""}
             />
               <div css={[tw`w-full h-auto absolute`,
               contentPositionStyle,
               isDarkMode ? tw`text-white` : tw`text-black`,
               ]}>
                 <FixedContainer
                   css={[
                   tw`text-center relative`,
                   !isDesktop ? tw`px-9` : tw`px-0`,
                   tw`mx-auto`,]}>
                     {subTitleHtml && !subtitleAfter && renderSubHeading(tw`mb-2`)}
                     {renderTitle()}
                     {subTitleHtml && subtitleAfter && renderSubHeading()}
                     {isDesktop && buttons?.length ? (
                     <div css={[ hasUnderlineStyle && { "&>a": linkbuttonStyle },tw`lg:mt-8`]}>
                       {renderInternalExternalLinks(
                         buttons as BlockHeroButtonsFragment[],
                         ctaProps,
                       )}
                     </div>
                       ) : (
                     <div>
                       {firstButton.length > 0 && (
                       <div css={[{ "&>a": buttonStyle }, tw`my-0`,  hasUnderlineStyle && { "&>a": linkbuttonStyle }]}>
                           {renderInternalExternalLinks(
                           firstButton,
                           ctaProps,
                         )}
                         </div>
                       )}
                     </div>
                    )}
                   </FixedContainer>       
             </div>     
            </div>
            {!isDesktop&& (
              <FixedContainer css={tw`block lg:hidden`}>
                <ScrollSnapComponent product={productsList} />
              </FixedContainer>)}
         </div>
     </div>
     )} 
    </>
  );
};
