/* eslint-disable react/function-component-definition */
import { FC } from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureReviewCollectionFieldsFragment } from "src/generated/datocms-types";
import tw, { theme as twTheme } from "twin.macro";
import { ContentSection } from "src/shared/components/ContentSection";
import { useMediaQuery } from "@mui/material";
import { FeatureReview } from "./FeatureReview";
import { FeatureReviewMobile } from "./FeatureReviewMobile";

export type BlockFeatureReviewProps = {
  data: BlockFeatureReviewCollectionFieldsFragment;
};

export const BlockFeatureReview: FC<BlockFeatureReviewProps> = ({
  data: { id, _modelApiKey,title, titleHtml,subtitle, subtitleHtml, reviewCollection },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const xlUp = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const hasTitleHTML = (titleHtml?.length ?? 0) > 0;
  const titlestring = (hasTitleHTML ? titleHtml : title) ?? "";
  const subTitleString = (subtitleHtml || subtitle) ?? "";
  const hasSubTitleString = (subTitleString?.length ?? 0) > 0;
  const titleText = titlestring?.replace("<<", "<span>").replace(">>", "</span>");
  const isMobile = !isDesktop && !xlUp;
  const containerWidth = !isLargeDesktop ? "1200px" : "1360px";
  return (
    <>
    <FixedContainer
      CustomContainer
      css={[
        tw`text-center hidden lg:block mt-4`,
        isMobile
          ? tw`container px-4 sm:px-0`
          : [tw`mx-auto px-0`, { maxWidth: !xlUp ? "980px" : containerWidth }],
      ]}
    >
        {hasSubTitleString || titleText && (
           <ContentSection
           sectionPadded={false}
           css={tw`pt-20 pb-8 bg-transparent`}
          >
            {hasSubTitleString && (
              <h2
              css={[
                tw`text-sm text-center mb-4 font-roboto font-bold uppercase tracking-wider`,
              ]}
              dangerouslySetInnerHTML={{ __html: subtitleHtml || (subtitle || "") }} />
            )}
           {titleText && (
              <h2
              css={[
                tw`text-center font-bold font-roboto text-2xl`,
                { "&>span": tw`text-bloomsy-red` },
              ]}
              dangerouslySetInnerHTML={{ __html: titleText }} />
           )}
         </ContentSection>
    )}
        <FeatureReview
          data={{ id, _modelApiKey,title, titleHtml,subtitle, subtitleHtml, reviewCollection }} />
    </FixedContainer>
      <FixedContainer css={tw` block lg:hidden`}>
          <FeatureReviewMobile
            data={{ id, _modelApiKey,title, titleHtml,subtitle, subtitleHtml, reviewCollection }} />
      </FixedContainer></>
  );
};
