import { makeVar, useReactiveVar } from "@apollo/client";

export const currentBreadcrumbPath = makeVar<string>("");
export const currentCollectionName = makeVar<string>("");

type UseBreadCrumbPageHook = () => {
  currentBreadcrumbPath: string;
  addBreadcrumbPath: (value: string) => void;
  currentCollectionName: string;
  addCollectionName: (value: string) => void;
};
export const useBreadcrumbPath: UseBreadCrumbPageHook = () => {
  const _setBreadcrumbPath = (value: string) => {
    currentBreadcrumbPath(value);
  };
  const _setCollectionName = (value: string) => {
    currentCollectionName(value);
  };
  return {
    currentBreadcrumbPath: useReactiveVar(currentBreadcrumbPath),
    addBreadcrumbPath: _setBreadcrumbPath,
    currentCollectionName: useReactiveVar(currentCollectionName),
    addCollectionName: _setCollectionName,
  };
};
