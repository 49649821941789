/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw from "twin.macro";
import {
  ContentSection,
  ContentSectionSubtitle,
  ContentSectionText,
  ContentSectionTitle,
  ContentSectionTitleH2,
} from "src/shared/components/ContentSection";
import Image from "next/legacy/image";
import { ContentSectionFieldsFragment } from "src/generated/datocms-types";
import { CollectionProductsMap } from "src/shared/types";
import { IMAGE_QUALITY } from "src/constants";
import { renderCmsModularContent } from "./utils";

type Props = {
  data: ContentSectionFieldsFragment;
  collectionProducts: CollectionProductsMap;
};
export const CmsContentSection: FC<Props> = ({ data, collectionProducts }) => {
  const {
    bgColor,
    bgImage,
    content,
    sectionPadded,
    subtitle,
    subtitlePos,
    title,
    fontSize,
    fontFamily,
    text,
    isTitleH1,
  } = data;
  const hasLargeText = fontSize === "large";
  const hasSmallText = fontSize === "small";
  const fontFamiliesMap = {
    roboto: tw`font-roboto`,
    lato: tw`font-roboto`,
    playfair: tw`font-playfair`,
  };
  const activeFontFamily =
    fontFamiliesMap[fontFamily as keyof typeof fontFamiliesMap] ??
    tw`font-roboto`;
  const imageSize = { h: 900 };
  const bgImageSrc = `${bgImage?.url}?h=${imageSize.h}`;
  const hasWhiteSmokeColor = bgColor === "whiteSmoke";
  return (
    <ContentSection
      sectionPadded={sectionPadded}
      bgColor={bgColor ?? ""}
      css={[hasWhiteSmokeColor && { background: "#F8F8F8" }]}
    >
      <div css={[tw`w-full h-full relative overflow-hidden`]}>
        {bgImage?.url && (
          <div css={[tw`relative`, { height: imageSize.h }]}>
            <Image
              css={tw`object-cover`}
              src={bgImageSrc}
              layout="fill"
              quality={IMAGE_QUALITY}
              alt={bgImage.alt ?? ""}
            />
          </div>
        )}
        <div
          css={[
            tw`w-full h-auto`,
            bgImage?.url && [
              tw`absolute`,
              {
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              },
            ],
          ]}
        >
          {subtitle && subtitlePos === "before" && (
            <ContentSectionSubtitle css={tw`text-center mb-2`}>
              {subtitle}
            </ContentSectionSubtitle>
          )}
          {title && isTitleH1 && (
            <ContentSectionTitle
              css={[
                tw`text-center lg:(max-w-4xl mx-auto)`,
                hasLargeText && tw`sm:text-4xl`,
                hasSmallText && tw`sm:text-base`,
                activeFontFamily,
              ]}
            >
              {title}
            </ContentSectionTitle>
          )}
          {title && !isTitleH1 && (
            <ContentSectionTitleH2
              css={[
                tw`text-center lg:(max-w-4xl mx-auto)`,
                hasLargeText && tw`sm:text-3xl`,
                hasSmallText && tw`sm:text-base`,
                activeFontFamily,
              ]}
            >
              {title}
            </ContentSectionTitleH2>
          )}
          {subtitle && subtitlePos === "after" && (
            <ContentSectionSubtitle css={tw`text-center mt-2`}>
              {subtitle}
            </ContentSectionSubtitle>
          )}
          {text && (
            <ContentSectionText
              css={[tw`mt-2`, hasLargeText && tw`md:text-xl`]}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}
          {renderCmsModularContent(content, collectionProducts)}
        </div>
      </div>
    </ContentSection>
  );
};
