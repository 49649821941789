/* eslint-disable react/function-component-definition */
import { BlockFeatureImageTextFieldsFragment } from "src/generated/datocms-types";
import { FC } from "react";
import Image from "next/legacy/image";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";
import FixedContainer from "src/shared/components/FixedContainer";

type FeatureImageFullProps = {
  data: BlockFeatureImageTextFieldsFragment;
};
export const BigImageComponent: FC<FeatureImageFullProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const hasDescriptionContent = data.descriptionContent.length > 0;
  const renderImage = (
    image:
      | BlockFeatureImageTextFieldsFragment["backgroundImage"]
      | BlockFeatureImageTextFieldsFragment["mobileImage"]
  ) => {
    const bigImageSize = { h: !isDesktop ? 900 : 1800 };
    const bigTextImageSize = { h: !isDesktop ? 400 : 700 };
    const imageSize = {
      h: hasDescriptionContent ? bigImageSize.h : bigTextImageSize.h,
    };
    const imageSrc = `${image?.url}?h=${imageSize.h}&crop=focalpoint&fit=crop&fp-x=0.4`;

    return (
      <div css={[tw`relative`, { height: imageSize.h }]}>
        <Image
          css={tw`object-cover xl:object-contain`}
          src={imageSrc}
          layout="fill"
          quality={IMAGE_QUALITY}
          alt={image?.alt ?? ""}
        />
      </div>
    );
  };
  const renderBgImage = () => {
    if (data.mobileImage?.responsiveImage && !isDesktop) {
      return renderImage(data.mobileImage);
    }
    return renderImage(data.backgroundImage);
  };
  const renderDescription = (hasBigText: boolean) => {
    const hasLeftPosition = data.bigTextPosition === "left";

    return (
      <div
        css={[
          tw`w-full max-w-largeScreenContainer mx-auto h-auto relative overflow-hidden`,
        ]}
      >
        {renderBgImage()}
        <div
          css={[
            tw`w-full h-auto`,
            data.hasBigText && data.bigText && tw`top-1/2 absolute`,
            data.hasBigText &&
              data.bigText && {
                left: "50%",
                transform: "translate(-50%,-50%)",
              },
            !isDesktop
              ? [tw`py-8 relative`, { top: "0%" }]
              : [
                  tw`absolute`,
                  {
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  },
                ],
          ]}
        >
          <FixedContainer>
            <div
              css={[
                hasLeftPosition ? tw`flex justify-start` : tw`flex justify-end`,
              ]}
            >
              {hasDescriptionContent && (
                <div css={tw`flex w-full  lg:w-5/12`}>
                  <div>
                    {data.titleDescription && (
                      <h2
                        css={tw`font-playfair text-2xl text-left  md:text-4xl`}
                      >
                        {data.titleDescription}
                      </h2>
                    )}
                    <div
                      css={tw`sm:flex sm:flex-wrap pt-4 sm:space-y-4 lg:space-y-36 justify-start mt-4`}
                    >
                      {data.descriptionContent.map((bp) => (
                        <div
                          key={bp.id}
                          css={tw`w-full sm:w-4/6 md:w-4/5 pt-4 md:pt-0`}
                        >
                          <div>
                            <h3
                              css={tw`font-roboto font-bold text-lg lg:text-xl`}
                            >
                              {bp.title}
                            </h3>
                            <div
                              css={tw`font-roboto text-sm lg:text-base pt-4 md:pt-2`}
                              dangerouslySetInnerHTML={{
                                __html: bp.description || "",
                              }}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {data.bigText && hasBigText && (
                <div
                  css={[
                    tw`w-1/2  mb-2 font-playfair px-2 lg:(pl-16 pr-52) text-lg sm:text-xl md:text-2xl lg:text-4xl leading-tight leading-10 `,
                  ]}
                  dangerouslySetInnerHTML={{ __html: data.bigText }}
                />
              )}
            </div>
          </FixedContainer>
        </div>
      </div>
    );
  };
  return (
    <div>
      {isDesktop && renderDescription(true)}
      {!isDesktop && hasDescriptionContent && renderDescription(false)}
    </div>
  );
};
