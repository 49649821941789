import { ImagSection } from "src/ui/components/BlockFeatureTextImagesButton";
/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";
import {
  BlockTextImageButtonFieldsFragment,
  BlockHeroButtonsFragment,
} from "src/generated/datocms-types";
import { renderInternalExternalLinks } from "src/shared/helpers";
import { ImageSection } from "./ImageSection";

type ImagSectionProps = {
  data: BlockTextImageButtonFieldsFragment;
};
export const ImagesTextButton: FC<ImagSectionProps> = ({
  data: {
    title,
    titleHtml,
    description,
    descriptionHtml,
    paragraph,
    hasButton,
    button,
    externalButton,
    hasImages,
    images,
    titleLogo,
    hasAnImageAndText,
  },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const buttonC: BlockHeroButtonsFragment[] = button as BlockHeroButtonsFragment[];
  const externalC: BlockHeroButtonsFragment[] = externalButton as BlockHeroButtonsFragment[]
  const hasUnderlineStyleButton = buttonC[0]?.variant === "link" ?? false;
  const hasUnderlineStyleExternal = externalC[0]?.variant === "link" ?? false;
  const linkbuttonStyle = [{ color: "black" }, tw`underline underline-offset-4`,];
  const buttonStyle = [!isDesktop && tw`text-sm`];
  const hasTitleHTML = (titleHtml?.length ?? 0) > 0;
  const titleText = (hasTitleHTML ? titleHtml : title) ?? "";
  const hasDescriptionHTML = (descriptionHtml?.length ?? 0) > 0;
  const descriptionText =  (hasDescriptionHTML ? descriptionHtml : description) ?? "";
  const isSvg = titleLogo?.format === "svg";
  const imageSrc = isSvg ? `${titleLogo?.url}` : `${titleLogo?.url}&w=320`;
 
  return (
    <>
      {titleLogo && (
        <div css={[tw`relative h-12`]}>
          <div css={tw`mt-20`}>
            <Image
              css={tw`block object-contain`}
              src={imageSrc}
              layout="fill"
              quality={IMAGE_QUALITY}
              alt={titleLogo?.alt ?? ""}
            />
          </div>
        </div>
      )}

      <div
        css={tw`flex flex-wrap text-left lg:text-center lg:justify-center pt-4 lg:pt-8 md:pb-8`}
      >
       
           <div
           css={[
             tw`font-playfair w-full text-3xl md:text-4xl`,
           ]}
         >
           <h2 dangerouslySetInnerHTML={{
              __html: titleText,
            }} />
         </div>
        {(descriptionText.length ?? 0)>0 && (
          <div
            css={[
              tw`font-playfair text-sm leading-loose mt-4 md:text-base md:leading-10 lg:text-xl lg:leading-10 w-full px-2 lg:px-0 lg:w-2/3 mb-6`,
              hasImages && tw`pb-8`,
            ]}
            dangerouslySetInnerHTML={{
              __html: descriptionText,
            }}
           />
        )}
        {paragraph && (
          <div
            css={[
              tw`font-playfair text-base leading-loose mt-4 md:text-base md:leading-10 lg:text-xl lg:leading-10 w-full lg:w-2/3 mb-6`,
              { "&>p": tw`mb-8` },
              hasImages && tw`pb-8`,
            ]}
            dangerouslySetInnerHTML={{
              __html: paragraph,
            }}
          />
        )}

        {hasButton && (button.length > 0 || externalButton) && (
          <>
            <div css={tw`w-full flex justify-center`}>
              <div css={[hasUnderlineStyleButton && {"&>a": linkbuttonStyle }, { "&>a": buttonStyle }, tw` md:w-1/4 lg:w-1/2 `]}>
                {renderInternalExternalLinks(
                  button as BlockHeroButtonsFragment[]
                )}
              </div>
            </div>
            <div css={tw`w-full flex justify-center`}>
              <div
                css={[
                  hasUnderlineStyleExternal && {"&>a": linkbuttonStyle },
                  { "&>a": buttonStyle },
                  tw` md:w-1/4 lg:w-1/2`,
                  button && tw`pt-4`,
                ]}
              >
                {renderInternalExternalLinks(
                  externalButton as BlockHeroButtonsFragment[]
                )}
              </div>
            </div>
          </>
        )}
      </div>
      {hasImages && !hasAnImageAndText && (
        <div css={tw`sm:(flex flex-wrap justify-between pt-2)`}>
          {images.map((image) => {
            const ImageData: ImagSection = {
              title: image.title ?? "",
              description: image.description ?? "",
              url: image.image?.url ?? "",
              alt: image.image?.alt ?? "",
            };
            return <ImageSection key={image.id} data={ImageData} />;
          })}
        </div>
      )}
    </>
  );
};
