import tw from "twin.macro";
import { MdOutlineTune } from "react-icons/md";
import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Paper, Popper } from "@mui/material";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { productSortOptions } from "src/constants";
import {
  ProductCollectionFieldsFragment,
  ProductFieldsShortFragment,
} from "src/generated/datocms-types";
import { FilterAndSortDrawer } from "./FilterAndSortDrawer";
import { CollectionProductsMap, FilterProduct } from "../types";
import { FilterList } from "./FilterList";

type Props = {
  filterOptions: FilterProduct[];
  productCollection: CollectionProductsMap;
  collections: ProductCollectionFieldsFragment[];
  setSortByKey: (value: string) => void;
};

export const FilterAndSortMobile: FC<Props> = ({
  filterOptions,
  productCollection,
  collections,
  setSortByKey,
}) => {
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [openSortPopper, setOpenSortPopper] = useState(false);
  const [sortByOptionSelected, setSortByOptionSelected] = useState(
    productSortOptions["unsorted-default"]
  );
  const [anchorElSortPopper, setAnchorElSortPopper] =
    useState<null | HTMLElement>(null);

  const getAllProducts = () => {
    const allProducts: ProductFieldsShortFragment[] = [];
    collections.forEach((collection) => {
      const productByTagSorted: ProductFieldsShortFragment[] = [];
      const tags = collection.productTags;
      const productsByTag = productCollection[collection.id] ?? [];
      tags.forEach((t) => {
        const products = productsByTag.filter((c) =>
          c.tags.find((i) => i.id === t.id)
        );
        products.forEach((m) => productByTagSorted.push(m));
      });
      const manualProducts = collection.products ?? [];
      const products = manualProducts
        .concat(productByTagSorted)
        .slice() as ProductFieldsShortFragment[];

      allProducts.push(...products);
    });

    return allProducts;
  };
  const handleClickOpenFilterDrawer = () => {
    setOpenFilterDrawer(() => {
      return !openFilterDrawer;
    });
  };
  const handleClickOpenSortPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSortPopper(event.currentTarget);
    setOpenSortPopper((previousOpen) => !previousOpen);
  };

  const allProducts = getAllProducts();

  return (
    <div css={tw`mt-5`}>
      <FilterAndSortDrawer
        filterOptions={filterOptions}
        allProducts={allProducts}
        openFilterDrawer={openFilterDrawer}
        handleClickOpenFilterDrawer={handleClickOpenFilterDrawer}
      />
      <div
        css={tw`w-full flex justify-between items-center mb-3 text-black font-bold font-roboto text-sm`}
      >
        <div>
          <a
            href={undefined}
            onClick={handleClickOpenFilterDrawer}
            css={tw`flex items-center cursor-default gap-2`}
          >
            <span>
              <MdOutlineTune />
            </span>
            <span css={tw`leading-4 tracking-wider`}>FILTERS</span>
          </a>
        </div>
        <div>
          <div>
            <span
              role="button"
              tabIndex={0}
              onClick={handleClickOpenSortPopper}
              onKeyPress={() => {
                return null;
              }}
              css={tw`flex cursor-default items-center`}
            >
              <span css={tw`leading-4 tracking-wider`}>
                SORT BY:
                <span css={tw`ml-1 text-sm font-normal font-roboto`}>
                  {sortByOptionSelected}
                </span>
              </span>
              <span>
                {!openSortPopper ? (
                  <FiChevronDown
                    css={[
                      tw`font-bold ml-1 mr-2 text-base`,
                      { color: "#4F5561" },
                    ]}
                    strokeWidth={5}
                  />
                ) : (
                  <FiChevronUp
                    css={[
                      tw`font-bold ml-1 mr-2 text-base`,
                      { color: "#4F5561" },
                    ]}
                    strokeWidth={5}
                  />
                )}
              </span>
            </span>
            <Popper
              id="simple-popper"
              open={openSortPopper}
              placement="bottom-end"
              anchorEl={anchorElSortPopper}
            >
              <Box>
                <Paper
                  css={[
                    tw`py-5 font-roboto mt-2`,
                    {
                      fontSize: "14px",
                      borderWidth: "1px",
                      borderColor: "#CCCCCC",
                    },
                  ]}
                >
                  {Object.keys(productSortOptions).map((k, idx) => (
                    <div
                      css={tw`w-full px-5 h-8 mb-2 flex items-center last:(mb-0)`}
                      key={`optionTitleMob ${idx + 1}`}
                    >
                      <a
                        href={undefined}
                        onClick={(event) => {
                          handleClickOpenSortPopper(event);
                          setSortByOptionSelected(
                            productSortOptions[
                              k as keyof typeof productSortOptions
                            ]
                          );
                          setSortByKey(k);
                        }}
                        role="button"
                        tabIndex={0}
                        onKeyPress={() => {
                          return null;
                        }}
                        css={tw`cursor-default w-full`}
                      >
                        {
                          productSortOptions[
                            k as keyof typeof productSortOptions
                          ]
                        }
                      </a>
                    </div>
                  ))}
                </Paper>
              </Box>
            </Popper>
          </div>
        </div>
      </div>
      <div>
        <FilterList />
      </div>
    </div>
  );
};
