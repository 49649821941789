import { FC } from "react";
import tw, { TwStyle, theme as twTheme } from "twin.macro";
import { BlockRowCardImageTitleRecord } from "src/generated/datocms-types";
import { ImageTitle } from "src/ui/components/BlockRowCard-ImageTitle/ImageTitle";
import {
  ContentSectionSubtitle,
  ContentSectionTitle,
} from "src/shared/components/ContentSection";
import FixedContainer from "src/shared/components/FixedContainer";
import { useMediaQuery } from "@mui/material";

export type BlockRowCardImageTitleRecordProps = {
  data: BlockRowCardImageTitleRecord;
};

export const BlockCardImageTitle: FC<BlockRowCardImageTitleRecordProps> = ({
  data: { title, subtitle, showSubtitleAfterTitle, description, content },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const renderSubHeading = (props: TwStyle = {}) => {
    return (
      <ContentSectionSubtitle>
        <p
          css={[
            tw`text-sm font-roboto uppercase font-normal tracking-wider `,
            props,
          ]}
        >
          {subtitle}
        </p>
      </ContentSectionSubtitle>
    );
  };

  return (
    <FixedContainer>
      <div
        css={[
          !isDesktop && tw`text-center`,
          tw`flex flex-wrap justify-center lg:justify-start mt-8 py-6 `,
        ]}
      >
        <div
          css={tw`pr-6 h-full relative overflow-hidden my-auto lg:(w-1/3 ) `}
        >
          {subtitle && showSubtitleAfterTitle && renderSubHeading(tw`mb-2`)}
          <ContentSectionTitle>
            <h1
              css={tw`text-2xl font-roboto lg:text-3xl font-bold m-0 leading-tight`}
            >
              {title}
            </h1>
          </ContentSectionTitle>
          {subtitle && !showSubtitleAfterTitle && renderSubHeading()}
          {description && (
            <div
              css={[
                { "&>p": tw`mb-4` },
                tw`my-4 font-roboto max-w-3xl text-sm lg:text-base`,
              ]}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
        </div>
        <div css={[tw`flex flex-wrap justify-end  lg:(w-2/3 py-6)  `]}>
          {content &&
            content.map((item) => {
              if (!item) return null;
              return <ImageTitle data={item} key={item.id} />;
            })}
        </div>
      </div>
    </FixedContainer>
  );
};
