/* eslint-disable react/function-component-definition */
import { FC } from "react";
import { BlockImageGridFieldsFragment } from "src/generated/datocms-types";
import tw, { styled } from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import Image from "next/legacy/image";
import {
  BlockSpacingContainer,
  BlockSpacingContainerProps,
} from "src/shared/components/BlockSpacingContainer";
import { IMAGE_QUALITY } from "src/constants";

const ImageGridItem = styled.div(
  (props: { variant: "square" | "square2x" | "rect" }) => [
    tw`relative border-2 border-white max-w-full w-full h-250`,
    props.variant === "square2x" && tw`lg:(w-250 h-250) xl:(w-500 h-500)`,
    props.variant === "square" && tw`lg:(w-125 h-125) xl:(w-250 h-250)`,
    props.variant === "rect" && tw`lg:(w-250 h-125) xl:(w-500 h-250)`,
  ]
);

export type BlockImageGridProps = {
  data: BlockImageGridFieldsFragment;
};
export const BlockImageGrid: FC<BlockImageGridProps> = ({ data }) => {
  const { images } = data;
  if (images.length !== 8) return null;
  const renderImage = (index: number) => {
    return (
      <Image
        css={tw`object-cover`}
        src={`${images[index].url}?w=500`}
        layout="fill"
        quality={IMAGE_QUALITY}
        alt={images[index].alt || ""}
      />
    );
  };
  return (
    <BlockSpacingContainer
      spacing={data.blockSpacing as BlockSpacingContainerProps["spacing"]}
    >
      <FixedContainer css={tw`px-5 lg:px-0`}>
        <div css={tw`lg:flex justify-center`}>
          <div>
            <div css={tw`lg:flex`}>
              <div css={tw`lg:w-8/12`}>
                <ImageGridItem variant="square2x">
                  {renderImage(0)}
                </ImageGridItem>
              </div>
              <div css={tw`lg:w-4/12`}>
                <ImageGridItem variant="square">{renderImage(1)}</ImageGridItem>
                <ImageGridItem variant="square">{renderImage(2)}</ImageGridItem>
              </div>
            </div>
            <div css={tw`lg:flex`}>
              <div css={tw`lg:w-4/12`}>
                <ImageGridItem variant="square">{renderImage(3)}</ImageGridItem>
              </div>
              <div css={tw`lg:w-8/12`}>
                <ImageGridItem variant="rect">{renderImage(4)}</ImageGridItem>
              </div>
            </div>
          </div>
          <div>
            <div css={tw`lg:flex`}>
              <ImageGridItem variant="square">{renderImage(5)}</ImageGridItem>
              <ImageGridItem variant="square">{renderImage(6)}</ImageGridItem>
            </div>
            <div>
              <ImageGridItem variant="square2x">{renderImage(7)}</ImageGridItem>
            </div>
          </div>
        </div>
      </FixedContainer>
    </BlockSpacingContainer>
  );
};
