/* eslint-disable react/function-component-definition */
import { useMediaQuery } from "@mui/material";
import { useKeenSlider } from "keen-slider/react";
import Image from "next/legacy/image";
import Link from "src/ui/components/BlockFeatureImageCard/Link";
import { IMAGE_QUALITY, SITE_URL } from "src/constants";
import { FC, useEffect, useState } from "react";
import { BlockProductCollectionSliderFieldsFragment } from "src/generated/datocms-types";
import FixedContainer from "src/shared/components/FixedContainer";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import tw, { theme as twTheme } from "twin.macro";
import { slugsPrefixMap } from "src/services/datocms/constants";

export type BlockProductCollectionSliderProps = {
  data: BlockProductCollectionSliderFieldsFragment;
};
const roundedStyleMap = {
  0: tw`rounded-tl-lg`,
  1: tw`rounded-tr-lg`,
  2: tw`rounded-bl-lg`,
  3: tw`rounded-br-lg`,
};
export const BlockProductCollectionSlider: FC<
  BlockProductCollectionSliderProps
> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const isXl = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const { title, titleHtml, subtitle,subtitleHtml, pageLinkUrl, productColletionLink, showSideTextOnDesktop=false, bgColor } = data;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showRighArrow, setshowRighArrow] = useState(false);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
      breakpoints: {
      "(min-width: 600px)": {
        slidesPerView: 2,
        spacing: 5,
        loop: false,
        centered:true
      },
      "(min-width: 1024px)": {
        slidesPerView: 2,
        spacing: 0,
      },
      "(min-width: 1110px)": {
        slidesPerView: 2,
        spacing: 0,
      },
      "(min-width: 1280px)": {
        slidesPerView: 3,
        spacing: 0,
      },
       "(min-width: 1366px)": {
        slidesPerView: 3,
        spacing: 0,
      }, 
    },
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  const styles = [
    isDesktop ? tw` w-7` : tw`w-12`,
    tw`absolute cursor-pointer fill-current`,
  ];
  const smallerDeskDimensions = isXl ? 300 : 400; 
  const smallerDeskDimensionsH = isXl ? 280 : 360; 
  const productImageSize = {
    w: isLargeDesktop ? 333 : smallerDeskDimensions,
    h: isLargeDesktop ? 331 : smallerDeskDimensionsH,
  };
  const isMobile = !isDesktop && !isXl;
  const containerWidth = !isLargeDesktop ? "1200px" : "1360px";
  const hasWhiteSmokeColor = bgColor === "whiteSmoke";
  const hasTitleHTML = (titleHtml?.length ?? 0) > 0;
  const titleText = (hasTitleHTML ? titleHtml : title) ?? "";
  const hasSubTitleHTML = (subtitleHtml?.length ?? 0) > 0;
  const subTitleText =  (hasSubTitleHTML ? subtitleHtml : subtitle) ?? "";
   useEffect(() => {
    if (slider) {
      slider.refresh();
    }
   }, [data, slider]);
   useEffect(() => {
    if (slider) {
      const { slidesPerView } = slider.details();
      if (slidesPerView > 1) {
        setshowRighArrow(
          currentSlide <
            Math.round(slider.details().size / slider.details().slidesPerView)
        );
      } else {
        setshowRighArrow(
          currentSlide + 1 <
            Math.ceil(slider.details().size / slider.details().slidesPerView)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider, currentSlide, setshowRighArrow]);
  return (
    <>
    
    <FixedContainer
      CustomContainer
      css={[
        tw`text-center hidden lg:block mt-4`,
        isMobile
          ? tw`container px-4 sm:px-0`
          : [tw`mx-auto px-0`, { maxWidth: !isXl ? "980px" : containerWidth }],
      ]}
      >
        {!showSideTextOnDesktop && (
           <div css={tw`w-full flex flex-wrap justify-center py-14`}>
           <div
              css={tw`font-roboto text-sm font-bold uppercase w-full text-center pt-4`}  dangerouslySetInnerHTML={{
                __html: subTitleText,
              }} />
            <h3 css={tw`font-playfair text-3xl w-full text-center`} dangerouslySetInnerHTML={{
                __html: titleText,
              }} />
         </div>
        ) }
        <div css={tw`flex justify-center`}>
        {showSideTextOnDesktop  && (
          <div css={[hasWhiteSmokeColor ? [{background: "#F8F8F8"}]: tw`bg-white` ,tw`w-[14%] lg:w-[33%] xl:w-[25%] flex flex-wrap justify-center items-center text-left h-auto`]}>
                <div css={tw`w-full`}>
                <div css={tw` font-roboto font-bold text-sm w-full uppercase pb-2`} dangerouslySetInnerHTML={{
                __html: titleText,
              }}/>
                <div css={tw`w-[80%] xl:w-[70%]`}>
                  <div css={tw`font-playfair text-4xl`} dangerouslySetInnerHTML={{
                __html: subTitleText,
              }}/>
                </div>
              </div> 
          </div>
          )}
           <div css={tw`relative w-[85%] lg:w-[67%] xl:w-[75%]`}>
            <FixedContainer
              CustomContainer
              css={[tw`w-full`]}
            >
            <div ref={sliderRef} className="keen-slider">
            {productColletionLink.map((collection) => {
              const collectionUrl = `${slugsPrefixMap.page}${collection.landingPageLink?.slug}`;
                return (
                  <div className="keen-slider__slide" css={tw`text-left px-0.5`} key={collection.id}>
                      <a href={collectionUrl}>
                        <Image
                          src={`${collection.image?.url}?w=${550}&h=${500}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`}
                          width={productImageSize.w}
                          height={productImageSize.h}
                          quality={IMAGE_QUALITY}
                          alt={collection.image?.alt ?? ""}
                          css={tw`object-cover`}
                        />
                      </a>
                      <div css={tw`py-4 text-left`}>
                        <span css={tw`font-roboto font-bold text-sm uppercase `}>
                          {collection.title}
                        </span>
                      </div>
                  </div>
                );
            })}
           
            </div>
            </FixedContainer> 
            <div css={[styles, tw`-left-8 top-0`, { background: "#11ffee00",height: productImageSize.h}]}>
              <div css={[tw`relative top-[38%] xl:top-[45%]`, { height: productImageSize.h }]}>
                <div css={[styles]}>
                    <MdArrowBackIos css={tw`w-6 h-6`} onClick={() => slider.prev()}/>
                </div>
              </div>
            </div>
            <div css={[styles, tw`-right-8 top-0`, { background: "#11ffee00",height: productImageSize.h}]}>
              <div css={[tw`relative top-[38%] xl:top-[45%]`]}>
                <div css={[styles]}>
                  <MdArrowForwardIos css={tw`w-6 h-6`} onClick={() => slider.next()} />
                </div>
              </div>
            </div>
       </div>
    </div>
        
      </FixedContainer>
        <FixedContainer css={tw`block lg:hidden`}>
          <div css={tw`w-full flex flex-wrap justify-center py-8`}>
            <div
            css={tw`font-roboto text-sm font-bold uppercase w-full text-center pb-2`} dangerouslySetInnerHTML={{
              __html: titleText,
            }} />
          <h3 css={tw`font-playfair text-3xl w-full text-center`} dangerouslySetInnerHTML={{
              __html: subTitleText,
            }}/>
          </div>
          <div css={tw`flex flex-wrap justify-center`}>
            {productColletionLink.slice(0, 4).map((product, index) => {
              const isPair = (index + 1) % 2 === 0;
              const roundedImage =
                roundedStyleMap[index as keyof typeof roundedStyleMap] ??
                tw`font-roboto`;
              return (
                <div
                  key={product.id}
                  css={[tw`flex px-[1px] md:(px-1 py-[1px]) -my-0.5 md:-m-0.5 w-1/2 md:w-1/2 `,
                  !isPair ? tw`justify-end` : tw`justify-start`]}
                >
                  <div css={tw`relative flex items-center `}>
                    <Link
                      key={product.id}
                      isExternal={false}
                      href={
                        product.landingPageLink?.slug
                          ? `${SITE_URL}/${product.landingPageLink?.slug}`
                          : SITE_URL
                      }
                    >
                      <Image
                        src={`${product.image?.url}?w=${550}&h=${500}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`}
                        width={productImageSize.w}
                        height={productImageSize.h}
                        quality={IMAGE_QUALITY}
                        layout="intrinsic"
                        alt="product"
                        css={[tw`brightness-75 lg:brightness-100 object-cover`, roundedImage]}
                      />
                    </Link>
                    <div
                      css={tw`absolute z-10 text-white font-roboto uppercase text-sm font-bold w-full text-center tracking-wider p-4`}
                    >
                      <span>{product.title}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </FixedContainer>
    </>
  );
};
