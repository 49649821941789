import { FC } from "react";
import { FeatureTestimonialCompaniesContentFragment } from "src/generated/datocms-types";
import FixedContainer from "src/shared/components/FixedContainer";
import Image from "next/legacy/image";
import tw from "twin.macro";
import { IMAGE_QUALITY } from "src/constants";

export type FeatureTestimonialCompaniesContentProps = {
  data: FeatureTestimonialCompaniesContentFragment;
};

export const TestimonialCard: FC<FeatureTestimonialCompaniesContentProps> = ({
  data,
}) => {
  const imageSrc = `${data.image?.url}?w=200&dpr=2`;
  return (
    <FixedContainer>
      <div key={data.id} css={tw`relative w-full my-4 lg:px-4 `}>
        <div>
          <div css={[tw`relative`, { height: 150 }]}>
            <Image
              css={tw`object-contain`}
              src={imageSrc}
              layout="fill"
              quality={IMAGE_QUALITY}
              alt={data.image?.alt || ""}
            />
          </div>
          <blockquote
            css={tw`font-playfair text-lg  text-center px-2  lg:(text-xl px-44) overflow-auto mb-2 `}
          >
            <p>&quot;{data.quote}&quot;</p>
          </blockquote>
        </div>
      </div>
    </FixedContainer>
  );
};
