import { fourSixtyScript } from "src/shared/types/fourSixty";

export const instagramFullPageScript: fourSixtyScript = {
  src: "https://foursixty.com/media/scripts/fs.embed.v2.5.js",
  dataTheme: "lookbook_v2_5",
  dataPageSize: "20",
};

export const instagramFullPageStyle = `
  <style>
    .fs-has-links::after {
      padding: 7px 10.5px;
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.8);
      content: "SHOP IT";
    }
    .fs-wrapper div.fs-text-container .fs-entry-title,
    div.fs-detail-title {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
    div.fs-text-container .fs-entry-date,
    div.fs-detail-container .fs-post-info,
    div.fs-wrapper div.fs-has-links::after,
    .fs-text-product,
    .fs-overlink-text {
      font-family: Lato;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
    .fs-wrapper div.fs-text-container * {
      color: #ffffff;
    }
    .fs-wrapper div.fs-text-container {
      background-color: rgba(0, 0, 0, 0.8);
    }
    div.fs-entry-date {
      display: none;
    }
    div.fs-entry-title {
      display: none;
    }
    .fs-wrapper div.fs-timeline-entry {
      margin: 5px;
    }
    .fs-next-page {
        margin-left: auto;
        margin-right: auto;
        width: auto;
    }
  </style>
`;
