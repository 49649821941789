/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useEffect, useState } from "react";
import tw, { theme as twTheme, TwStyle } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import FixedContainer from "src/shared/components/FixedContainer";
import { renderInternalExternalLinks } from "src/shared/helpers";
import { CTAButton } from "src/shared/types";
import {
  BlockHeroButtonsFragment,
  BlockHeroFieldsFragment,
} from "src/generated/datocms-types";
import { Button } from "src/shared/components/Button";
import { IMAGE_QUALITY } from "src/constants";
import { useEntryObject } from "src/ui/components/BlockEmbeddedForm/state";
import { scrollIntoFormElement } from "src/ui/components/BlockEmbeddedForm/utils";

const OPTIMIZE_EXPERIMENT_ID = "jx0fhlurSQSsZgxDWGvWQw";

const fontFamilyMap = {
  lato: tw`font-lato`,
  roboto: tw`font-roboto`,
  playfair: tw`font-playfair`,
};

const fontFamilyTitleMap = {
  lato: tw`font-lato`,
  roboto: tw`font-roboto`,
  playfair: tw`font-playfair`,
};
export type BlockHeroProps = {
  data: BlockHeroFieldsFragment & { buttonsType?: "default" | "accelPay" };
};
const maxContentWidthMap = {
  large: tw`max-w-full`,
  medium: tw`max-w-lg`,
  small: tw`max-w-xs`,
};

const abTestMap = {
  0: "testA",
  1: "testB",
};

// eslint-disable-next-line react/function-component-definition
export const BlockHero: FC<BlockHeroProps> = ({
  data: {
    bgImage,
    bgVideo,
    bgVideoPosterImage,
    isShortHeight,
    buttons,
    colorButton,
    hasBiggerButton = false,
    centered: isCentered,
    centeredMobile: isCenteredMobile,
    mobileImage,
    subtitle,
    subtitleAfter,
    text,
    textMobile,
    theme,
    titlePadded,
    title,
    titleLogo,
    titleLogoMobile,
    titleLogoAfter,
    isBigVersionLogo,
    isTitleFontBig,
    contentWidth,
    titleFontFamily,
    subtitleFontFamily,
    titleSubtitleFontSize,
    buttonsType = "default",
    isTitleH1,
    hasAButtonToScrollToAComponent,
    variantStyle,
    buttonTitle,
    hasLightTheme = false,
    hideInMobileVersion = false,
  },
}) => {
  const { currentEntryObject } = useEntryObject();
  const [optimizeVariant, setOptimizeVariant] = useState("");
  const [variant, setvariant] = useState("");

  const splitCaracter = title?.trim().split("_") || [];
  const hasSplitCaracter = splitCaracter?.length > 1;
  const testName = hasSplitCaracter ? splitCaracter[0] : "";

  const contentTitle = hasSplitCaracter ? splitCaracter[1] : title;
  const hasImageAsTitle =
    titleLogo?.format === "svg" || titleLogo?.format === "png";
  useEffect(() => {
    if (testName !== "") {
      if (window.google_optimize !== undefined) {
        const _variant = window.google_optimize.get(OPTIMIZE_EXPERIMENT_ID);
        setvariant(_variant);
        const variantValue =
          abTestMap[_variant as keyof typeof abTestMap] ?? "";
        setOptimizeVariant(variantValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant]);

  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const isDarkMode = theme === "dark";
  const firstButton: BlockHeroButtonsFragment[] =
    buttons?.length && buttons?.[0] ? [buttons[0]] : [];
  const buttonStyle = [
    tw`w-auto text-sm`,
  ];
  const ContentStyle = [!isDesktop ? tw`text-center` : tw`text-left`];

  const headerHeight = isShortHeight ? "short" : "large";
  const headerHeightStylehMap = {
    large: { h: isDesktop ? 600 : 550 },
    short: { h: isDesktop ? 400 : 250 },
  };
  const BigVersionLogo = isBigVersionLogo ? "large" : "default";
  const DescriptionHeight = !text && buttons?.length ? 340 : 150;
  const LogoDescriptionHeight = text ? 340 : DescriptionHeight;
  const BigVersionLogohMap = {
    large: { h: isDesktop ? LogoDescriptionHeight : 150 },
    default: { h: 180 },
  };

  const maxContentWidth =
    maxContentWidthMap[contentWidth as keyof typeof maxContentWidthMap] ??
    tw`max-w-xl`;

  const headerHeightStyle = headerHeightStylehMap[
    headerHeight as keyof typeof headerHeightStylehMap
  ] ?? { h: isDesktop ? 600 : 400 };

  const BigVersionLogoStyle = BigVersionLogohMap[
    BigVersionLogo as keyof typeof BigVersionLogohMap
  ] ?? { h: 60 };

  const SubTitlefontFamilyKey = subtitleFontFamily ?? "roboto";

  const SubtitlefontFamilyStyle =
    fontFamilyMap[SubTitlefontFamilyKey as keyof typeof fontFamilyMap];

  const stylesSubtitle = [SubtitlefontFamilyStyle];

  const hasLargeText = titleSubtitleFontSize === "large";

  const titleFontBigStyle = isTitleFontBig
    ? tw`text-3xl lg:text-5xl lg:px-0`
    : tw`text-3xl lg:text-4xl lg:px-0`;

  if (!isDesktop && hideInMobileVersion) return null;
  const renderSubHeading = (props: TwStyle = {}) => {
    return (
      <p
        css={[
          hasLargeText ? tw`lg:text-lg` : tw`text-sm`,
          tw`uppercase tracking-wider font-roboto my-4 font-bold`,
          isDesktop ? stylesSubtitle : tw`font-bold`,
          props,
        ]}
        dangerouslySetInnerHTML={{
          __html: subtitle ?? "",
        }}
      />
    );
  };
  const renderHeadingImage = (showBefore: boolean) => {
    const mobileLogoAlign = isCenteredMobile ? "0%" : "35%";
    const desktopLogoAlign = isCentered ? "0%" : "1%";
    const widthStyle = isCentered ? tw` mx-auto` : tw`w-3/5`;

    const hasmobileLogoUrl = !!titleLogoMobile?.url?.length;
    const mobileImageURL = hasmobileLogoUrl
      ? titleLogoMobile?.url || ""
      : titleLogo?.url || "";
    const desktopImageURL = titleLogo?.url || "";
    return (
      <div
        css={[
          tw`relative`,
          !isDesktop && tw` mx-auto`,
          isDesktop && widthStyle,
          { right: isDesktop ? desktopLogoAlign : mobileLogoAlign },
          !isBigVersionLogo && tw`w-full`,
          { height: BigVersionLogoStyle.h },
          showBefore ? tw`mb-0 lg:mb-4` : tw`mt-4`,
        ]}
      >
        {isDesktop && hasImageAsTitle && (
          <Image
            css={tw`block object-contain`}
            src={desktopImageURL}
            layout="fill"
            quality={IMAGE_QUALITY}
            priority
            alt={titleLogo?.alt ?? ""}
          />
        )}
        {!isDesktop && hasImageAsTitle && (
          <Image
            css={tw`block object-contain`}
            src={mobileImageURL}
            layout="fill"
            quality={IMAGE_QUALITY}
            priority
            alt={titleLogo?.alt ?? ""}
          />
        )}
      </div>
    );
  };
  const renderImage = (
    image:
      | BlockHeroFieldsFragment["bgImage"]
      | BlockHeroFieldsFragment["mobileImage"]
  ) => {
    const imageSrc = `${image?.url}?h=${headerHeightStyle.h}`;
    return (
      <div css={[tw`relative`, { height: headerHeightStyle.h }]}>
        <Image
          css={tw`object-cover`}
          src={imageSrc}
          layout="fill"
          quality={IMAGE_QUALITY}
          priority
          alt={image?.alt ?? ""}
        />
      </div>
    );
  };
  const renderHeroImage = () => {
    if (mobileImage?.responsiveImage && !isDesktop) {
      return renderImage(mobileImage);
    }
    return renderImage(bgImage);
  };
  const renderTitle = () => {
    const fontFamilyKey = titleFontFamily ?? "roboto";

    const titleDesktopStyle = isDesktop
      ? [tw`px-24 leading-loose`, { fontSize: "37px", lineHeight: "47px" }]
      : [tw`text-3xl sm:px-28`];

    const titlePaddedStyle = titlePadded
      ? titleDesktopStyle
      : titleFontBigStyle;
    const hasTitleMinWidth = contentWidth === "medium";
    const fontFamilyStyle =
      fontFamilyTitleMap[fontFamilyKey as keyof typeof fontFamilyTitleMap];
    const styles = [
      tw`m-0 leading-tight`,
      hasLargeText ? titlePaddedStyle : tw`text-3xl lg:text-4xl`,
      fontFamilyStyle,
      !isDesktop && hasImageAsTitle && tw`pb-2`,
      !isDesktop && !hasImageAsTitle && tw`pb-4`,
      hasTitleMinWidth &&
        hasLargeText &&
        !isCentered &&
        isDesktop && [tw`w-full lg:w-1/2 max-w-xs xl:(w-auto max-w-sm pr-9) `],
    ];

    if (hasLargeText) {
      if (isTitleH1) {
        return (
          <h1
            css={styles}
            dangerouslySetInnerHTML={{
              __html: contentTitle ?? "",
            }}
          />
        );
      }
      return (
        <h3
          css={styles}
          dangerouslySetInnerHTML={{
            __html: contentTitle ?? "",
          }}
        />
      );
    }

    if (!hasLargeText) {
      if (isTitleH1) {
        return (
          <h1
            css={[styles, hasImageAsTitle && tw`px-2`]}
            dangerouslySetInnerHTML={{
              __html: contentTitle ?? "",
            }}
          />
        );
      }
      return (
        <h3
          css={[styles, hasImageAsTitle && tw`pb-12 lg:(px-2 pb-0)`]}
          dangerouslySetInnerHTML={{
            __html: contentTitle ?? "",
          }}
        />
      );
    }
  };
  const mobileContentPosition = [isCenteredMobile ? "55%" : "35%"];
  let textMobileContent = text || "";
  if (textMobile?.length) {
    textMobileContent = textMobile;
  }

  const isValidToRender = testName === "" || optimizeVariant === testName;
  const ctaProps: CTAButton = {
    whiteColor: hasLightTheme,
    hasBiggerButton,
    colorButton,
  };
  if (!isValidToRender) return null;
  const sendEvent = () => {
    if (window.dataLayer && optimizeVariant === testName) {
      window.dataLayer.push({
        event: "AB Test Event",
        abTestMetadata: {
          category: "AB Test",
          label: optimizeVariant,
          action: "Click CTA Subscribe",
        },
      });
    }
  };
  const renderHeroVideo = () => {
    const videoSrc = bgVideo?.url;
    return (
      <div
        css={[
          tw`relative`,
          { height: headerHeightStyle.h },
          title ||
            subtitle ||
            text ||
            (buttons.length > 0 && tw`relative brightness-50`),
        ]}
      >
        <video
          css={tw`object-cover h-full w-full`}
          src={videoSrc}
          autoPlay
          muted
          loop
          poster={bgVideoPosterImage?.url}
        />
      </div>
    );
  };
  const renderScrollToButton = () => {
    return (
      <Button
        variant={variantStyle ?? "outline"}
        size="small"
        onClick={() => scrollIntoFormElement(currentEntryObject)}
        tabIndex={0}
        css={[!isDesktop && tw`mx-2 text-sm`]}
      >
        {buttonTitle}
      </Button>
    );
  };
  return (
    <div
      css={[
        tw`w-full max-w-largeScreenContainer mx-auto h-full relative overflow-hidden bg-white`,
      ]}
    >
      {isDesktop && bgVideo ? renderHeroVideo() : renderHeroImage()}
      <div
        css={[
          tw`w-full h-auto absolute`,
          {
            top: isDesktop ? "50%" : mobileContentPosition,
            left: "50%",
            transform: "translate(-50%,-50%)",
          },
          isDarkMode ? tw`text-white` : tw`text-black`,
        ]}
      >
        <FixedContainer
          css={[
            isCentered ? tw`text-center` : ContentStyle,
            !isDesktop ? tw`px-9` : tw`px-0`,
            isDesktop && !isLargeDesktop && !isCentered
              ? tw`mx-10`
              : tw`mx-auto`,
          ]}
        >
          <div
            css={[
              isCentered ? tw`mx-auto` : tw`mx-0`,
              isDesktop && maxContentWidth,
            ]}
          >
            {titleLogo && !titleLogoAfter && renderHeadingImage(true)}
            {subtitle && !subtitleAfter && renderSubHeading(tw`mb-2`)}
            {renderTitle()}
            {subtitle && subtitleAfter && renderSubHeading()}
            {text && (
              <div
                css={[
                  { "&>p": tw`mb-4` },
                  isCentered ? tw`mx-auto` : tw`mx-0`,
                  tw`my-4 font-roboto max-w-3xl text-sm lg:text-base`,
                  isDarkMode ? tw`text-white` : tw`text-gray-700`,
                ]}
                dangerouslySetInnerHTML={{
                  __html: isDesktop ? text : textMobileContent,
                }}
              />
            )}
            {titleLogo && titleLogoAfter && renderHeadingImage(false)}
            {isDesktop && buttons?.length && buttonsType === "default" ? (
              <div css={tw` lg:mt-8`}>
                {renderInternalExternalLinks(
                  buttons as BlockHeroButtonsFragment[],
                  ctaProps,
                  sendEvent
                )}
                {hasAButtonToScrollToAComponent && renderScrollToButton()}
              </div>
            ) : (
              <>
                {firstButton?.length > 0 && buttonsType === "default" && (
                  <div css={[{ "&>a": buttonStyle }]}>
                    {renderInternalExternalLinks(
                      firstButton,
                      ctaProps,
                      sendEvent
                    )}
                    {hasAButtonToScrollToAComponent && renderScrollToButton()}
                  </div>
                )}
              </>
            )}
            {buttonsType === "accelPay" && (
              <div data-bccollectionid="309530">SHOP NOW</div>
            )}
          </div>
        </FixedContainer>
      </div>
    </div>
  );
};
