import tw from "twin.macro";
import {
  FC,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
  Dispatch,
} from "react";
import { useCurrentFilterSelection } from "src/features/Cart/state";
import { FilterDesktopComponent } from "src/shared/components/FilterDesktopComponent";
import { CollectionProductsMap, FilterProduct } from "src/shared/types";
import { Box, IconButton, Paper, Popper, Tab, Tabs } from "@mui/material";
import { FiChevronDown } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { productSortOptions } from "src/constants";
import {
  getFilterListForProductList,
  setFilterList,
} from "src/shared/utils/products";
import {
  ProductCollectionFieldsFragment,
  ProductFieldsShortFragment,
} from "src/generated/datocms-types";
type Props = {
  filterOptions: FilterProduct[];
  productCollection: CollectionProductsMap;
  collections: ProductCollectionFieldsFragment[];
  setSortByKey: Dispatch<SetStateAction<string>>;
};

export const ProductCollectionComponent: FC<Props> = ({
  filterOptions,
  productCollection,
  collections,
  setSortByKey,
}) => {
  const { addCurrentFilterSelection, currentFilterSelection } =
    useCurrentFilterSelection();
  const [filterListSelected, setFilterListSelected] = useState<FilterProduct>();
  const [removeFilterListSelected, setRemoveFilterListSelected] =
    useState<FilterProduct>();
  const [removeAll, setRemoveAll] = useState<boolean>(false);
  const [isStickyInfo, setisStickyInfo] = useState(false);
  const [innerHeight, setInnerHeight] = useState(0);
  const [productInfoHeight, setProductInfoHeight] = useState(0);
  const [previousBottom, setPreviousBottom] = useState(0);
  const [sortByOptionSelected, setSortByOptionSelected] = useState("Featured");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [open, setOpen] = useState(false);
  const [filterDisabledOptions, setFilterDisabledOptions] = useState<
    FilterProduct[]
  >([]);
  const [allProducts, setAllProducts] = useState<ProductFieldsShortFragment[]>(
    []
  );

  const getDisabledFilterOptions = (
    filterProductsSelected: FilterProduct[]
  ) => {
    if (filterProductsSelected && filterProductsSelected.length > 0) {
      let filterOptionsReview: FilterProduct[] = [];
      const filterProductsresultTypes = Array.from(
        new Set(filterProductsSelected.map((m) => m.type) ?? [])
      );

      if (filterProductsresultTypes.length === 1)
        filterOptionsReview = filterOptions.filter(
          (f) => f.type !== filterProductsresultTypes[0]
        );
      else if (filterProductsresultTypes.length > 1)
        filterOptionsReview = filterOptions.filter(
          (f) => !filterProductsSelected.some((s) => s.tagId === f.tagId)
        );

      const productFiltered = setFilterList(
        allProducts,
        filterProductsSelected
      );
      const filterOptDisabled = getFilterListForProductList(
        productFiltered as ProductFieldsShortFragment[],
        filterOptionsReview
      );

      const filterOptDisabledResult = [...filterOptDisabled];
      setFilterDisabledOptions(filterOptDisabledResult);
    } else setFilterDisabledOptions([]);
  };

  const handleScroll = useCallback(() => {
    setOpen(false);
    const element = document.getElementById("filter-component");
    if (element) {
      const { bottom, height } = element.getBoundingClientRect();
      const isNearBottom = bottom <= window.innerHeight;
      setProductInfoHeight(height);
      setInnerHeight(window.innerHeight + 30);
      if (isNearBottom || window.innerHeight > height) {
        setisStickyInfo(true);
      } else if (isStickyInfo && bottom > previousBottom) {
        setisStickyInfo(false);
      }
      setPreviousBottom(bottom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const _allProducts: ProductFieldsShortFragment[] = [];
    collections.forEach((collection) => {
      const productByTagSorted: ProductFieldsShortFragment[] = [];
      const tags = collection.productTags;
      const productsByTag = productCollection[collection.id] ?? [];
      tags.forEach((t) => {
        const products = productsByTag.filter((c) =>
          c.tags.find((i) => i.id === t.id)
        );
        products.forEach((m) => productByTagSorted.push(m));
      });
      const manualProducts = collection.products ?? [];
      const products = manualProducts
        .concat(productByTagSorted)
        .slice() as ProductFieldsShortFragment[];

      _allProducts.push(...products);
    });
    setAllProducts(_allProducts);
  }, [collections, productCollection]);
  useEffect(() => {
    if (removeFilterListSelected) {
      const currentListSelection = currentFilterSelection ?? [];
      const newSelection = currentListSelection.filter(
        (f) => f.tagId !== removeFilterListSelected.tagId
      );

      getDisabledFilterOptions(newSelection);
      addCurrentFilterSelection(
        newSelection.length > 0 ? newSelection : undefined
      );
      setRemoveFilterListSelected(undefined);
    }
    if (removeAll) {
      addCurrentFilterSelection(undefined);
      setRemoveAll(false);
      setFilterDisabledOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    removeAll,
    removeFilterListSelected,
    currentFilterSelection,
    allProducts,
  ]);
  useEffect(() => {
    if (filterListSelected) {
      const currentListSelection = currentFilterSelection ?? [];
      const isSelected = currentListSelection.includes(filterListSelected);
      if (!isSelected) {
        currentListSelection?.push(filterListSelected);
        getDisabledFilterOptions(currentListSelection);
        addCurrentFilterSelection(currentListSelection);
        setFilterListSelected(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterListSelected, currentFilterSelection, allProducts]);
  useEffect(() => {
    // remove filter options selected, if it is disabled too
    const removeFilterProducts = (currentFilterSelection ?? []).filter(
      (f) => !filterDisabledOptions.some((s) => s.tagId === f.tagId)
    );

    if (removeFilterProducts.length > 0)
      addCurrentFilterSelection(removeFilterProducts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDisabledOptions]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <div
      id="filter-component"
      css={[
        tw`flex z-1 bg-white sticky`,
        isStickyInfo &&
          innerHeight <= productInfoHeight && {
            top: `-${productInfoHeight - innerHeight}px`,
          },
        isStickyInfo && innerHeight > productInfoHeight && [{ top: "93px" }],
      ]}
    >
      <div css={tw`w-2/6`}>
        <FilterDesktopComponent
          filterOptions={filterOptions}
          setFilterListSelected={setFilterListSelected}
          setRemoveFilterListSelected={setRemoveFilterListSelected}
          currentFilterSelection={currentFilterSelection ?? []}
          filterDisabledOptions={filterDisabledOptions}
        />
      </div>
      <div css={tw`w-1/2`}>
        {currentFilterSelection && (
          <div css={tw`flex mt-3`}>
            <div css={tw`flex`}>
              {currentFilterSelection.map((i) => (
                <div
                  css={tw`flex pr-8 items-center pb-2`}
                  key={`itemTagId${i.tagId}`}
                >
                  <div
                    css={[
                      tw`font-roboto capitalize`,
                      { color: "#666666", fontSize: "12px" },
                    ]}
                  >
                    {i.filterName}
                  </div>
                  <div css={tw`flex items-start`}>
                    <IconButton
                      onClick={() => {
                        setRemoveFilterListSelected(i);
                      }}
                      css={tw`text-gray-500 h-0 px-1 hover:bg-transparent`}
                      size="small"
                    >
                      <MdClose />
                    </IconButton>
                  </div>
                </div>
              ))}
            </div>
            <div css={tw`flex pr-8 items-center pb-2`}>
              <span
                onClick={() => setRemoveAll(true)}
                onKeyPress={() => setRemoveAll(true)}
                role="button"
                tabIndex={0}
              >
                <div
                  css={[
                    tw`font-roboto capitalize underline underline-offset-4`,
                    { color: "#666666", fontSize: "12px" },
                  ]}
                >
                  {" "}
                  Clear All
                </div>
              </span>
            </div>
          </div>
        )}
      </div>
      <div
        css={tw`w-1/6 flex justify-end cursor-pointer items-center font-bold`}
      >
        <Tabs
          value={0}
          TabIndicatorProps={{
            style: {
              height: 0,
            },
          }}
        >
          <Tab
            sx={{
              "&.Mui-selected": {
                color: "#666666",
              },
            }}
            css={[
              tw`block py-0 pl-0 font-roboto tracking-tight border-solid border-transparent min-w-0`,
              {
                borderBottomWidth: "3px",
                fontSize: "0.85rem",
                color: "#666666",
              },
            ]}
            key="sortMenuPopper"
            onClick={(e) => {
              setAnchorEl(e.currentTarget);
              setOpen(true);
            }}
            onMouseEnter={(e) => {
              setAnchorEl(e.currentTarget);
              setOpen(true);
            }}
            onMouseLeave={() => setOpen(false)}
            data-key={0}
            label={
              <div css={tw`flex items-center gap-2 font-roboto font-bold`}>
                <div>Sort By:</div>
                <div css={tw`capitalize`}>{sortByOptionSelected}</div>
                <FiChevronDown css={tw`font-bold`} />
              </div>
            }
            aria-haspopup="true"
          />
        </Tabs>
        <Box>
          <Popper
            id="simple-popper"
            open={open}
            anchorEl={anchorEl}
            placement="bottom-end"
            onMouseEnter={() => {
              setOpen(true);
            }}
            onMouseLeave={() => setOpen(false)}
          >
            <Paper sx={{ borderRadius: "0px", border: "0px" }}>
              <div
                css={[
                  tw`py-6 px-8 font-roboto grid grid-rows-3 grid-flow-col gap-2`,
                  {
                    fontSize: "13.5px",
                    borderWidth: "1px",
                    borderColor: "#CCCCCC",
                  },
                ]}
              >
                {Object.keys(productSortOptions).map((k, idx) => (
                  <div css={tw`w-full`} key={`optionTitle ${idx + 1}`}>
                    <span
                      onClick={() => {
                        setSortByKey(k ?? "unsorted-default");
                        setSortByOptionSelected(
                          productSortOptions[
                            k as keyof typeof productSortOptions
                          ]
                        );
                        setOpen(false);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyPress={() => {
                        return null;
                      }}
                      css={tw`cursor-pointer hover:text-red-500 block`}
                    >
                      {productSortOptions[k as keyof typeof productSortOptions]}
                    </span>
                  </div>
                ))}
              </div>
            </Paper>
          </Popper>
        </Box>
      </div>
    </div>
  );
};
