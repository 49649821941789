/* eslint-disable react/function-component-definition */
import { FC, useEffect, useState } from "react";
import tw, { TwStyle, theme as twTheme } from "twin.macro";
import {
  Breadcrumbs,
  useMediaQuery,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import NextLink from "next/link";
import Head from "next/head";
import { SITE_URL } from "src/constants";
import { useRouter } from "next/router";

type BreadcrumbItem = {
  "@type": "ListItem";
  position: number;
  name: string;
  item?: string; // href
};
export type BreadcrumbLink = {
  name: string;
  item: string; // href
};
type Props = {
  title: string;
  crumbs: BreadcrumbLink[];
  containerStyles?: TwStyle;
  showOnMobile?: boolean;
};

const theme = createTheme({
  components: {
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          marginLeft: "4px",
          marginRight: "4px",
        },
      },
    },
  },
});
export const ProductBreadcrumb: FC<Props> = ({
  title,
  crumbs,
  containerStyles,
  showOnMobile = false,
}) => {
  const [breadcrumbTitle, setBreadcrumbTitle] = useState<string>(title);
  const breadcrumbItems: BreadcrumbItem[] = crumbs.map((b, idx) => {
    return {
      "@type": "ListItem",
      position: idx + 1,
      name: b.name,
      item: `${SITE_URL}${b.item}`,
    };
  });
  breadcrumbItems.push({
    "@type": "ListItem",
    position: crumbs.length + 1,
    name: breadcrumbTitle,
  });
  const breadcrumbJsonLd = `
      {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": ${JSON.stringify(breadcrumbItems)}
    }
  `;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const breadcrumbsStyle = [
    tw`font-roboto font-bold text-black uppercase`,
    { fontSize: "11.6px" },
  ];

  const hasTitle = breadcrumbTitle.trim().length > 0;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cityParam = urlParams.get("city") as string;
    setBreadcrumbTitle(
      cityParam ? `${cityParam} ${breadcrumbTitle}` : breadcrumbTitle
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!isDesktop && !showOnMobile) {
    return null;
  }
  return (
    <div css={[containerStyles, tw`pt-4`]}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: breadcrumbJsonLd }}
          key="jsonld-breadcrumb"
        />
      </Head>
      <div>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Breadcrumbs>
              <NextLink passHref href="/" css={breadcrumbsStyle}>
                Home
              </NextLink>
              {crumbs.map((c) => (
                <NextLink
                  passHref
                  href={c.item}
                  key={c.item}
                  css={breadcrumbsStyle}
                >
                  {c.name}
                </NextLink>
              ))}
              {hasTitle && (
                <span css={breadcrumbsStyle}>{breadcrumbTitle}</span>
              )}
            </Breadcrumbs>
          </ThemeProvider>
        </StyledEngineProvider>
      </div>
    </div>
  );
};
