/* eslint-disable react/function-component-definition */
import { BlockFeatureLinkCollectionFieldsFragment } from "src/generated/datocms-types";
import { FC } from "react";
import tw from "twin.macro";
import NextLink from "next/link";
import { getInternalLink, InternalOrExternalLink } from "src/shared/helpers";

type PilarPagesProps = {
  data: BlockFeatureLinkCollectionFieldsFragment;
};
export const PilarPages: FC<PilarPagesProps> = ({ data }) => {
  const { linkCollection, title } = data;
  return (
    <div css={tw`my-6`}>
      <div>
        <h6
          css={tw`text-sm uppercase tracking-wider font-roboto font-bold text-bloomsy-red font-roboto mb-4`}
        >
          {title}
        </h6>
      </div>
      <ul css={[tw`grid grid-cols-2 lg:grid-cols-6 text-sm`]}>
        {linkCollection.map((lc) => {
          const href = getInternalLink(
            lc?.internalLink as InternalOrExternalLink
          );
          return (
            <li key={lc.id} css={tw`my-0.5`}>
              <a key={lc?.id} href={href} css={tw`text-gray-700`}>
                {lc?.title}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

type MenuLinksProps = {
  data: BlockFeatureLinkCollectionFieldsFragment;
};
export const Tags: FC<MenuLinksProps> = ({ data }) => {
  const { linkCollection } = data;
  return (
    <div
      css={tw`p-4 flex  flex-wrap items-center overflow-auto w-full  md:justify-start`}
    >
      {linkCollection.map((lc) => {
        const href = getInternalLink(
          lc?.internalLink as InternalOrExternalLink
        );
        return (
          <NextLink key={lc?.id} href={href} passHref legacyBehavior>
            <button
              type="button"
              css={[
                tw`font-roboto uppercase tracking-wider bg-white px-4 py-2 m-2 rounded-full text-xs border border-gray-200 whitespace-nowrap hover:bg-gray-50 active:border-gray-300`,
              ]}
            >
              {lc?.title}
            </button>
          </NextLink>
        );
      })}
    </div>
  );
};
