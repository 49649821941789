/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import {
  formatShopifyPrice,
  renderInternalExternalLinks,
} from "src/shared/helpers";
import FixedContainer from "src/shared/components/FixedContainer";
import {
  ContentSectionTitle,
  ContentSectionSubtitle,
  ContentSectionTitleH3,
} from "src/shared/components/ContentSection";
import { ProductPriceBlock } from "src/shared/components/ProducePriceBlock";
import {
  BlockMainFeatureButtonsFragment,
  BlockMainFeatureFieldsFragment,
  TitleTextFragment,
  TextFragment,
} from "src/generated/datocms-types";
import { SliderDots, galleryImage } from "src/shared/components/ImageSlider";
import { ShopifyProductJson } from "src/shared/types/shopify";
import { IMAGE_QUALITY } from "src/constants";
import { Description } from "./Description";

export type DescriptionType = {
  data: TitleTextFragment[] | TextFragment[];
  hasBulletPoints: boolean;
  hasDescriptionAsTable: boolean;
  textColorHex: string;
};
// todo: rename file
export type BlockMainFeatureProps = {
  data: BlockMainFeatureFieldsFragment;
};
export const BlockMainFeature: FC<BlockMainFeatureProps> = ({ data }) => {
  const ImageCollection: galleryImage[] | undefined = [];
  const {
    fontFamily,
    text,
    title,
    imageWidth,
    image,
    imagePosition,
    buttons,
    subtitle,
    subtitlePosition,
    product,
    hasSlider,
    gallery,
    backgroundColor,
    colorText,
    hasPersonalizedDescription,
    hasDescriptionAsTable,
    rows,
    hasBulletPoints,
    bulletPoints,
    isTitleH1,
  } = data;

  const halfWidthStyle = [
    hasDescriptionAsTable ? tw`justify-center` : tw`justify-between`,
  ];
  const halfWidthImageStyle = [
    hasDescriptionAsTable
      ? tw`object-contain lg:object-cover`
      : tw`object-contain`,
  ];
  const ImageStyle = [
    hasDescriptionAsTable ? tw`h-60 sm:h-96` : { height: "400px" },
  ];
  const addGallery = () => {
    if (image?.url) {
      const defaultImage: galleryImage = {
        id: image?.id,
        alt: image?.alt || "",
        url: image.url,
      };
      ImageCollection.push(defaultImage);
    }

    gallery.forEach((n) => {
      const alt = n.alt || "";
      const imageItem: galleryImage = {
        id: n.id,
        alt,
        url: n.url,
      };
      ImageCollection.push(imageItem);
    });
  };

  const imageFirst = imagePosition === "left";
  const hasProduct = Boolean(product?.shopifyProduct);
  const hasGallery = gallery?.length;
  const hasFontSize = fontFamily === "default" || fontFamily === "";
  const hasHalfWidth = imageWidth !== "default";
  const shopifyProduct: ShopifyProductJson = product?.shopifyProduct;
  const minPrice = formatShopifyPrice(
    shopifyProduct?.priceRange.minVariantPrice
  );
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const imageSize = { w: isDesktop ? 500 : 250 };
  const imageWidthSize = { w: 800 };
  const imageSrc = `${image?.url}?w=${imageWidthSize.w}&h=${imageSize.w}`;
  const buttonStyle = [!isDesktop && tw`text-sm`];
  const bgHex = backgroundColor?.hex?.toString() || "";
  const textColorHex = colorText?.hex?.toString() || "";
  const hasSubtitle = !hasDescriptionAsTable && subtitle;
  if (hasGallery) {
    addGallery();
  }

  const getDescriptionData = () => {
    const descriptionData: DescriptionType = {
      data: hasBulletPoints ? bulletPoints : rows,
      hasBulletPoints,
      hasDescriptionAsTable,
      textColorHex,
    };
    return descriptionData;
  };
  const renderHeading = (isDefaultFontFam: boolean) => {
    if (isDefaultFontFam) {
      if (isTitleH1) {
        return (
          <ContentSectionTitle
            css={[
              tw`mb-2 px-0 font-normal`,
              textColorHex ?? { color: textColorHex },
            ]}
          >
            {title}
          </ContentSectionTitle>
        );
      }
      return (
        <ContentSectionTitleH3
          css={[
            tw`mb-2 px-0 font-normal`,
            textColorHex ?? { color: textColorHex },
          ]}
        >
          {title}
        </ContentSectionTitleH3>
      );
    }
    if (isTitleH1) {
      return (
        <h1
          css={[
            tw`mb-2 px-0 font-playfair text-base leading-tight sm:px-0`,
            textColorHex ?? { color: textColorHex },
            { fontSize: "37px" },
          ]}
        >
          {title}
        </h1>
      );
    }
    <h3
      css={[
        tw`mb-2 px-0 font-playfair text-base leading-tight sm:px-0`,
        textColorHex ?? { color: textColorHex },
        { fontSize: "37px" },
      ]}
    >
      {title}
    </h3>;
  };

  const renderTitlePersonalizedDesc = (hasBulletPoint: boolean) => {
    if (!hasBulletPoint) return null;
    return (
      <h2
        css={[
          tw`ml-6 text-3xl font-bold leading-tight font-playfair`,
          textColorHex && { color: textColorHex },
        ]}
      >
        {title}
      </h2>
    );
  };

  return (
    <FixedContainer css={[!isDesktop && tw`px-4`]}>
      <div
        css={[
          tw`flex flex-wrap items-center px-1`,
          imageFirst ? tw`flex-row` : tw`flex-row-reverse`,
          bgHex ?? { backgroundColor: bgHex },
          hasHalfWidth && halfWidthStyle,
          !hasPersonalizedDescription && tw`mt-10`,
        ]}
      >
        <div
          css={[
            tw`w-full`,
            hasProduct || hasHalfWidth ? tw`lg:w-1/2` : tw`lg:w-8/12`,
          ]}
        >
          <div
            css={[
              tw`relative h-full mb-8 lg:mb-0`,
              imageFirst && !product ? tw`mr-0 lg:mr-10` : tw`mr-0`,
              !imageFirst && !product ? tw`ml-0 lg:ml-10` : tw`ml-0`,
              [hasHalfWidth ? ImageStyle : { height: imageSize.w }],
            ]}
          >
            {hasSlider ? (
              <SliderDots data={ImageCollection} />
            ) : (
              <Image
                css={[hasHalfWidth ? halfWidthImageStyle : tw`object-cover`]}
                src={imageSrc}
                layout="fill"
                quality={IMAGE_QUALITY}
                alt={image?.alt ?? ""}
              />
            )}
          </div>
        </div>
        <div
          css={[
            isDesktop && tw`flex flex-col justify-center`,
            hasProduct ? tw`lg:w-6/12` : tw`lg:w-4/12`,
            hasBulletPoints && [
              { borderColor: textColorHex, borderLeftWidth: "2px" },
              tw`pl-4`,
            ],
            isDesktop && [hasHalfWidth ? ImageStyle : { height: imageSize.w }],
            product && isDesktop && tw`lg:pl-10 bg-bloomsy-lightAnnouncement `,
          ]}
        >
          {hasSubtitle && subtitlePosition === "before title" && (
            <ContentSectionSubtitle
              css={[tw`mb-2 px-0`, textColorHex ?? { color: textColorHex }]}
            >
              {subtitle}
            </ContentSectionSubtitle>
          )}
          {hasPersonalizedDescription
            ? renderTitlePersonalizedDesc(hasBulletPoints)
            : renderHeading(hasFontSize)}

          {hasSubtitle && subtitlePosition === "after title" && (
            <ContentSectionSubtitle
              css={[tw`mb-2 px-0`, textColorHex ?? { color: textColorHex }]}
            >
              {subtitle}
            </ContentSectionSubtitle>
          )}
          {minPrice && (
            <ProductPriceBlock
              productId={product?.id}
              minPrice={minPrice}
              bouquetSize={product?.bouquetSize ?? ""}
              title={product?.title ?? ""}
              tags={product?.tags as []}
            />
          )}
          {hasPersonalizedDescription ? (
            <Description data={getDescriptionData()} />
          ) : (
            <div
              css={[
                { "&>p": tw`mb-2 lg:mb-4` },
                tw`mt-4 font-roboto text-sm lg:text-base text-gray-700`,
                product && isDesktop && tw`w-8/12`,
              ]}
              dangerouslySetInnerHTML={{ __html: text as string }}
            />
          )}

          {buttons && buttons.length > 0 && (
            <div css={tw`flex md:justify-center lg:justify-start `}>
              <div
                css={[
                  { "&>a": buttonStyle },
                  tw`md:mt-4 lg:mt-8 md:w-1/4 lg:w-1/2 `,
                ]}
              >
                {renderInternalExternalLinks(
                  buttons as BlockMainFeatureButtonsFragment[]
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </FixedContainer>
  );
};
