import { FC, useState } from "react";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { useKeenSlider } from "keen-slider/react";

import Image from "next/legacy/image";

export type galleryImage = {
  id: string;
  url: string;
  alt: string;
};

export type BlockFeatureReviewProps = {
  data: galleryImage[];
};

export const SliderDots: FC<BlockFeatureReviewProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const imageSize = { w: isDesktop ? 600 : 200, h: isDesktop ? 550 : 250 };
  const hasCollection = data.length > 1;
  const classNameForSlider = hasCollection ? "keen-slider" : "";
  const classNameForSlide = hasCollection ? "keen-slider__slide" : "";

  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });
  return (
    <div>
      <div ref={slideRef} className={classNameForSlider}>
        {data.map((d) => {
          const imageSrc = `${d?.url}?w=${imageSize.w}`;
          return (
            <div
              className={classNameForSlide}
              key={d.id}
              css={[{ height: imageSize.h }]}
            >
              <Image
                css={tw`object-contain`}
                src={imageSrc}
                layout="fill"
                unoptimized
                alt={d.alt ?? ""}
              />
            </div>
          );
        })}
      </div>
      {slider && hasCollection && (
        <div css={tw`flex justify-center py-0`}>
          {/* @ts-ignore */}
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                css={[
                  tw`w-2.5 h-2.5 mx-1 mt-2 bg-bloomsy-gray50 block rounded-full lg:(w-3 h-3) focus:outline-none`,
                  currentSlide === idx && tw`bg-bloomsy-midgray`,
                ]}
                type="button"
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
