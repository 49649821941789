/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import { FaqsCollectionFieldsFragment } from "src/generated/datocms-types";
import FixedContainer from "src/shared/components/FixedContainer";
import tw from "twin.macro";
import { FaqCollection } from "src/features/FaqPage/FaqCollection";

export type BlockFAQprops = {
  data: FaqsCollectionFieldsFragment;
};

export const BlockFAQ: FC<BlockFAQprops> = ({ data }) => {
  return (
    <FixedContainer css={tw`px-5 sm:px-0`}>
      <FaqCollection key={data.id} collection={data} />
    </FixedContainer>
  );
};
