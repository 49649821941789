import { BlockFeatureLinkCollectionFieldsFragment } from "src/generated/datocms-types";
import { FC, useState } from "react";
import tw, { styled, theme as twTheme } from "twin.macro";
import { getInternalLink, InternalOrExternalLink } from "src/shared/helpers";
import Image from "next/legacy/image";
import FixedContainer from "src/shared/components/FixedContainer";
import { useMediaQuery } from "@mui/material";
import { useKeenSlider } from "keen-slider/react";
import { IMAGE_QUALITY } from "src/constants";
import Link from "next/link";

type CardsGridProps = {
  data: BlockFeatureLinkCollectionFieldsFragment;
};
// eslint-disable-next-line react/function-component-definition
export const CardsGrid: FC<CardsGridProps> = ({ data }) => {
  const { linkCollection, applyFilterGrayscaleToCardImage } = data;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const [currentSlide, setCurrentSlide] = useState(0);
  const styles = [
    isDesktop ? tw` w-20` : tw`w-12`,
    tw`absolute cursor-pointer fill-current text-gray-400 bg-gray-50 bg-opacity-75`,
  ];
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    breakpoints: {
      "(min-width: 400px)": {
        slidesPerView: 1,
      },
      "(min-width: 1000px)": {
        slidesPerView: 4,
        spacing: 15,
      },
    },
    loop: false,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  const productImageSize = {
    w: !isDesktop ? 300 : 300,
    h: !isDesktop ? 170 : 170,
  };
  const EllipsisTitle = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `;
  const EllipsisShorDescription = styled.span`
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `;
  return (
    <FixedContainer
      css={isDesktop && !isLargeDesktop ? tw`w-11/12` : tw`w-full`}
    >
      <div>
        <div ref={sliderRef} className="keen-slider">
          {linkCollection.map((lc) => {
            if (lc.isExternal && (lc.url?.length ?? 0) === 0) return null;
            if (!lc.isExternal && !lc.internalLink) return null;
            if ((lc.title?.length ?? 0) === 0 || !lc.coverImage) return null;
            const href = lc.isExternal
              ? lc.url
              : getInternalLink(lc?.internalLink as InternalOrExternalLink);
            return (
              <article
                className={!isDesktop ? "keen-slider__slide" : ""}
                key={lc.id}
                css={[
                  tw`my-2 px-2 w-full sm:w-1/2 lg:(my-4 w-1/3)`,
                  linkCollection.length < 4 || !isDesktop
                    ? tw`flex justify-center`
                    : ``,
                ]}
              >
                <Link
                  href={href || ""}
                  passHref
                  target="_blank"
                  rel="noreferrer"
                  css={[
                    tw`block min-h-full overflow-hidden rounded shadow-md bg-white border border-solid border-bloomsy-productCardBorder hover:drop-shadow-lg`,
                    linkCollection.length < 4 || !isDesktop
                      ? tw`w-[300px]`
                      : ``,
                  ]}>

                  <div css={tw`w-full text-center`}>
                    <Image
                      src={`${lc.coverImage?.url}?w=${productImageSize.w}&h=${productImageSize.h}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`}
                      css={[
                        tw`block object-contain px-4 my-4`,
                        applyFilterGrayscaleToCardImage
                          ? tw`filter grayscale`
                          : ``,
                      ]}
                      width={productImageSize.w}
                      height={productImageSize.h}
                      quality={IMAGE_QUALITY}
                      alt={lc.coverImage?.alt ?? "Cover Image"}
                    />
                  </div>
                  <div css={tw`border-gray-200 border-t mx-4 py-4 h-48`}>
                    <h2
                      css={tw`block font-roboto uppercase tracking-wider hover:text-bloomsy-red transition-colors ease-in-out duration-300`}
                    >
                      <EllipsisTitle>{lc.title}</EllipsisTitle>
                    </h2>
                    <p css={tw`mt-2 text-gray-700 font-roboto text-sm`}>
                      <EllipsisShorDescription>
                        {lc.shortDescription}
                      </EllipsisShorDescription>
                    </p>
                  </div>

                </Link>
              </article>
            );
          })}
          {isDesktop && (
            <>
              {linkCollection.length > 4 && currentSlide !== 0 && (
                <div
                  css={[styles, tw`sm:h-350 lg:h-350 top-[1rem] left-[0.5rem]`]}
                >
                  <div css={tw`block my-32`}>
                    <svg onClick={() => slider.prev()} viewBox="0 0 100 125">
                      <path d="M66.006,82.742L40.377,52l25.629-30.742c1.133-1.361,0.947-3.379-0.41-4.514s-3.381-0.951-4.514,0.408L33.744,49.947   C33.246,50.541,33,51.271,33,52c0,0.73,0.246,1.459,0.744,2.053l27.338,32.795c1.133,1.359,3.156,1.543,4.514,0.408   C66.957,86.119,67.141,84.105,66.006,82.742z" />
                    </svg>
                  </div>
                </div>
              )}
              {linkCollection.length > 4 &&
                slider.details().size - 4 !== currentSlide && (
                  <div
                    css={[
                      styles,
                      tw`-right-0 sm:h-350 lg:h-350 top-[1rem] right-[0.5rem]`,
                    ]}
                  >
                    <div css={tw`block my-32`}>
                      <svg onClick={() => slider.next()} viewBox="0 0 100 125">
                        <path d="M66.254,46.947L38.916,14.152c-1.131-1.359-3.154-1.543-4.512-0.408c-1.361,1.137-1.545,3.152-0.412,4.512L59.621,49   L33.992,79.742c-1.131,1.363-0.947,3.379,0.412,4.514c1.357,1.135,3.381,0.951,4.512-0.408l27.338-32.793   C66.752,50.459,67,49.729,67,49C67,48.27,66.752,47.541,66.254,46.947z" />
                      </svg>
                    </div>
                  </div>
                )}
            </>
          )}
        </div>
      </div>

      <div
        css={[
          isDesktop && tw`h-0 overflow-hidden`,
          !isDesktop && tw`flex justify-center py-12`,
        ]}
      >
        {/* @ts-ignore */}
        {[...Array(slider && slider.details().size).keys()].map((idx) => {
          return (
            <button
              css={[
                tw`w-2.5 h-2.5 mx-1 mt-2 bg-bloomsy-gray50 block rounded-full lg:(w-3 h-3) focus:outline-none`,
                currentSlide === idx && tw`bg-bloomsy-midgray`,
              ]}
              type="button"
              key={idx}
              onClick={() => {
                slider.moveToSlideRelative(idx);
              }}
            />
          );
        })}
      </div>
    </FixedContainer>
  );
};
