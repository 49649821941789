/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImagSection } from "src/ui/components/BlockFeatureTextImagesButton";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { FC,useRef,useEffect, useState } from "react";
import { getBasicFourSixtyscript } from "src/shared/utils";
import { instagramSliderScript, instagramSliderStyle} from "./embed";

export const DesktopSlider: FC = () => {
    const [renderInstragramEl, setRenderInstagramEl] = useState(false);
    const fourSixtyWidget = useRef<HTMLDivElement>(null);
    const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
    useEffect(() =>
    {
            const sliderEl = document.getElementsByClassName("fs-slider_v2_5");
            if (sliderEl.length > 0) {
              setRenderInstagramEl(true);
            }
    }, [renderInstragramEl, setRenderInstagramEl]);
   useEffect(() => {
       if (!renderInstragramEl) {
        const scripte = document.getElementById("fs.slider.v2.5.js");
        const scriptx = document.getElementById("fs.embed.v2.5.js");
            const script = getBasicFourSixtyscript();
            const currentFourSixtyWidget = fourSixtyWidget.current;
            script.src = instagramSliderScript.src;
            script.setAttribute("data-theme", instagramSliderScript.dataTheme);
            script.setAttribute(
              "data-cell-size",
              instagramSliderScript.dataCellSize || ""
            );
           script.id="testSliderInstagram"
            fourSixtyWidget.current?.appendChild(script);
            return () => {
              currentFourSixtyWidget?.removeChild(script);
            };
          }
           
          
      },[isDesktop, renderInstragramEl, setRenderInstagramEl]);
    const instagramStyle = `${instagramSliderStyle}`;
  return (
      <div
      id="DesktopSlider"
          ref={fourSixtyWidget}
          dangerouslySetInnerHTML={{ __html: instagramStyle}}
          css={tw`mt-2`} />
  );
};
