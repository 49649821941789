import tw, { styled } from "twin.macro";

export type BlockSpacingContainerProps = {
  spacing: "top" | "bottom" | "both" | "none";
};
export const BlockSpacingContainer = styled.div(
  ({ spacing }: BlockSpacingContainerProps) => [
    spacing === "both" && tw`py-12`,
    spacing === "top" && tw`pt-12`,
    spacing === "bottom" && tw`pb-12`,
  ]
);
