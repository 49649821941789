/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import { FeatureImageCardContentFragment } from "src/generated/datocms-types";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import { getInternalLink } from "src/shared/helpers";

type Props = {
  data: FeatureImageCardContentFragment;
};
export const ProductCardWithTitle: FC<Props> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);

  const productImageSize = {
    w: !isDesktop ? 350 : 400,
    h: !isDesktop ? 350 : 400,
  };
  const productImage = [
    {
      src: `${data.image?.url}?w=${productImageSize.w}&h=${productImageSize.h}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`,
      width: productImageSize.w,
      height: productImageSize.h,
      alt: `${data.image?.alt}`,
    },
  ];

  const href = getInternalLink(data.link);

  return (
    <div css={tw`w-full bg-white flex justify-center`}>
      <div css={tw`my-4 md:my-2 mx-0 md:mx-2`}>
        <a href={href} css={tw`block relative`}>
          <Image
            src={productImage[0].src}
            width={productImage[0].width}
            height={productImage[0].height}
            unoptimized
            layout="intrinsic"
            alt={productImage[0].alt}
          />
        </a>
        <div css={[tw`flex  w-full justify-start`]}>
          <p css={tw`text-sm lg:text-base font-roboto  text-left`}>
            <a href={href}>{data.title}</a>
          </p>
        </div>
      </div>
    </div>
  );
};
