/* eslint-disable react/function-component-definition */
import { FeatureTestimonialContentFragment } from "src/generated/datocms-types";
import { FC } from "react";
import tw from "twin.macro";
import { FaStar } from "react-icons/fa";

type FeatureTestimonialProps = {
  data: FeatureTestimonialContentFragment;
  cardsColor?: string;
  cardType?: string;
};
const CardColorMap = {
  white: tw`bg-white`,
  gray: { backgroundColor: "#faf8f6" },
};
const CardTypeMap = {
  default: tw`lg:w-1/3 xl:w-1/4`,
  small: tw`lg:w-1/3 xl:w-1/4`,
};

export const FeatureTestimonial: FC<FeatureTestimonialProps> = ({
  data,
  cardsColor = "white",
  cardType = "default",
}) => {
  const hasDefaultHeight = cardType === "default";
  const cardColorStyle =
    CardColorMap[cardsColor as keyof typeof CardColorMap] ?? tw`bg-white`;
  const cardTypeStyle =
    CardTypeMap[cardType as keyof typeof CardTypeMap] ?? tw`lg:w-1/4`;

  const startCount = data.numberStars ?? 5;
  const listStart = new Array(startCount).fill(0);

  return (
    <div
      css={[
        tw`relative px-0 my-4 h-[302px] rounded-md rounded-b-none text-start lg:(px-0.5) xl:px-1`,
        cardTypeStyle,
      ]}
    >
      <div css={[tw`flex flex-col p-7 rounded h-full`, cardColorStyle]}>
        <div css={tw`pt-9 pb-6 -mt-7 flex`}>
          {listStart.map((_, index) => {
            // eslint-disable-next-line react/no-array-index-key
            return <FaStar key={index} css={tw`mx-1 fill-[#FBBD08]`} />;
          })}
        </div>
        <blockquote
          css={[
            tw`font-roboto text-base h-[144px] leading-7 overflow-auto mb-2`,
            hasDefaultHeight ? tw`lg:h-56 xl:h-44` : tw`lg:h-48`,
          ]}
        >
          <p>{data.quote}</p>
        </blockquote>
        <p
          css={tw`mt-6 font-roboto text-sm font-bold uppercase tracking-wider leading-5`}
        >
          &mdash;&nbsp;{data.author}
        </p>
      </div>
    </div>
  );
};
