/* eslint-disable react/function-component-definition */
/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import { EmbeddedFormFieldsFragment } from "src/generated/datocms-types";
import FixedContainer from "src/shared/components/FixedContainer";
import tw from "twin.macro";
import CorporateSalesForm from "src/features/CorporateSale";

export type BlockEmbeddedFormprops = {
  data: EmbeddedFormFieldsFragment;
};

export const BlockEmbeddedForm: FC<BlockEmbeddedFormprops> = ({ data }) => {
  const { formType } = data;
  const isEmbededForm = formType !== "corporate sales";
  return (
    <FixedContainer css={tw`text-center`}>
      {isEmbededForm ? (
        <div
          key={data?.id}
          css={[tw`pt-10`, { "&>iframe": tw`w-full overflow-hidden` }]}
          dangerouslySetInnerHTML={{ __html: data.embedHtml || "" }}
        />
      ) : (
        <CorporateSalesForm />
      )}
    </FixedContainer>
  );
};
