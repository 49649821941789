import { FeatureImageTitleContentFragment } from "src/generated/datocms-types";
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";

type FeatureImageTitleProps = {
  data: FeatureImageTitleContentFragment;
};
export const FeatureImageTitle: FC<FeatureImageTitleProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
 
  const productImageSize = {
    w: !isDesktop ? 250 : 450,
    h: !isDesktop ? 300 : 300,
  };
  const imageSrc = `${data.image?.url}?w=${productImageSize.w}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`;
  return (
    <div css={tw`w-[49%] lg:w-1/4 my-4 px-4`}>
    <div
      css={[
        tw`relative w-full h-full rounded bg-transparent h-auto`,
      ]}
    >
          <Image
          css={tw`object-cover`}
           src={imageSrc}
           width={productImageSize.w}
           height={productImageSize.h}
           unoptimized
           layout="intrinsic"
          alt={data.image?.alt ?? ""}
        />
      </div>
      <p
        css={tw`w-full block font-normal uppercase text-sm tracking-wider text-bloomsy-darkgray text-center mt-4`}
      >
        {data.title}
      </p>
    </div>
  );
};

