/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImagSection } from "src/ui/components/BlockFeatureTextImagesButton";
/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";

type ImagSectionProps = {
  data: ImagSection;
};
export const ImageSection: FC<ImagSectionProps> = ({
  data: { title, description, url, alt },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const imageSize = [{ height: isDesktop ? 450 : 250 }];
  const imageSrc = `${url}?w=600&h=600dpr=2`;
  return (
    <div css={[isDesktop ? [{ width: "47%" }] : tw`w-full p-1`]}>
      <div css={[tw`relative w-full `, imageSize]}>
        <Image
          src={imageSrc}
          layout="fill"
          css={tw`object-cover`}
          quality={IMAGE_QUALITY}
          alt={alt || ""}
        />
      </div>
      <div css={[tw`mt-8 font-roboto`]}>
        <h2 css={[tw`text-lg sm:text-xl font-bold`]}>{title}</h2>
        <div
          css={[
            { "&>p": tw`mb-4` },
            tw`mx-0`,
            tw`text-sm sm:text-base mt-4 prose text-black`,
          ]}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};
