/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw from "twin.macro";
import {
  BlockProductBestSellerFieldsFragment,
  ProductFieldsFragment,
  ProductFieldsShortFragment,
} from "src/generated/datocms-types";
import { IMAGE_QUALITY } from "src/constants";
import Image from "next/legacy/image";
import {
  slugsPrefixMap,
  freeShippingIcon,
  deluxePlan,
} from "src/services/datocms/constants";
import { formatShopifyPrice } from "src/shared/helpers";
import { ProductTag } from "src/shared/types";
import { getProductRecurringLabel } from "src/shared/utils/products";
import { copy } from "src/features/ProductForm/constants";

export type Props = {
  product: ProductFieldsFragment[] | ProductFieldsShortFragment[];
  isSubscribePage?: boolean;
};
export const ScrollSnapComponent: FC<Props> = ({ product, isSubscribePage=false }) => {
  return (
    <div css={tw`py-6 lg:(pt-6 pb-0)`}>
      <div
        css={[
          tw`relative w-full flex gap-1.5 justify-start snap-x snap-mandatory overflow-x-auto`,
          {
            "&::-webkit-scrollbar": "display: none;}",
          },
        ]}
      >
        {product.map((p) => {
          const hasStemsValue = p.bouquetSize?.trim() !== "-";
          const recurringFrequency = getProductRecurringLabel(
            p.title ?? "",
            p.tags ?? ""
          );
          const hasFreeShipping = p.tags?.find((tag: ProductTag) => {
            return tag?.slug?.includes(freeShippingIcon);
          });
          const productUrl = `${slugsPrefixMap.product}${p.slug}`;
          const isSub = p.isSubscription;
          const salesPrice =
            formatShopifyPrice(
              p?.shopifyProduct.compareAtPriceRange?.minVariantPrice ?? 0
            ) ?? "";
          const hasSalesPrice =
            Number(
              p?.shopifyProduct?.compareAtPriceRange?.minVariantPrice?.amount ??
                0
            ) > 0;
          const minPrice =
            formatShopifyPrice(
              p?.shopifyProduct?.priceRange?.minVariantPrice ?? 0
            ) ?? "";
          const isDeluxePlan = p.sku === deluxePlan;
          const subscriptionImage = !isSubscribePage ? p.gallery[1] : p.gallery[0];
          const firstImage = p.isSubscription ? subscriptionImage : p.gallery[0];
          return (
            <div
              css={tw`flex flex-row relative snap-start shrink-0 pb-4`}
              key={p.id}
            >
              <a href={productUrl} css={tw`max-w-[270px]`}>
                <Image
                  src={`${
                    firstImage.url
                  }?w=${550}&h=${500}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`}
                  width={270}
                  height={280}
                  quality={IMAGE_QUALITY}
                  alt="product"
                  css={tw`rounded-t-lg object-cover`}
                />

                {hasFreeShipping && (
                  <div css={tw`w-5/6 capitalize leading-7 pt-2`}>
                    <span css={[tw`font-roboto block`, { color: "#41A317" }]}>
                      {copy.freeShipping.toLowerCase()}
                    </span>
                  </div>
                )}
                 {isDeluxePlan && isSubscribePage && (
                  <div css={tw` w-full h-[25%]  absolute top-[42%] flex items-center justify-end `}>
                    <div css={tw`w-[30%]`}>
                      <div
                          css={[
                          tw`rounded-full  h-16 w-16`,
                            {background: "#333333"},
                          ]}
                      >
                        <span css={[tw`text-white text-center block p-4`,{fontSize: "11px"}]}> BEST DEAL</span>
                      </div>
                    </div>
                    </div>
                  )}
                <div css={[!hasFreeShipping && tw`pt-2`, tw`w-full truncate`]}>
                  <span css={tw`capitalize font-roboto`}>{p.title}</span>
                </div>
                <div css={tw`flex flex-wrap w-full text-base leading-7`}>
                  <div css={tw`flex capitalize font-roboto w-5/6 leading-7`}>
                    <p>{minPrice}</p>
                    {hasSalesPrice && (
                      <p css={tw`ml-1 text-base text-gray-500 line-through`}>
                        {salesPrice}
                      </p>
                    )}
                    {p.isSubscription && (
                      <span css={tw`lowercase`}>/{recurringFrequency}</span>
                    )}
                  </div>
                  <div>
                    {p.isSubscription && hasStemsValue && (
                      <span
                        css={tw`capitalize font-roboto text-sm inline text-bloomsy-midgray leading-6`}
                      >
                        {`Includes ${isSub ? "approx." : ""} ${
                          p.bouquetSize
                        } stems`}
                      </span>
                    )}
                  </div>
                </div>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
};
