/* eslint-disable react/function-component-definition */

import React, { FC, useState } from "react";
import tw, { theme as twTheme } from "twin.macro";
import { styled } from "@mui/material/styles";
import {
  FlowerShopSectionTitle,
  FlowerShopSectionTitleH1,
} from "src/features/FlowerShopPage/styled";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import { MinusIcon, PlusIcon } from "./Icons";
const PREFIX = "MuiAccordionDetails";

const classes = {
  root: `${PREFIX}-root`,
  expanded: `${PREFIX}-expanded`,
};
// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled("div")({
  [`& .${classes.root}`]: {
    padding: "0px 0px",
    letterSpacing: "0px",
  },
  [`& .${classes.expanded}`]: {},
});

const AccordionSummary = MuiAccordionSummary;
type Props = {
  title: string;
  description: string;
  isTitleH1?: boolean;
};
const MobileCollectionDescription: FC<Props> = ({
  title,
  description,
  isTitleH1 = false,
}) => {
  const [isAccordeonExpanded, setIsAccordeonExpanded] = useState(false);
  const accordionStyle = tw`px-0 font-roboto normal-case cursor-pointer shadow-none w-full tracking-wider`;
  const accordionSummaryStyle = tw`uppercase font-roboto font-bold text-sm`;

  const handleChangeAccordion = (
    e: React.SyntheticEvent<EventTarget>,
    isExpanded: boolean
  ) => {
    setIsAccordeonExpanded(isExpanded);
  };

  return (
    <Root>
      <div>
        {title && (
          <div css={tw`flex flex-wrap justify-start`}>
            <Accordion
              css={[accordionStyle]}
              key="AccordionCollectionDescription"
              id="AccordionCollectionDescription"
              onChange={(e, isExpanded) => {
                handleChangeAccordion(e, isExpanded);
              }}
            >
              <AccordionSummary
                expandIcon={
                  description &&
                  (!isAccordeonExpanded ? (
                    <PlusIcon css={tw`w-8 h-8 p-0 text-[#4F5561]`} />
                  ) : (
                    <MinusIcon css={tw`w-8 h-8 p-0 text-[#4F5561]`} />
                  ))
                }
                css={[accordionSummaryStyle]}
                classes={{
                  root: classes.root,
                }}
              >
                {isTitleH1 ? (
                  <FlowerShopSectionTitleH1
                    css={tw`text-left border-0 font-normal text-3xl lg:text-4xl capitalize`}
                  >
                    {title}
                  </FlowerShopSectionTitleH1>
                ) : (
                  <FlowerShopSectionTitle
                    css={tw`text-left border-0 font-normal text-3xl lg:text-4xl capitalize`}
                  >
                    {title}
                  </FlowerShopSectionTitle>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {description && (
                  <>
                    <div
                      css={[
                        tw`lg:pr-16 text-left text-base leading-7 lg:text-base lg:text-left font-roboto -mt-1`,

                        { color: "#515151" },
                      ]}
                      dangerouslySetInnerHTML={{
                        __html: description ?? "",
                      }}
                    />
                    <div css={[tw`border-solid pl-1`, { width: "97%" }]}>
                      <hr css={[tw`mt-8`, { borderColor: "#515151" }]} />
                    </div>
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          </div>
        )}
      </div>
    </Root>
  );
};

export default MobileCollectionDescription;
