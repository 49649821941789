/* eslint-disable react/function-component-definition */
import { FC, useEffect, useState, useCallback } from "react";
import {
  FlowerShopSection,
  FlowerShopSectionItems,
  FlowerShopSectionTitle,
  FlowerShopSectionTitleH1,
} from "src/features/FlowerShopPage/styled";
import { FlowerShopCard } from "src/features/FlowerShopPage/FlowerShopCard";
import { CollectionProductsMap, FilterProduct } from "src/shared/types";
import { ProductBreadcrumb } from "src/features/ProductBreadcrumb";
import { setFilterList } from "src/shared/utils/products";
import {
  ProductCollectionFieldsFragment,
  ProductFieldsShortFragment,
  PromotionalCardFieldsFragment,
} from "src/generated/datocms-types";
import { useSession } from "next-auth/react";
import { useKeenSlider } from "keen-slider/react";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { ShopifyProductJson } from "src/shared/types/shopify";
import { dataLayer } from "src/features/Analytics";
import { useBreadcrumbPath } from "src/features/ProductBreadcrumb/hooks";
import { productSortFnMap } from "src/shared/utils";
import MobileCollectionDescription from "src/shared/components/MobileCollectionDescription";
import { PromotionalCard } from "src/shared/components/PromotionalCard";
import { FilterAndSortMobile } from "src/shared/components/FilterAndSortMobile";
import { impactDataLayerEvent } from "../Analytics/utils";
import { ItemsData } from "../../features/Cart/types/index";
import { useCurrentFilterSelection } from "../Cart/state";
import { ProductCollectionComponent } from "./ProductCollectionComponent";

type Props = {
  collections: ProductCollectionFieldsFragment[];
  collectionProducts: CollectionProductsMap;
  sectionOnChange?: (inView: boolean, entry: IntersectionObserverEntry) => void;
  sortByFn?: (
    a: ProductFieldsShortFragment | PromotionalCardFieldsFragment,
    b: ProductFieldsShortFragment | PromotionalCardFieldsFragment
  ) => number;
  isInFlowerPage?: boolean;
  filterOptions?: FilterProduct[];
};

export const ProductCollectionSection: FC<Props> = ({
  collections,
  collectionProducts,
  sortByFn,
  sectionOnChange,
  isInFlowerPage = false,
  filterOptions,
}) => {
  const { currentFilterSelection, addCurrentFilterSelection } =
    useCurrentFilterSelection();
  const { addCollectionName } = useBreadcrumbPath();
  type SortDirection = "asc" | "desc";

  const productSortDefault = "unsorted-default";
  const [sortByKey, setSortByKey] = useState(productSortDefault);
  const sortByFnPage = useCallback(
    (a, b) => {
      const [sortByType, sortByDirection] = sortByKey.split("-");
      const sortFn =
        productSortFnMap[sortByType as keyof typeof productSortFnMap];
      return sortFn(a, b, sortByDirection as SortDirection);
    },
    [sortByKey]
  );
  const { data: session } = useSession();

  useEffect(() => {
    // Products list impressions
    const itemsObj: ItemsData[] = [];
    if (collections.length) {
      collections.forEach((collection) => {
        const product = collection.products;
        product.forEach((p) => {
          let item: ItemsData = {};
          if (p._modelApiKey === "product") {
            const { shopifyProduct }: { shopifyProduct: ShopifyProductJson } =
              p;
            item = {
              item_name: shopifyProduct.title,
              item_id: shopifyProduct?.variants.edges[0].node.sku,
              price: shopifyProduct?.variants.edges[0].node.priceV2.amount,
              item_brand: "BloomsyBox",
              item_category: "",
              item_category2: "",
              item_variant: "",
              item_list_name: collection.title || "",
              item_list_id: collection.id,
              quantity: 1,
            };
            itemsObj.push(item);
          }
        });
      });
      dataLayer({
        event: "view_item_list",
        ecommerce: {
          items: itemsObj,
        },
      });
      impactDataLayerEvent(
        session?.shopifyUser.id || "",
        session?.shopifyUser.email || ""
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // cleaning filters
    addCurrentFilterSelection(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  let productsCount = 0;
  const slidesPerView = 3;
  let hasEnoughSlides = productsCount > slidesPerView;
  let dotsCount = 0;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    loop: true,
    mode: "snap",
    slidesPerView: hasEnoughSlides ? slidesPerView : 1,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    spacing: 3,
    breakpoints: {
      [`(min-width: ${twTheme`screens.lg`})`]: {
        slidesPerView,
        spacing: 5,
      },
    },
  });
  const sectionItemsProps: { onChange?: Props["sectionOnChange"] } = {};
  const styles = tw`mb-6`;
  if (sectionOnChange) {
    sectionItemsProps.onChange = sectionOnChange;
  }
  const flowerShopSectionTitle = (
    centered: boolean,
    collectionTitle: string,
    isTitleH1: boolean
  ) => {
    return (
      <div>
        {isTitleH1 ? (
          <FlowerShopSectionTitleH1
            css={[
              !isInFlowerPage &&
                !centered && [
                  tw`text-left border-0 font-normal text-3xl lg:text-4xl`,
                ],
            ]}
          >
            {collectionTitle}
          </FlowerShopSectionTitleH1>
        ) : (
          <FlowerShopSectionTitle
            css={[
              !isInFlowerPage &&
                !centered && [
                  tw`text-left border-0 font-normal text-3xl lg:text-4xl`,
                ],
            ]}
          >
            {collectionTitle}
          </FlowerShopSectionTitle>
        )}
      </div>
    );
  };

  const [city, setCity] = useState<string>("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cityParam = (urlParams.get("city") as string)
      ?.split(" ")
      ?.map((c) => {
        return c.charAt(0).toUpperCase() + c.slice(1);
      })
      ?.join(" ");
    setCity(cityParam);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isInFlowerPage &&
        isDesktop &&
        filterOptions &&
        filterOptions?.length > 0 && (
          <ProductCollectionComponent
            filterOptions={filterOptions ?? []}
            productCollection={collectionProducts}
            collections={collections}
            setSortByKey={setSortByKey}
          />
        )}
      {isInFlowerPage &&
        filterOptions &&
        filterOptions?.length > 0 &&
        !isDesktop && (
          <div css={tw`w-full`}>
            <FilterAndSortMobile
              filterOptions={filterOptions ?? []}
              productCollection={collectionProducts}
              collections={collections}
              setSortByKey={setSortByKey}
            />
          </div>
        )}

      {collections.map((collection) => {
        const tags = collection.productTags;
        const { showProductAs, hideBreadcrumb } = collection;
        const hasSlider = showProductAs === "Slider";
        let gridType = "default";
        if (!hasSlider && showProductAs !== "Grid 3 products") {
          gridType = "secondGrid";
        }
        const classNameForSlider = hasSlider ? "keen-slider" : "";
        const productsByTag = collectionProducts[collection.id] ?? [];
        const productByTagSorted: ProductFieldsShortFragment[] = [];
        tags.forEach((t) => {
          const products = productsByTag.filter((c) =>
            c.tags.find((i) => i.id === t.id)
          );
          products.map((m) => productByTagSorted.push(m));
        });
        const manualProducts = collection.products ?? [];
        let allProducts = manualProducts.concat(productByTagSorted).slice();
        if (currentFilterSelection) {
          allProducts = setFilterList(
            manualProducts
              .concat(productByTagSorted)
              .slice() as ProductFieldsShortFragment[],
            currentFilterSelection
          );
        }

        productsCount = allProducts.length;
        hasEnoughSlides = productsCount > slidesPerView;
        const collectionTitle = city
          ? `${city} ${collection.title?.replace(/-/g, " ")}`
          : collection.title?.replace(/-/g, " ");
        if (!isInFlowerPage) {
          addCollectionName(collectionTitle || "");
        }
        const { centered, hideTitle, description, hasFilterComponent } =
          collection;
        const filterComponentStyle = [
          tw`flex flex-wrap items-center justify-start px-2`,
        ];

        const titleCenteredStyle = [
          centered ? tw`w-full` : tw`sm:w-2/3  lg:w-3/4 xl:w-4/5`,
        ];

        if (!sortByFnPage && sortByFn) {
          allProducts.sort((a, b) => sortByFn(a, b));
        }
        if (!sortByFn && sortByFnPage && hasFilterComponent) {
          allProducts.sort((a, b) => sortByFnPage(a, b));
        } else if (sortByFnPage && hasFilterComponent) {
          allProducts.sort((a, b) => sortByFnPage(a, b));
        }

        if (!allProducts.length) {
          return null;
        }

        return (
          <FlowerShopSection key={collection.id}>
            <div
              css={[
                !isInFlowerPage && filterComponentStyle,
                !isDesktop && tw`mt-8`,
                !isDesktop && !description && tw`flex flex-col items-start`,
                !isDesktop && description && tw`flex justify-start`,
              ]}
            >
              {isDesktop && !isInFlowerPage && (
                <div
                  css={[
                    tw`mb-4 xl:mb-10`,
                    isInFlowerPage ? tw`w-full` : titleCenteredStyle,
                    hideBreadcrumb && tw`pt-6`,
                  ]}
                >
                  {!hideBreadcrumb && !isInFlowerPage && !centered && (
                    <ProductBreadcrumb
                      containerStyles={styles}
                      crumbs={[]}
                      title={collectionTitle || ""}
                    />
                  )}

                  {!hideTitle &&
                    !isInFlowerPage &&
                    flowerShopSectionTitle(
                      centered,
                      collectionTitle ?? "",
                      collection.isTitleH1
                    )}
                  {description && !isInFlowerPage && (
                    <p
                      css={[
                        !isDesktop && tw`pt-4`,
                        !isInFlowerPage &&
                          !centered && [
                            tw`lg:pr-16 text-left text-base leading-7 lg:text-base lg:text-left font-roboto -mt-1`,
                          ],
                        { color: "#374151" },
                      ]}
                      dangerouslySetInnerHTML={{
                        __html: description || "",
                      }}
                    />
                  )}
                </div>
              )}

              {!isDesktop && !isInFlowerPage && (
                <div>
                  <MobileCollectionDescription
                    title={collectionTitle ?? ""}
                    description={description ?? ""}
                    isTitleH1={collection.isTitleH1}
                  />
                  {filterOptions &&
                    filterOptions?.length > 0 &&
                    hasFilterComponent && (
                      <FilterAndSortMobile
                        filterOptions={filterOptions ?? []}
                        productCollection={collectionProducts}
                        collections={collections}
                        setSortByKey={setSortByKey}
                      />
                    )}
                </div>
              )}
            </div>
            {hasFilterComponent &&
              !isInFlowerPage &&
              isDesktop &&
              filterOptions &&
              filterOptions?.length > 0 && (
                <ProductCollectionComponent
                  filterOptions={filterOptions}
                  productCollection={collectionProducts}
                  collections={collections}
                  setSortByKey={setSortByKey}
                />
              )}
            <FlowerShopSectionItems
              id={collection.id}
              rootMargin="-100px 0px"
              {...sectionItemsProps}
            >
              {hasSlider ? (
                <>
                  <div ref={sliderRef} className={classNameForSlider}>
                    {allProducts.map((product) => {
                      const isProductCard = product._modelApiKey === "product";
                      const productCard = product as ProductFieldsShortFragment;
                      const promotionalCard =
                        product as PromotionalCardFieldsFragment;
                      return (
                        <>
                          {isProductCard && (
                            <FlowerShopCard
                              key={product.id}
                              product={productCard}
                              hasSlider={hasSlider}
                              gridType={gridType}
                              totalProducts={productsCount}
                              isFlowerShopPage={isInFlowerPage}
                            />
                          )}
                          {!isProductCard && (
                            <PromotionalCard
                              data={promotionalCard}
                              hasSlider={hasSlider}
                              gridType={gridType}
                              totalProducts={productsCount}
                              isFlowerShopPage={isInFlowerPage}
                              cardType="large"
                            />
                          )}
                        </>
                      );
                    })}
                  </div>

                  {slider && hasSlider && (
                    <div css={tw`flex justify-center py-0 w-full`}>
                      {/* @ts-ignore */}
                      {[...Array(slider.details().size).keys()].map((idx) => {
                        if (
                          dotsCount !== Math.floor(idx / slidesPerView) ||
                          idx === 0 ||
                          !isDesktop
                        ) {
                          if (idx > 0) dotsCount += 1;
                          return (
                            <button
                              css={[
                                tw`w-2.5 h-2.5 mx-1 mt-2 bg-bloomsy-gray50 block rounded-full lg:(w-3 h-3) focus:outline-none`,
                                currentSlide === idx && tw`bg-bloomsy-midgray`,
                              ]}
                              type="button"
                              key={idx}
                              onClick={() => {
                                slider.moveToSlideRelative(idx);
                              }}
                            />
                          );
                        }
                        return null;
                      })}
                    </div>
                  )}
                </>
              ) : (
                <div
                  css={tw`flex flex-wrap justify-between lg:(justify-start) w-full`}
                >
                  {allProducts.map((product) => {
                    const isProductCard = product._modelApiKey === "product";
                    const productCard = product as ProductFieldsShortFragment;
                    const promotionalCard =
                      product as PromotionalCardFieldsFragment;
                    const productKey = isProductCard
                      ? `FlowerShopCard/${collection.id}/${product.id}`
                      : `PromotionalCard/${collection.id}/${product.id}`;

                    return (
                      <>
                        {isProductCard && (
                          <FlowerShopCard
                            key={productKey}
                            product={productCard}
                            hasSlider={hasSlider}
                            gridType={gridType}
                            totalProducts={productsCount}
                            isFlowerShopPage={isInFlowerPage}
                          />
                        )}
                        {!isProductCard && (
                          <PromotionalCard
                            key={productKey}
                            data={promotionalCard}
                            hasSlider={hasSlider}
                            gridType={gridType}
                            totalProducts={productsCount}
                            isFlowerShopPage={isInFlowerPage}
                            cardType="large"
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            </FlowerShopSectionItems>
          </FlowerShopSection>
        );
      })}
    </div>
  );
};
