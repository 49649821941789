import { FC, Fragment } from "react";
import Head from "next/head";
import Error from "next/error";

const NotFoundPage: FC = () => {
  return (
    <Fragment>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <Error statusCode={404} />
    </Fragment>
  );
};

export default NotFoundPage;
