/* eslint-disable react/button-has-type */
/* eslint-disable no-console */
/* eslint-disable react/function-component-definition */

import { FC, useState } from "react";
import tw from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureImageFullFieldsFragment } from "src/generated/datocms-types";
import { FeatureImageFull } from "src/ui/components/BlockFeatureImageFull/FeatureImageFull";
import { useKeenSlider } from "keen-slider/react";
import { getInternalLink } from "src/shared/helpers";

const Arrow = (props: {
  disabled: boolean;
  left?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClickArrow: (e: any) => void;
}) => {
  const { disabled, left, handleClickArrow } = props;
  return (
    <svg
      onClick={handleClickArrow}
      css={[
        tw`w-[20px] h-[20px] absolute top-[60%] fill-[#4D5562]`,
        left ? tw`left-[10px]` : tw`left-[auto]`,
        left ? tw`right-[auto]` : tw`right-[10px]`,
      ]}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {left && !disabled && (
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      )}
      {!left && !disabled && (
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />
      )}
    </svg>
  );
};

export type fontType = {
  title: string;
  subtitle: string;
};

export type BlockFeatureImageFullMobileProps = {
  data: BlockFeatureImageFullFieldsFragment;
};

export const BlockFeatureImageFullMobile: FC<
  BlockFeatureImageFullMobileProps
> = ({
  data: { subtitleFont, titleFont, content, hasDividers, pageLinkUrl },
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoadCarousel, setLoadCarousel] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: 1,
    spacing: 1,
    duration: 2000,
    loop: true,
    slideChanged(slider) {
      setCurrentSlide(slider.details().relativeSlide);
    },
    created() {
      setLoadCarousel(true);
    },
  });

  const fontStyle: fontType = {
    title: titleFont || "roboto",
    subtitle: subtitleFont || "roboto",
  };

  const fontTitleFamilyMap = {
    roboto: tw`font-roboto text-sm`,
    condensed: tw`font-roboto text-sm`,
    playfair: tw`font-playfair`,
  };

  const titlefontFamilyStyle =
    fontTitleFamilyMap[fontStyle.title as keyof typeof fontTitleFamilyMap];

  return (
    <FixedContainer>
      <div>
        {!isLoadCarousel && (
          <p
            css={[
              hasDividers && tw`px-0 sm:px-2`,
              tw`font-roboto text-sm mt-2 text-gray-700 text-center`,
            ]}
          >
            Loading...
          </p>
        )}
        <div ref={sliderRef} className="keen-slider">
          {content &&
            content.map((record) => {
              if (!record) return null;
              return (
                <div className="keen-slider__slide" key={record.id}>
                  <FeatureImageFull
                    data={record}
                    fontStyle={fontStyle}
                    hasDividers={hasDividers}
                  />
                </div>
              );
            })}
        </div>
        {instanceRef && (
          <>
            <Arrow
              left
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              handleClickArrow={(e: any) =>
                e.stopPropagation() || instanceRef.prev()
              }
              disabled={false}
            />
            <Arrow
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              handleClickArrow={(e: any) =>
                e.stopPropagation() || instanceRef.next()
              }
              disabled={false}
            />
          </>
        )}
      </div>
      {pageLinkUrl.length > 0 && (
        <div css={tw`text-center mt-1`}>
          <a
            css={[
              tw`underline underline-offset-4 font-bold tracking-wider h-12 flex justify-center items-center`,
              titlefontFamilyStyle,
            ]}
            href={getInternalLink(pageLinkUrl[0].link)}
          >
            {pageLinkUrl[0].title}
          </a>
        </div>
      )}
    </FixedContainer>
  );
};
