/* eslint-disable react/function-component-definition */
import React, { FC } from "react";
import { styled } from "@mui/material/styles";
import tw from "twin.macro";
import { FaqsCollectionFieldsFragment } from "src/generated/datocms-types";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";
import { PlusIcon, MinusIcon } from "src/shared/components/Icons";
const PREFIX = "FaqCollection";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  expanded: `${PREFIX}-expanded`,
};

const Root = styled("div")({
  [`& .${classes.root}`]: {},
  [`& .${classes.content}`]: { "&$expanded": { fontWeight: 800 } },
  [`& .${classes.expanded}`]: {},
});

type Props = {
  collection: FaqsCollectionFieldsFragment;
  searchKeywords?: string;
};
const AccordionSummary = MuiAccordionSummary;

export const FaqCollection: FC<Props> = ({ collection, searchKeywords }) => {
  const [activeItemId, setActiveItemId] = React.useState<string>("");
  if (collection.hideFaqPage) return null;
  const filteredCollection = collection.questionList.filter((item) => {
    const questionText = item.question?.toLowerCase();
    const answerText = item.answer?.toLowerCase();
    const keywords = searchKeywords?.toLowerCase() || "";
    if (!questionText || !answerText) return false;
    return questionText.includes(keywords) || answerText.includes(keywords);
  });

  return (
    <Root
      css={[
        { opacity: "87%" },
        tw`p-4 mb-4 overflow-hidden shadow-md bg-white border border-solid border-bloomsy-productCardBorder sm:(mx-5 p-4) md:(mx-5 p-8) lg:(mx-36 p-8) xl:(mx-36 p-8) divide-y`,
      ]}
    >
      <div css={tw`px-4 py-3 bg-bloomsy-lightgray rounded`}>
        <h2 css={tw`font-playfair font-bold text-lg`}>{collection.title}</h2>
      </div>
      {filteredCollection.length > 0 ? (
        filteredCollection.map((item) => {
          const { answer, id, question } = item;
          if (!answer?.length) return null;
          const isItemExpanded = activeItemId === id;
          return (
            <Accordion
              key={id}
              id={id}
              expanded={isItemExpanded}
              onChange={(e, isExpanded) =>
                setActiveItemId(isExpanded ? id : "")
              }
              css={[
                tw`py-2 px-4 font-roboto normal-case cursor-pointer shadow-none`,
              ]}
            >
              <AccordionSummary
                expandIcon={
                  isItemExpanded ? (
                    <MinusIcon css={tw`w-6 h-6 text-black`} />
                  ) : (
                    <PlusIcon css={tw`w-6 h-6 text-black`} />
                  )
                }
                css={[tw`capitalize focus:outline-none hover:text-bloomsy-red`]}
                classes={{
                  root: classes.root,
                  content: classes.content,
                  expanded: classes.expanded,
                }}
              >
                {question}
              </AccordionSummary>
              <AccordionDetails>
                <div
                  css={[tw`mx-4 py-2 font-roboto`, { "&>p": tw`mb-4` }]}
                  dangerouslySetInnerHTML={{ __html: answer as string }}
                />
              </AccordionDetails>
            </Accordion>
          );
        })
      ) : (
        <div css={tw`px-4 py-2 italic`}>No results found.</div>
      )}
    </Root>
  );
};
