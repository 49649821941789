/* eslint-disable @typescript-eslint/no-unused-vars */
import { ImagSection } from "src/ui/components/BlockFeatureTextImagesButton";
/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";
import {
  BlockTextImageButtonFieldsFragment,
  BlockHeroButtonsFragment,
} from "src/generated/datocms-types";
import { ContentSectionSubtitle } from "src/shared/components/ContentSection";
import { renderInternalExternalLinks } from "src/shared/helpers";
import { FixedContainer } from "src/shared/components/FixedContainer";

type props = {
  data: BlockTextImageButtonFieldsFragment;
};
export const ImageTextButton: FC<props> = ({ data }) => {
  const {
    background,
    image,
    title,
    subtitlePosition,
    description,
    paragraph,
    hasButton,
    button,
  } = data;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);

  const hex = background?.hex?.toString() || "";
  const imageSize = { w: isDesktop ? 450 : 350 };
  const imageWidthSize = { w: 800 };
  const imageSrc = `${image?.url}?w=${imageWidthSize.w}&h=${imageSize.w}`;
  const buttonStyle = [!isDesktop && tw`text-sm`];
  return (
    <FixedContainer css={tw`px-1`}>
      <div
        css={[
          tw`flex flex-wrap`,
          isDesktop ? tw`flex-row` : tw`flex-col-reverse`,
          hex && { backgroundColor: hex },
        ]}
      >
        <div
          css={tw`w-full lg:w-1/2 flex justify-start lg:justify-center px-4 lg:px-0`}
        >
          <div css={tw`flex flex-wrap justify-start pt-8 w-full sm:w-4/5`}>
            <div
              css={[tw`font-playfair w-full text-2xl md:text-3xl lg:text-4xl`]}
            >
              {subtitlePosition === "before title" && (
                <ContentSectionSubtitle css={[tw`mb-2 px-0`]}>
                  {description}
                </ContentSectionSubtitle>
              )}
              <h2>{title}</h2>
              {subtitlePosition === "after title" && (
                <ContentSectionSubtitle css={[tw`mb-2 px-0`]}>
                  {description}
                </ContentSectionSubtitle>
              )}
            </div>

            {paragraph && (
              <div
                css={[
                  tw`font-roboto text-sm lg:text-base text-gray-700 text-left w-11/12 mt-4  xl:-mt-10`,
                  { "&>p": tw`mb-4` },
                ]}
                dangerouslySetInnerHTML={{
                  __html: paragraph,
                }}
              />
            )}

            {hasButton && button.length > 0 && (
              <div
                css={tw`w-full flex justify-start mt-4  xl:-mt-10 mb-6 lg:mb-0`}
              >
                <div css={[{ "&>a": buttonStyle }, tw` md:w-1/4 lg:w-1/2 `]}>
                  {renderInternalExternalLinks(
                    button as BlockHeroButtonsFragment[]
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div css={[tw` w-full lg:w-1/2 h-full`, { height: imageSize.w }]}>
          <div css={[tw`relative h-full`]}>
            <Image
              src={imageSrc}
              layout="fill"
              css={tw`object-cover`}
              quality={IMAGE_QUALITY}
              alt={image?.alt || ""}
            />
          </div>
        </div>
      </div>
    </FixedContainer>
  );
};
