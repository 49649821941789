import { FC } from "react";
import {
  BlockProductBestSellerFieldsFragment,
  ProductFieldsFragment,
} from "src/generated/datocms-types";
import tw, { theme as twTheme } from "twin.macro";
import { getInternalLink } from "src/shared/helpers";
import { useMediaQuery } from "@mui/material";
import FixedContainer from "src/shared/components/FixedContainer";
import { SliderProducts } from "./SliderProducts";
import { ScrollSnapComponent } from "../ScrollSnapComponent";

export type BlockProductBestSellerProps = {
  data: BlockProductBestSellerFieldsFragment;
};

// eslint-disable-next-line react/function-component-definition
export const BlockProductBestSeller: FC<BlockProductBestSellerProps> = ({
  data,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const xlUp = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const { title, productBestSeller, pageLinkUrl } = data;
  const products = productBestSeller as ProductFieldsFragment[];
  const isMobile = !isDesktop && !xlUp;
  const containerWidth = !isLargeDesktop ? "1200px" : "1360px";
  const children = () => { 
    return(
      <div css={tw`font-bold py-8`}>
          <article
            css={[tw`font-roboto flex flex-wrap flex-row lg:justify-between`,
            ]}
          >
            {title && (
              <div css={tw`w-2/3 py-4`}>
                <p css={tw`font-bold text-xl lg:text-2xl text-left`}>{title}</p>
              </div>
            )}
            {pageLinkUrl.length > 0 && (
              <div css={tw`w-1/3 text-right py-4`}>
                <a
                  href={getInternalLink(pageLinkUrl[0].link)}
                  css={[tw`text-sm uppercase font-roboto underline underline-offset-4`]}
                >
                  {pageLinkUrl[0].title}
                </a>
              </div>
            )}
          </article>
        </div>
    )
    
  }
  return (
    <>
      <FixedContainer css={tw`block lg:hidden`}>
      {children}
      <ScrollSnapComponent product={products} />

      </FixedContainer>
      <FixedContainer
      CustomContainer
      css={[
        tw`text-center hidden lg:block mt-4`,
        isMobile
          ? tw`container px-4 sm:px-0`
          : [tw`mx-auto px-0`, { maxWidth: !xlUp ? "980px" : containerWidth }],
      ]}
      >
            <div>
            {children()}
            </div>
         
          <SliderProducts products={products} isBestSellerComponent />
            
        </FixedContainer>
    </>
  );
};
