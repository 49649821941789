/* eslint-disable no-console */
/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureImageFullFieldsFragment } from "src/generated/datocms-types";
import { FeatureImageFull } from "src/ui/components/BlockFeatureImageFull/FeatureImageFull";
import { useMediaQuery } from "@mui/material";
import { getInternalLink } from "src/shared/helpers";
import { BlockFeatureImageFullMobile } from "./FeatureImageFullMobile";

export type fontType = {
  title: string;
  subtitle: string;
};
export type BlockFeatureImageFullProps = {
  data: BlockFeatureImageFullFieldsFragment;
};

export const BlockFeatureImageFull: FC<BlockFeatureImageFullProps> = ({
  data: { subtitleFont, titleFont, content, hasDividers, pageLinkUrl },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);

  const fontStyle: fontType = {
    title: titleFont || "roboto",
    subtitle: subtitleFont || "roboto",
  };
  const fontTitleFamilyMap = {
    roboto: tw`font-roboto text-sm`,
    condensed: tw`font-roboto text-sm`,
    playfair: tw`font-playfair`,
  };
  const titlefontFamilyStyle =
  fontTitleFamilyMap[fontStyle.title as keyof typeof fontTitleFamilyMap];
  return (
    <FixedContainer>
      {isDesktop && (
        <>
        <div
          css={[
            hasDividers &&
              tw`divide-y divide-x-0 sm:(divide-y-0 divide-x) divide-black lg:h-36`,
            tw`flex flex-wrap justify-center mt-0 lg:mt-8`,
          ]}
        >
          {content &&
            content.map((record) => {
              if (!record) return null;
              return (
                <FeatureImageFull
                  data={record}
                  key={record.id}
                  fontStyle={fontStyle}
                  hasDividers={hasDividers}
                />
              );
            })}
        </div>
        <div>
            {pageLinkUrl.length > 0 && (
        <div css={tw`text-center mt-3`}>
          <a
            css={[
              tw`underline underline-offset-4 font-bold tracking-wider h-12 flex justify-center items-center`,
              titlefontFamilyStyle,
            ]}
            href={getInternalLink(pageLinkUrl[0].link)}
          >
            {pageLinkUrl[0].title}
          </a>
        </div>
      )}
          </div>
          </>
      )}

      {!isDesktop && (
        <BlockFeatureImageFullMobile
          data={
            {
              subtitleFont,
              titleFont,
              content,
              hasDividers,
              pageLinkUrl,
            } as BlockFeatureImageFullProps["data"]
          }
        />
      )}
    </FixedContainer>
  );
};
