/* eslint-disable react/function-component-definition */
import { FeatureImageFullContentFragment } from "src/generated/datocms-types";
import { FC } from "react";
import Image from "next/legacy/image";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";

type FeatureTextIconProps = {
  data: FeatureImageFullContentFragment;
};
export const FeatureTextIcon: FC<FeatureTextIconProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const imageSize = [
    !isDesktop ? { width: 30, height: 30 } : { width: 40, height: 40 },
  ];
  const renderImageOrSvg = () => {
    if (!data.image) return null;
    const isSvg = data.image.format === "svg";
    const imageSrc = isSvg ? data.image.url : `${data.image.url}?w=110`;
    return (
      <div css={[tw`relative mx-auto w-full h-full`, imageSize]}>
        <Image
          css={tw` object-fill`}
          src={imageSrc}
          layout="fill"
          quality={IMAGE_QUALITY}
          alt={data.image?.alt || ""}
        />
      </div>
    );
  };
  return (
    <div
      css={[
        tw`flex flex-wrap mt-2 w-full items-center justify-center md:justify-between border-b border-gray-900`,
        isDesktop ? { width: "48%" } : tw`w-full md:w-5/12`,
      ]}
    >
      <div css={tw``}>{renderImageOrSvg()}</div>
      <div
        css={tw`w-5/6 lg:w-11/12 lg:px-4 pb-4 flex flex-wrap items-start h-full`}
      >
        <h3
          css={tw`text-sm lg:text-base block capitalize font-bold font-roboto tracking-wider text-bloomsy-darkgray mb-2 lg:(mb-0) `}
        >
          {data.title}
        </h3>
        <p css={tw`text-sm lg:text-base text-gray-700 font-roboto h-full`}>
          {data.text}
        </p>
      </div>
    </div>
  );
};
