import { useKeenSlider } from "keen-slider/react";
import { FC, useEffect, useState } from "react";
import Image from "next/legacy/image";
import { IMAGE_QUALITY } from "src/constants";
import { ProductFieldsFragment } from "src/generated/datocms-types";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import FixedContainer from "src/shared/components/FixedContainer";
import { ProductTag } from "src/shared/types";
import {slugsPrefixMap,
  freeShippingIcon,
} from "src/services/datocms/constants";
import { getProductRecurringLabel } from "src/shared/utils/products";
import { formatShopifyPrice } from "src/shared/helpers";
import { copy } from "src/features/ProductForm/constants";

type Props = {
  products: ProductFieldsFragment[];
  isBestSellerComponent?: boolean;
};

// eslint-disable-next-line react/function-component-definition
export const SliderComponent: FC<Props> = ({
  products,
  isBestSellerComponent = true,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const isXl = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [showRighArrow, setshowRighArrow] = useState(false);
  const styles = [
     tw` w-7 bg-white`,
    tw`absolute cursor-pointer text-gray-600`,
  ];
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    breakpoints: {
      "(min-width: 600px)": {
        slidesPerView: 2,
        spacing: 5,
        loop: false,
        centered:true
      },
      "(min-width: 1024px)": {
        slidesPerView: 3,
        spacing: 0,
      },
      "(min-width: 1110px)": {
        slidesPerView: 3,
        spacing: 0,
      },
      "(min-width: 1280px)": {
        slidesPerView: 4,
        spacing: 0,
      },
       "(min-width: 1366px)": {
        slidesPerView: 4,
        spacing: 0,
      },  
    },
    loop: true,
    initial: 0,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });
  const smallerDeskDimensions = isXl ? 300 : 400; 
  const smallerDeskDimensionsH = isXl ? 280 : 360; 
  const productImageSize = {
    w: isLargeDesktop ? 333 : smallerDeskDimensions,
    h: isLargeDesktop ? 331 : smallerDeskDimensionsH,
  };
  useEffect(() => {
    if (slider) {
      slider.refresh();
    }
  }, [products, slider]);
  useEffect(() => {
    if (slider) {
      const { slidesPerView } = slider.details();
      if (slidesPerView > 1) {
        setshowRighArrow(
          currentSlide <
            Math.round(slider.details().size / slider.details().slidesPerView)
        );
      } else {
        setshowRighArrow(
          currentSlide + 1 <
            Math.ceil(slider.details().size / slider.details().slidesPerView)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slider, currentSlide, setshowRighArrow]);
  return (
    <div css={tw`flex`}>
       <div css={tw`relative w-full`}>
        <FixedContainer
          CustomContainer
          css={[tw`w-full`,isDesktop && !isLargeDesktop ? tw`` : tw` px-0`]}
        >
           <div ref={sliderRef} className="keen-slider" css={tw``}>
            {products.map((p) => {
                  const subscriptionImage = p.gallery[1] ?? p.gallery[0];
                  const firstImage = p.isSubscription ? subscriptionImage : p.gallery[0];
                const hasStemsValue = p.bouquetSize?.trim() !== "-";
                const recurringFrequency = getProductRecurringLabel(
                  p.title ?? "",
                  p.tags ?? ""
                );
                const hasFreeShipping = p.tags?.find((tag: ProductTag) => {
                  return tag?.slug?.includes(freeShippingIcon);
                });
                const productUrl = `${slugsPrefixMap.product}${p.slug}`;
                const salesPrice = formatShopifyPrice(
                  p?.shopifyProduct.compareAtPriceRange?.minVariantPrice ?? 0
                ) ?? "";
                const hasSalesPrice = Number(
                  p?.shopifyProduct?.compareAtPriceRange?.minVariantPrice?.amount ?? 0
                ) > 0;
                const minPrice = formatShopifyPrice(
                  p?.shopifyProduct?.priceRange?.minVariantPrice ?? 0
                ) ?? "";

                return (
                  <div
                    key={p.id}
                      className="keen-slider__slide" css={tw`text-left px-0.5`}
                    >
                      <a href={productUrl}>
                        <Image
                          src={`${firstImage.url}?w=${550}&h=${500}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`}
                          width={productImageSize.w}
                          height={productImageSize.h}
                          quality={IMAGE_QUALITY}
                          alt="product"
                          css={tw`object-cover`} />
                        <div css={tw``}>
                          {hasFreeShipping && (
                            <div css={tw`capitalize leading-7 pt-2`}>
                              <span css={[tw`font-roboto block`, { color: "#41A317" }]}>{copy.freeShipping.toLowerCase()}</span>
                            </div>
                          )}
                          <div css={[!hasFreeShipping && tw`pt-2`, tw`w-full`]}>
                          <span css={tw`capitalize font-roboto`}>{p.title}</span>
                          </div>
                          <div css={tw`flex flex-wrap w-full text-base leading-7`}>
                            <div css={tw`flex capitalize font-roboto w-5/6 leading-7`}>
                              <p>{minPrice}</p>
                              {hasSalesPrice && (
                                <p css={tw`ml-1 text-base text-gray-500 line-through`}>
                                  {salesPrice}
                                </p>
                              )}
                              {p.isSubscription && (<span css={tw`lowercase`}>/{recurringFrequency}</span>)}

                            </div>
                            <div>
                              {p.isSubscription && hasStemsValue && (
                                <span css={tw`capitalize font-roboto text-sm inline text-bloomsy-midgray leading-6`}>
                                  {`Includes approx. ${p.bouquetSize} stems`}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                );
              })}

        </div>
        </FixedContainer> 
            <div css={[styles, tw`-left-8 top-0`, { background: "#11ffee00",height: productImageSize.h}]}>
            <div css={[tw`relative top-[38%] xl:top-[45%]`, { height: productImageSize.h }]}>
            <div css={[styles]}>
                <MdArrowBackIos css={tw`w-6 h-6`} onClick={() => slider.prev()}/>
                </div>
              </div>
            </div>
            <div css={[styles, tw`-right-8 top-0`, { background: "#11ffee00",height: productImageSize.h}]}>
              <div css={[tw`relative top-[38%] xl:top-[45%]`]}>
                <div css={[styles]}>
                <MdArrowForwardIos css={tw`w-6 h-6`} onClick={() => slider.next()} />
                </div>
              </div>
            </div>
       </div>
    </div>
  );
};
