import { FC } from "react";
import Image from "next/legacy/image";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";

type Props = {
  productImage: Record<string, any>;
};
export const ImageCard: FC<Props> = ({ productImage }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  return (
    <div>
      <div>
        <Image
          css={[{ opacity: "1" }]}
          src={productImage[1].src}
          width={productImage[1].width}
          height={productImage[1].height}
          unoptimized
          layout="intrinsic"
          alt={productImage[1].alt}
        />
      </div>
      <div
        css={[
          tw`absolute`,
          isDesktop && {
            left: "0%",
            top: "0%",
          },
        ]}
      >
        <Image
          css={[
            {
              opacity: "1",
              transition: "opacity 0.5s ease-in-out;",
              " &:hover{opacity": "0;}",
            },
          ]}
          src={productImage[0].src}
          width={productImage[0].width}
          height={productImage[0].height}
          unoptimized
          layout="intrinsic"
          alt={productImage[0].alt}
        />
      </div>
    </div>
  );
};
