/* eslint-disable react/function-component-definition */
import { FC, Fragment, useCallback, useEffect, useState, useRef } from "react";
import tw, { theme as twTheme } from "twin.macro";
import debounce from "lodash.debounce";
import { useMediaQuery } from "@mui/material";
import { collectionResult } from "../utils/products";

type Props = {
  collections: collectionResult[];
  activeNode: HTMLElement | null;
};
export const CollectionNav: FC<Props> = ({ collections, activeNode }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const [headerHeight, setHeaderHeight] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const anchorOffsetTop = anchorRef.current?.offsetTop ?? 0;
  const scrollToNode = useCallback(
    (nodeId) => {
      const node = document.getElementById(nodeId);
      if (!node) return false;
      const position = node.offsetTop + 400;
      window.scrollTo({
        top: isDesktop ? position : position - 10,
        behavior: "smooth",
      });
    },
    [isDesktop]
  );
  const handleScroll = useCallback(() => {
    return debounce(() => {
      const header = document.getElementById("js-pageHeader");
      const _height = header?.offsetHeight ?? 0;
      const offset = anchorOffsetTop - _height;
      const { scrollTop } = document.documentElement;
      const scrolled = scrollTop > offset;
      setHasScrolled(scrolled);
      if (headerHeight !== _height) {
        setHeaderHeight(_height);
      }
    }, 1)();
  }, [anchorOffsetTop, headerHeight]);
  useEffect(() => {
    const header = document.getElementById("js-pageHeader");
    setHeaderHeight(header?.offsetHeight ?? 0);
  }, []);
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);
  return (
    <Fragment>
      <div ref={anchorRef} />
      <div
        css={[
          tw`sticky bg-white`,
          !isDesktop && tw`z-50`,
          headerHeight > 0 ? tw`block` : tw`hidden`,
          hasScrolled && !isDesktop && tw`shadow-lg`,
          { top: isDesktop ? headerHeight + 50 : headerHeight - 2 },
        ]}
      >
        <div
          css={[tw`overflow-auto md:justify-center`, isDesktop && tw`mt-28`]}
        >
          {collections.map((collection) => {
            return (
              <div key={collection.id}>
                {isDesktop ? (
                  <span
                    css={[
                      tw`block py-2 font-roboto uppercase`,
                      { fontSize: "13.6px" },
                      activeNode?.id === collection.id && tw`font-bold`,
                    ]}
                    role="button"
                    tabIndex={0}
                    onKeyPress={() => scrollToNode(collection.id)}
                    onClick={() => scrollToNode(collection.id)}
                  >
                    {collection.title}
                  </span>
                ) : (
                  <button
                    onClick={() => scrollToNode(collection.id)}
                    type="button"
                    key={collection.id}
                    css={[
                      tw`font-roboto uppercase tracking-wider bg-white px-3 py-1 rounded-full text-xs border border-gray-200 whitespace-nowrap`,
                      activeNode?.id === collection.id &&
                        tw`bg-gray-500 text-white`,
                    ]}
                  >
                    {collection.title}
                  </button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Fragment>
  );
};
