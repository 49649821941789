/* eslint-disable no-nested-ternary */
/* eslint-disable react/function-component-definition */
import { FC } from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureLinkCollectionFieldsFragment } from "src/generated/datocms-types";
import { PilarPages, Tags } from "./TypeOfLinks";
import { CardsGrid } from "./CardsGrid";

export type BlockFeatureLinkCollectionProps = {
  data: BlockFeatureLinkCollectionFieldsFragment;
};
export const BlockFeatureLinkCollection: FC<
  BlockFeatureLinkCollectionProps
> = ({ data }) => {
  const { linkCollection, typeOfLinks } = data;
  const typeOfLinksValue = typeOfLinks === "" ? "Tags" : typeOfLinks;
  const hasTagsLinks = typeOfLinksValue === "Tags";
  const hasCardsGridLink = typeOfLinksValue === "Cards Grid";
  if (!linkCollection) return null;
  return (
    <FixedContainer>
      {hasTagsLinks ? (
        <Tags data={data} />
      ) : hasCardsGridLink ? (
        <CardsGrid data={data} />
      ) : (
        <PilarPages data={data} />
      )}
    </FixedContainer>
  );
};
