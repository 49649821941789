/* eslint-disable react/function-component-definition */
import { FC, useEffect } from "react";
import { BlockFeatureReviewCollectionFieldsFragment } from "src/generated/datocms-types";
import tw from "twin.macro";
import { useKeenSlider } from "keen-slider/react";
import { FeatureTestimonial } from "src/ui/components/BlockFeatureTestimonial/FeatureTestimonial";

export type BlockFeatureReviewProps = {
  data: BlockFeatureReviewCollectionFieldsFragment;
};

export const FeatureReviewMobile: FC<BlockFeatureReviewProps> = ({
  data: { reviewCollection },
}) => {
  const hasCollection = reviewCollection.length > 1;
  const classNameForSlider = hasCollection ? "keen-slider" : "";
  const classNameForSliderPage = hasCollection ? "keen-slider__slide" : "";

  const [slideRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: 1.2,
    spacing: 4,
    loop: true,
  });

  useEffect(() => {
    if (slider) slider.refresh();
  }, [slider]);

  return (
    <div css={tw`z-10`}>
      <div ref={slideRef} className={classNameForSlider}>
        {reviewCollection?.map((sliderPage) => {
          const {
            hasDefaultCardPerView,
            threeCardPerViewContent,
            sixCardPerViewContent,
            cardsColor,
            cardType,
          } = sliderPage;

          const listViewContent = hasDefaultCardPerView
            ? threeCardPerViewContent
            : sixCardPerViewContent;

          return listViewContent.map((record) => {
            if (!record) return null;
            return (
              <div
                key={record.id}
                className={classNameForSliderPage}
                css={tw`flex flex-wrap justify-center`}
              >
                <FeatureTestimonial
                  data={record}
                  key={record.id}
                  cardsColor={cardsColor || ""}
                  cardType={cardType || ""}
                />
              </div>
            );
          });
        })}
      </div>
    </div>
  );
};
