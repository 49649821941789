/* eslint-disable react/function-component-definition */
import * as React from "react";
import tw, { theme as twTheme } from "twin.macro";
import { BlockInstagramSliderFieldsFragment } from "src/generated/datocms-types";
import { useMediaQuery } from "@mui/material";
import {
  ContentSectionSubtitle,
} from "src/shared/components/ContentSection";
import { DesktopSlider } from "./DesktopSlider";
import { MobileSlider } from "./MobileSlider";

export type BlockFeatureInstagramSliderProps = {
  data: BlockInstagramSliderFieldsFragment;
};
export const BlockFeatureInstagramSlider: React.FC<
  BlockFeatureInstagramSliderProps
> = ({ data }) => {
  const { title, subtitle } = data;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const [hydrationCompleted, setHydrationCompleted] = React.useState(false);
  React.useEffect(() => {
    setHydrationCompleted(true);
  }, []);
  return (
      <div>
        {title && (
          <h3
            css={tw`text-2xl sm:text-3xl font-playfair leading-tight px-4 sm:px-0 text-center`}
          >
            {title}
          </h3>
        )}
        {subtitle && (
          <ContentSectionSubtitle css={tw`text-center`}>{subtitle}</ContentSectionSubtitle>
        )}
        {isDesktop && hydrationCompleted && (<DesktopSlider />)}
        {!isDesktop && hydrationCompleted && (<MobileSlider />)}
      </div>
  );
};
