import { FC, useRef, useState, useEffect } from "react";
import { useKeenSlider } from "keen-slider/react";
import tw, { theme as twTheme, TwStyle } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import FixedContainer from "src/shared/components/FixedContainer";
import { renderInternalExternalLinks } from "src/shared/helpers";
import {
  BlockHeroButtonsFragment,
  BlockHeroAnimatedTextFieldsFragment,
} from "src/generated/datocms-types";
import { IMAGE_QUALITY } from "src/constants";

const fontFamilyMap = {
  rcondensed: tw`font-roboto`,
  roboto: tw`font-roboto`,
  playfair: tw`font-playfair`,
};

export type BlockHeroAnimatedTextProps = {
  data: BlockHeroAnimatedTextFieldsFragment;
};
export const BlockHeroAnimatedText: FC<BlockHeroAnimatedTextProps> = ({
  data: {
    bgImage,
    buttons,
    centered: isCentered,
    mobileImage,
    subtitle,
    subtitleAfter,
    text,
    theme,
    title,
    animatedText,
    titleLogo,
    titleLogoAfter,
    contentWidth,
    titleFontFamily,
    titleSubtitleFontSize,
  },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);

  const [pause] = useState(false);
  const timer = useRef<number | null>(null);
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    spacing: 0,
    slidesPerView: 1,
    centered: true,
    vertical: true,
    loop: true,
    duration: 1500,
    controls: false,
    mode: "free-snap",
  });

  useEffect(() => {
    timer.current = window.setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 4000);
    return () => {
      window.clearInterval(timer.current || 0);
    };
  }, [pause, slider]);

  const isDarkMode = theme === "dark";
  const maxContentWidthMap = {
    large: tw`max-w-full`,
    medium: tw`max-w-lg`,
    small: tw`max-w-xs`,
  };
  const maxContentWidth =
    maxContentWidthMap[contentWidth as keyof typeof maxContentWidthMap] ??
    tw`max-w-xl`;
  const hasLargeText = titleSubtitleFontSize === "large";

  const fontFamilyKey = titleFontFamily ?? "roboto";
  const fontFamilyStyle =
    fontFamilyMap[fontFamilyKey as keyof typeof fontFamilyMap];

  const styles = [
    tw`text-3xl font-bold m-0 leading-tight`,
    hasLargeText
      ? [{ fontSize: isDesktop ? "45px" : "30px" }]
      : tw`lg:text-4xl`,
    fontFamilyStyle,
  ];

  const renderSubHeading = (props: TwStyle = {}) => {
    return (
      <p
        css={[
          hasLargeText ? tw`lg:text-lg` : tw`lg:text-sm`,
          tw`font-roboto uppercase font-normal tracking-wider text-sm`,
          props,
        ]}
      >
        {subtitle}
      </p>
    );
  };
  const renderHeadingImage = (showBefore: boolean) => {
    const isSvg = titleLogo?.format === "svg";
    const imageSrc = isSvg ? `${titleLogo?.url}` : `${titleLogo?.url}&w=320`;
    return (
      <div
        css={[
          tw`relative mx-auto`,
          { height: 60, width: 320 },
          showBefore ? tw`mb-4` : tw`mt-4`,
        ]}
      >
        <Image
          css={tw`block object-contain`}
          src={imageSrc}
          layout="fill"
          quality={IMAGE_QUALITY}
          alt={titleLogo?.alt ?? ""}
        />
      </div>
    );
  };
  const renderImage = (
    image:
      | BlockHeroAnimatedTextFieldsFragment["bgImage"]
      | BlockHeroAnimatedTextFieldsFragment["mobileImage"]
  ) => {
    const imageSize = { h: !isDesktop ? 400 : 600 };
    const imageSrc = `${image?.url}?h=${imageSize.h}`;
    return (
      <div css={[tw`relative`, { height: imageSize.h }]}>
        <Image
          css={tw`object-cover`}
          src={imageSrc}
          layout="fill"
          quality={IMAGE_QUALITY}
          alt={image?.alt ?? ""}
        />
      </div>
    );
  };
  const renderHeroImage = () => {
    if (mobileImage?.responsiveImage && !isDesktop) {
      return renderImage(mobileImage);
    }
    return renderImage(bgImage);
  };
  const renderTitle = () => {
    return hasLargeText ? (
      <h1 css={styles}>{title}</h1>
    ) : (
      <h2 css={styles}>{title}</h2>
    );
  };

  return (
    <div css={[tw`w-full h-full relative overflow-hidden bg-white`]}>
      {renderHeroImage()}
      <div
        css={[
          tw`w-full h-auto absolute`,
          isDarkMode ? tw`text-white` : tw`text-black`,
          { top: "50%", left: "50%", transform: "translate(-50%,-50%)" },
        ]}
      >
        <FixedContainer css={isCentered ? tw`text-center` : tw`text-left`}>
          <div css={[isCentered ? tw`mx-auto` : tw`mx-0`, maxContentWidth]}>
            {titleLogo && !titleLogoAfter && renderHeadingImage(true)}
            {subtitle && !subtitleAfter && renderSubHeading(tw`mb-2`)}
            {title && animatedText && (
              <div css={tw`flex flex-wrap items-baseline  sm:pl-3`}>
                <div>{renderTitle()}</div>
                <div
                  ref={sliderRef}
                  className="keen-slider"
                  css={[tw` sm:pl-3`, { width: "500px", height: "60px" }]}
                >
                  {animatedText.map((t) => {
                    return (
                      <div
                        className="keen-slider__slide"
                        key={t?.id}
                        css={[
                          tw`text-4xl font-playfair font-bold m-0 leading-tight lg:text-5xl`,
                        ]}
                      >
                        {t?.text}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {subtitle && subtitleAfter && renderSubHeading()}
            {text && (
              <div
                css={[
                  { "&>p": tw`mb-4` },
                  isCentered ? tw`mx-auto` : tw`mx-0`,
                  tw`my-4 font-roboto max-w-3xl text-sm lg:text-base`,
                  isDarkMode ? tw`text-white` : tw`text-gray-700`,
                ]}
                dangerouslySetInnerHTML={{ __html: text }}
              />
            )}
            {titleLogo && titleLogoAfter && renderHeadingImage(false)}
            {buttons && buttons.length > 0 && (
              <div css={tw`mt-6 lg:mt-12`}>
                {renderInternalExternalLinks(
                  buttons as BlockHeroButtonsFragment[]
                )}
              </div>
            )}
          </div>
        </FixedContainer>
      </div>
    </div>
  );
};
