import {
  BlockFeatureTestimonial,
  BlockFeatureTestimonialProps,
} from "src/ui/components/BlockFeatureTestimonial";
import { BlockProse, BlockProseProps } from "src/ui/components/BlockProse";
import {
  BlockProseColumn,
  BlockProseColumnProps,
} from "src/ui/components/BlockProseColumn";
import {
  BlockProseColumnTitle,
  BlockProseColumnTitleProps,
} from "src/ui/components/BlockProseColumnTitleBgImage";
import {
  BlockFeatureImageFull,
  BlockFeatureImageFullProps,
} from "src/ui/components/BlockFeatureImageFull";
import {
  BlockFeaturedIn,
  BlockFeaturedInProps,
} from "src/ui/components/BlockFeaturedIn";
import {
  BlockMainFeature,
  BlockMainFeatureProps,
} from "src/ui/components/MainFeature";
import { BlockHero, BlockHeroProps } from "src/ui/components/BlockHero";
import {
  BlockHeroAnimatedText,
  BlockHeroAnimatedTextProps,
} from "src/ui/components/BlockHeroAnimatedText";
import {
  BlockFeatureImageTitle,
  BlockFeatureImageTitleProps,
} from "src/ui/components/BlockFeatureImageTitle";
import {
  BlockFeatureImageCard,
  BlockFeatureImageCardProps,
} from "src/ui/components/BlockFeatureImageCard";
import {
  BlockFeatureQuote,
  BlockFeatureQuoteProps,
} from "src/ui/components/BlockFeatureQuote";
import {
  BlockImageGrid,
  BlockImageGridProps,
} from "src/ui/components/BlockImageGrid";
import { ContentModulesFragment } from "src/generated/datocms-types";
import {
  BlockProductCollection,
  BlockProductCollectionProps,
} from "src/ui/components/BlockProductCollection";
import {
  BlockFlowerCollection,
  BlockFlowerCollectionProps,
} from "src/ui/components/BlockFlowerCollection";
import {
  BlockPlantCollection,
  BlockPlantCollectionProps,
} from "src/ui/components/BlockPlantCollection";

import { CollectionProductsMap } from "src/shared/types";
import {
  BlockFeatureVideo,
  BlockFeatureVideoProps,
} from "src/ui/components/BlockFeatureVideo";
import {
  BlockFeatureRowImageCard,
  BlockFeatureRowImageCardProps,
} from "src/ui/components/BlockFeatureRowImageCard";
import {
  BlockFeatureInstagramFull,
  BlockFeatureInstagramFullProps,
} from "src/ui/components/BlockFeatureInstagramFull";
import {
  BlockFeatureInstagramProduct,
  BlockFeatureInstagramProductProps,
} from "src/ui/components/BlockFeatureInstagramProduct";
import {
  BlockFeatureInstagramSlider,
  BlockFeatureInstagramSliderProps,
} from "src/ui/components/BlockFeatureInstagramSlider";
import {
  BlockCardImageTitle,
  BlockRowCardImageTitleRecordProps,
} from "src/ui/components/BlockRowCard-ImageTitle";

import {
  BlockFeatureReview,
  BlockFeatureReviewProps,
} from "src/ui/components/BlockFeatureReview";

import {
  BlockTestimonialCompanySlider,
  BlockTestimonialCompanySliderprops,
} from "src/ui/components/BlockFeatTestimonialCompanySlider";

import {
  BlockEmbeddedForm,
  BlockEmbeddedFormprops,
} from "src/ui/components/BlockEmbeddedForm";
import {
  BlockMainFeatureContainer,
  BlockMainFeatureContainerProps,
} from "src/ui/components/MainFeatureContainer";
import {
  BlockFeatureLinkCollection,
  BlockFeatureLinkCollectionProps,
} from "src/ui/components/BlockFeatureLinkCollection";
import {
  BlockFeatureDiscount,
  BlockFeatureDiscountProps,
} from "src/ui/components/BlockFeatureDiscountCard";
import { BlockFAQ, BlockFAQprops } from "src/ui/components/BlockFeatFAQ";
import {
  BlockFeatureTextImageButton,
  BlockFeatureTextImageButtonProps,
} from "src/ui/components/BlockFeatureTextImagesButton";
import {
  BlockImageText,
  BlockImageTextProps,
} from "src/ui/components/BlockFeatureImageText";
import {
  BlockProductCollectionSlider,
  BlockProductCollectionSliderProps,
} from "src/ui/components/BlockProductCollectionSlider";
import {
  BlockProductBestSeller,
  BlockProductBestSellerProps,
} from "src/ui/components/BlockProductBestSeller";

import {
  BlockFeatureTextIconFull,
  BlockFeatureTextIconFullProps,
} from "src/ui/components/BlockFeatureTextIcon";

import {
  BlockFeatureTextImage,
  BlockFeatureTextImageProps,
} from "src/ui/components/BlockTextImage";
import {
  BlockHeroBannerColumn,
  BlockHerobanner2columnProps,
} from "src/ui/components/BlockHeroBanner2Column";
import {
  BlockCTA,
  BlockCTAProps,
} from "src/ui/components/BlockCTA";

export const renderCmsModularContent = (
  content: ContentModulesFragment[],
  collectionProducts: CollectionProductsMap
) => {
  return content.map((data) => {
    const modelApiKey = data._modelApiKey;
    const key = data.id;
    if (!modelApiKey) return null;
    if (modelApiKey === "block_feature_video") {
      return (
        <BlockFeatureVideo
          key={key}
          data={data as BlockFeatureVideoProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_flower_collection") {
      return (
        <BlockFlowerCollection
          key={key}
          data={data as BlockFlowerCollectionProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_plant_collection") {
      return (
        <BlockPlantCollection
          key={key}
          data={data as BlockPlantCollectionProps["data"]}
        />
      );
    }

    if (modelApiKey === "block_product_collection") {
      return (
        <BlockProductCollection
          key={key}
          data={data as BlockProductCollectionProps["data"]}
          collectionProducts={collectionProducts}
        />
      );
    }
    if (modelApiKey === "block_image_grid") {
      return (
        <BlockImageGrid key={key} data={data as BlockImageGridProps["data"]} />
      );
    }
    if (modelApiKey === "block_feature_quote") {
      return (
        <BlockFeatureQuote
          key={key}
          data={data as BlockFeatureQuoteProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_prose") {
      return <BlockProse key={key} data={data as BlockProseProps["data"]} />;
    }
    if (modelApiKey === "block_feature_image_card") {
      return (
        <BlockFeatureImageCard
          key={key}
          data={data as BlockFeatureImageCardProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_image_full") {
      return (
        <BlockFeatureImageFull
          key={key}
          data={data as BlockFeatureImageFullProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_image_title") {
      return (
        <BlockFeatureImageTitle
          key={key}
          data={data as BlockFeatureImageTitleProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_testimonial") {
      return (
        <BlockFeatureTestimonial
          key={key}
          data={data as BlockFeatureTestimonialProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_hero") {
      return <BlockHero key={key} data={data as BlockHeroProps["data"]} />;
    }
    if (modelApiKey === "block_hero_animated_text") {
      return (
        <BlockHeroAnimatedText
          key={key}
          data={data as BlockHeroAnimatedTextProps["data"]}
        />
      );
    }

    if (modelApiKey === "block_main_feature") {
      return (
        <BlockMainFeature
          key={key}
          data={data as BlockMainFeatureProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_featured_in") {
      return (
        <BlockFeaturedIn
          key={key}
          data={data as BlockFeaturedInProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_row_image_card") {
      return (
        <BlockFeatureRowImageCard
          key={key}
          data={data as BlockFeatureRowImageCardProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_prose_column") {
      return (
        <BlockProseColumn
          key={key}
          data={data as BlockProseColumnProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_prose_column_title_bgimage") {
      return (
        <BlockProseColumnTitle
          key={key}
          data={data as BlockProseColumnTitleProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_instagram_full") {
      return (
        <BlockFeatureInstagramFull
          key={key}
          data={data as BlockFeatureInstagramFullProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_instagram_product") {
      return (
        <BlockFeatureInstagramProduct
          key={key}
          data={data as BlockFeatureInstagramProductProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_instagram_slider") {
      return (
        <BlockFeatureInstagramSlider
          key={key}
          data={data as BlockFeatureInstagramSliderProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_review_collection") {
      return (
        <BlockFeatureReview
          key={key}
          data={data as BlockFeatureReviewProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_row_card_image_title") {
      return (
        <BlockCardImageTitle
          key={key}
          data={data as BlockRowCardImageTitleRecordProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feat_testimonials_companies_slider") {
      return (
        <BlockTestimonialCompanySlider
          key={key}
          data={data as BlockTestimonialCompanySliderprops["data"]}
        />
      );
    }
    if (modelApiKey === "embedded_form") {
      return (
        <BlockEmbeddedForm
          key={key}
          data={data as BlockEmbeddedFormprops["data"]}
        />
      );
    }

    if (modelApiKey === "block_main_feature_container") {
      return (
        <BlockMainFeatureContainer
          key={key}
          data={data as BlockMainFeatureContainerProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_discount_card") {
      return (
        <BlockFeatureDiscount
          key={key}
          data={data as BlockFeatureDiscountProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_link_collection") {
      return (
        <BlockFeatureLinkCollection
          key={key}
          data={data as BlockFeatureLinkCollectionProps["data"]}
        />
      );
    }
    if (modelApiKey === "faqs_collection") {
      return <BlockFAQ key={key} data={data as BlockFAQprops["data"]} />;
    }
    if (modelApiKey === "block_feature_text2images_button") {
      return (
        <BlockFeatureTextImageButton
          key={key}
          data={data as BlockFeatureTextImageButtonProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_image_text") {
      return (
        <BlockImageText key={key} data={data as BlockImageTextProps["data"]} />
      );
    }
    if (modelApiKey === "product_collection_slider") {
      return (
        <BlockProductCollectionSlider
          key={key}
          data={data as BlockProductCollectionSliderProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_text_icon") {
      return (
        <BlockFeatureTextIconFull
          key={key}
          data={data as BlockFeatureTextIconFullProps["data"]}
        />
      );
    }
    if (modelApiKey === "product_best_seller") {
      return (
        <BlockProductBestSeller
          key={key}
          data={data as BlockProductBestSellerProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_feature_text_image_module") {
      return (
        <BlockFeatureTextImage
          key={key}
          data={data as BlockFeatureTextImageProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_herobanner2column") {
      return (
        <BlockHeroBannerColumn
          key={key}
          data={data as BlockHerobanner2columnProps["data"]}
        />
      );
    }
    if (modelApiKey === "block_cta") {
      return (
        <BlockCTA
          key={key}
          data={data as BlockCTAProps["data"]}
        />
      );
    }
    return null;
  });
};

