import { fourSixtyScript } from "src/shared/types/fourSixty";

export const instagramProductSliderScript: fourSixtyScript = {
  src: "https://foursixty.com/media/scripts/fs.slider.v2.5.js",
  dataTheme: "slider_v2_5",
  dataCellSize: "33.333%",
  dataHasPostSelector: "#foursixty-hideable-container",
  dataForUrl: "true",
};

export const instagramProductSliderStyle = `
   <style>
    .fs-has-links::after {
      padding: 5px 7.5px;
      border: 1px solid #ffffff;
      color: #ffffff;
      content: "SHOP IT";
    }
    .fs-has-links:hover:after {
      background-color: #ffffff;
      color: rgba(0, 0, 0, 0.8);
    }
    .fs-wrapper {
      height: auto;
    }
    .fs-entry-container {
      height: 0 !important;
      width: 33.333% !important;
      padding-top: 33.333% !important;
    }
    .fs-wrapper div.fs-text-container .fs-entry-title,
    div.fs-detail-title {
      font-family: Times New Roman, serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
    div.fs-text-container .fs-entry-date,
    div.fs-detail-container .fs-post-info,
    div.fs-wrapper div.fs-has-links::after,
    .fs-text-product,
    .fs-overlink-text {
      font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
    }
    .fs-slider-next-button,
    .fs-slider-prev-button {
      opacity: 1;
      background-color: rgba(30, 255, 255, 0);
      z-index: 10;
    }
    .fs-wrapper {
      margin: auto;
      width: calc(100% - 120px);
    }
    .fs-wrapper div.fs-text-container * {
      color: #ffffff;
    }
    .fs-wrapper div.fs-text-container {
      background-color: rgba(0, 0, 0, 0.8);
      margin: 0px;
    }
    div.fs-entry-date {
      display: none;
    }
    div.fs-entry-title {
      display: none;
    }
    .fs-wrapper div.fs-timeline-entry {
      margin: 5px;
    }
  </style>
`; 
