/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw from "twin.macro";
import {
  getProductRecurringLabel,
  isSuperGiftProduct,
} from "src/shared/utils/products";

export const ProductPriceBlock: FC<{
  productId: string;
  minPrice: string;
  bouquetSize: string;
  title: string;
  tags: [];
}> = ({ productId, minPrice, bouquetSize, title, tags }) => {
  if (!minPrice) return null;
  const recurringFrequency = getProductRecurringLabel(title, tags);
  const frecuencyLabel = isSuperGiftProduct(productId)
    ? ""
    : ` / ${recurringFrequency.toUpperCase()}`;

  const hasStemsValue = bouquetSize.trim() !== "-";
  return (
    <div css={tw`mt-2 mb-4 flex items-center flex-wrap`}>
      <p
        css={tw`font-roboto font-normal text-xl lg:text-xl pr-2 mb-2 w-full lg:(w-auto mb-0 pr-2)`}
      >
        {minPrice}
        <span
          css={[
            tw`font-roboto font-bold tracking-wide border-solid border-black`,
            { fontSize: "13.6px" },
          ]}
        >
          {frecuencyLabel}
        </span>
      </p>
      {hasStemsValue && (
        <div>
          <span
            css={[
              tw`font-black uppercase tracking-wider font-roboto lg:pb-0.5 `,
              { fontSize: "13.6px" },
            ]}
          >
            APPROX {bouquetSize} STEMS
          </span>
        </div>
      )}
    </div>
  );
};
