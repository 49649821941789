import { fourSixtyScript } from "src/shared/types/fourSixty";

export const instagramSliderScript: fourSixtyScript = {
  src: "https://foursixty.com/media/scripts/fs.slider.v2.5.js",
  dataTheme: "slider_v2_5",
  dataCellSize: "16.666%",
};

 export const instagramSliderMobileScript: fourSixtyScript = {
  src: "https://foursixty.com/media/scripts/fs.embed.v2.5.js",
  dataTheme: "showcase_v2_5",
  dataPageSize:"6",
} 

export const instagramSliderStyle = `
<style>
  .fs-has-links
    {
      display:none;
    }
  .fs-wrapper
  {
    height:auto;
  }
  .fs-slider_v2_5
  .fs-entry-container
  {
    height:0 !important;
    width:16.666%!important;
    padding-top:16.666%!important;
  }
  .fs-wrapper
  div.fs-text-container
  .fs-entry-title,
  div.fs-detail-title
  {
    font-family:Times New Roman,
    serif;font-style:normal;
    font-weight:normal;
    font-size:14px;
  }
  div.fs-text-container
  .fs-entry-date,
  div.fs-detail-container
  .fs-post-info,
  div.fs-wrapper
  div.fs-has-links::after,
  .fs-text-product,
  .fs-overlink-text
  {
    font-family:Helvetica Neue,Helvetica,Arial, sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:14px;
  }
  .fs-wrapper
  div.fs-text-container
  * { color:#ffffff; }
  .fs-wrapper
  div.fs-text-container
  {
    background-color:rgba(0,0,0,-0.005);
    margin:0px;
  }
  div.fs-entry-date
  {
    display:none;
  }
  div.fs-service-icon
  {
    display:none;
  }
  div.fs-entry-title
  {
    display:none;
  }
  .fs-slider_v2_5 .
  fs-wrapper
  div.fs-timeline-entry
  {
    margin:1px;
  }
   </style>`;

export const instagramSliderMobileStyle = `
 <style>
  .fs-has-links::after
    {
      padding:5px 7.5px;
      background-color:#ffffff;
      color:rgba(0, 0, 0, 0.8);
      content:"SHOP IT";
    }
  .fs-showcase_v2_5.fs-desktop
  .fs-entry-container
  {
    width:33.333333333333336%
    !important;
    padding-top:33.333333333333336% !important;
  }
  .fs-showcase_v2_5.fs-mobile
  .fs-entry-container
  {
    width:50% !important;
    padding-top:50% !important;
  }
  .fs-wrapper
  div.fs-text-container
  .fs-entry-title,
  div.fs-detail-title
  {
    font-family:Times New Roman,
    serif;
    font-style:normal;
    font-weight:normal;
    font-size:14px;
  }
  div.fs-text-container
  .fs-entry-date,
  div.fs-detail-container
  .fs-post-info,
  div.fs-wrapper
  div.fs-has-links::after,
  .fs-text-product,
  .fs-overlink-text
  {
    font-family:Helvetica Neue,
    Helvetica,
    Arial,
    sans-serif;
    font-style:normal;
    font-weight:normal;
    font-size:14px;
  }
  .fs-wrapper
  div.fs-text-container *
  {
    color:#ffffff;
  }
  .fs-wrapper
  div.fs-text-container
  {
    background-color:rgba(0, 0, 0, 0.8);
    margin:0px;
  }
  div.fs-entry-date
  {
    display:none;
  }
  div.fs-entry-title
  {
    display:none;
  }
  .fs-showcase_v2_5.fs-wrapper
  div.fs-timeline-entry
  {
    margin:1px;
  }
  </style>`;

