import { useMutation } from "react-query";
import { corporateSalesRecord } from "src/features/CorporateSale/utils/types";
import { customerApiClient } from "../api/client";

export type UseCorporateEventProps = {
  addRecord: corporateSalesRecord;
};
const mutationFn = async (opts: UseCorporateEventProps) => {
 
  return customerApiClient.url("/Klaviyo-event").post(opts).json();
};
export const UseKlaviyoCorporateEventMutation = () => {
  return useMutation(mutationFn);
};
