/* eslint-disable react/function-component-definition */
import { useState, useEffect, useRef } from "react";
import { NextPage } from "next";

import BloomsyLoader from "src/shared/components/BloomsyLoader";
import {
  UseAddRecordMutation,
  UseKlaviyoCorporateEventMutation,
} from "src/features/CorporateSale/hook";
import { useForm, DefaultValues } from "react-hook-form";
import tw from "twin.macro";
import { Button } from "src/shared/components/Button";
import FixedContainer from "src/shared/components/FixedContainer";
import {
  corporateSalesType,
  corporateSalesRecord,
} from "src/features/CorporateSale/utils/types";
import { useHookFormMask } from 'use-mask-input';
import ReCAPTCHA from "react-google-recaptcha";
import { reCaptchaApi } from "src/features/ContactUs/utils";
import { FaRegCheckCircle } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { useInView } from "react-intersection-observer";
import { useEntryObject } from "src/ui/components/BlockEmbeddedForm/state";

type FormData = {
  name: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  company: string;
  bouquets: string;
  budget: string;
  occassion: string;
  notes: string;
};

export const defaultValues: DefaultValues<FormData> = {
  name: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  company: "",
  bouquets: "",
  budget: "",
  occassion: "",
  notes: "",
};
const CorporateSalesForm: NextPage = () => {
  const { addEntryObject } = useEntryObject();
  const [inViewRef, inView, entry] = useInView({
    threshold: 0.3,
  });

  useEffect(() => {
    addEntryObject(entry);
  }, [addEntryObject, entry]);
  const emailTo = [{ email: "info@bloomsybox.com" }];
  const reRef = useRef<ReCAPTCHA>(null);

  const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || "";

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<FormData>();

  const registerWithMask = useHookFormMask(register);
  const mutation = UseAddRecordMutation();
  const klaviyoEventMutation = UseKlaviyoCorporateEventMutation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [iserror, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setmessageError] = useState("");
  useEffect(() => {
    if (isSuccess || iserror) {
      const timeout = setTimeout(() => {
        setIsSuccess(false);
        setIsError(false);
      }, 4000);

      return () => clearTimeout(timeout);
    }
  }, [isSuccess, iserror]);
  const getHTMLMessage = (formData: FormData) => `
        <div">
          <span style="font-weight: bold; font-size: 18px;">Name:</span>
          <span style="font-size: 17px;">${formData.name}</span>
            <br><br>
          <span style="font-weight: bold; font-size: 18px;">Last Name:</span>
          <span style="font-size: 17px;">${formData.lastName}</span>
             <br><br>
          <span style="font-weight: bold; font-size: 18px;">Email:</span>
          <span style="text-decoration: underline; text-underline-offset: 4px; color: blue; font-size: 17px;">${formData.email}</span>
              <br><br>
           <span style="font-weight: bold; font-size: 18px; ">Phone:</span>
           <span style="font-size: 17px; ">${formData.phoneNumber}</span>
              <br><br>
           <span style="font-weight: bold; font-size: 18px;">Company:</span>
           <span style="font-size: 17px;">${formData.company}</span>
              <br><br>
              <span style="font-weight: bold; font-size: 18px;">Bouquets:</span>
           <span style="font-size: 17px;">${formData.bouquets}</span>
              <br><br>
              <span style="font-weight: bold; font-size: 18px;">Budget:</span>
           <span style="font-size: 17px;">${formData.budget}</span>
              <br><br> 
          <span style="font-weight: bold; font-size: 18px;">Occassion:</span>
           <span style="font-size: 17px;">${formData.occassion}</span>
              <br><br>
              <span style="font-weight: bold; font-size: 18px;">Notes:</span>
           <span style="font-size: 17px;">${formData.notes}</span>
              <br><br>             
        </div>
      `;
  const onSubmit = async (formData: FormData) => {
    const token = reRef.current?.getValue();
    reRef.current?.reset();
    const reCaptchaRes = await reCaptchaApi
      .url("/reCaptcha")
      .post({ token })
      .json();
    if (reCaptchaRes.success) {
      setIsLoading(true);
      const addRecord: corporateSalesRecord = {
        name: formData.name,
        last_name: formData.lastName,
        email: formData.email,
        phone_number: formData.phoneNumber,
        company: formData.company,
        quantity_products: formData.bouquets,
        budget: formData.budget,
        occassion: formData.occassion,
        notes: formData.notes,
      };
      const emailNotification: corporateSalesType = {
        email: emailTo,
        message: getHTMLMessage(formData),
      };

       mutation.mutate(
        {
          addRecord,
        },
        {
          onSuccess: async (data) => { 
            klaviyoEventMutation.mutate({ addRecord });
            reset(defaultValues);
            setIsLoading(false);
            setIsSuccess(true);
             const res = fetch("/api/sendgrid/sendgrid", {
              method: "POST",
              body: JSON.stringify(emailNotification),
            }); 
           },
          onError: (err) => {
            setIsLoading(false);
            setmessageError("Your email could not be sent. Please try again.");
            setIsError(true);
          },
        }
      ); 
    } else {
      setmessageError("Please tick the recaptcha");
      setIsError(true);
    } 
  };

  return <>
    <div css={tw`flex justify-center`} ref={inViewRef}>
      {isSuccess && (
        <div
          css={tw`bg-white rounded-sm shadow-lg p-4 my-3 text-center bg-green-50 text-green-700 w-1/2 flex justify-center`}
        >
          <div css={tw`px-2`}>
            {" "}
            <FaRegCheckCircle />
          </div>
          Thank you for contacting us!
        </div>
      )}
      {iserror && (
        <div
          css={tw`bg-white rounded-sm shadow-lg p-4 my-3 text-center bg-red-100 text-red-600 w-1/2 flex justify-center`}
        >
          <div css={tw`px-2`}>
            {" "}
            <FiAlertTriangle />
          </div>
          {messageError}
        </div>
      )}
    </div>
    <FixedContainer css={tw`w-4/5 md:w-4/5 flex flex-wrap justify-center`}>
      <form
        css={tw`w-full flex flex-wrap justify-center my-12`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          css={tw`w-full md:w-2/5 flex flex-wrap justify-center text-center m-2`}
          {...register("name")}
          required
          placeholder="NAME"
          type="text"
        />
        <input
          css={tw`w-full md:w-2/5 flex flex-wrap justify-center text-center m-2`}
          {...register("lastName")}
          required
          placeholder="LAST NAME"
          type="text"
        />
        <input
          css={tw`w-full md:w-2/5 flex flex-wrap justify-center text-center m-2`}
          {...registerWithMask("email","email", {
            required: {
              value: true,
              message: "",
            },})}
          required
          placeholder="EMAIL"
          type="text"
          />
        <input
          css={tw`w-full md:w-2/5 flex flex-wrap justify-center text-center m-2`}
          {...registerWithMask("phoneNumber",'+19999999999')}
          
          required
          placeholder="PHONE NUMBER"
          type="text"
        />
        <input
          css={tw`w-full md:w-2/5 flex flex-wrap justify-center text-center m-2`}
          {...register("company")}
          required
          placeholder="COMPANY"
          type="text"
        />
        <input
          css={tw`w-full md:w-2/5 flex flex-wrap justify-center text-center m-2`}
          {...register("bouquets")}
          required
          placeholder="NUMBER OF BOUQUETS"
          type="text"
        />
        <input
          css={tw`w-full md:w-2/5 flex flex-wrap justify-center text-center m-2`}
          {...register("budget")}
          required
          placeholder="BUDGET"
          type="text"
          pattern="^(0|[1-9][0-9]*)$"
        />
        <input
          css={tw`w-full md:w-2/5 flex flex-wrap justify-center text-center m-2`}
          {...register("occassion")}
          placeholder="OCCASSION"
          type="text"
        />

        <textarea
          css={tw`w-full md:w-4/5 flex flex-wrap justify-center text-center m-2 h-24`}
          {...register("notes")}
          placeholder="notes"
        />
         <div css={tw`my-2 lg:mt-4 w-full md:w-4/5`}>
          {" "}
          <ReCAPTCHA css={tw`w-full`} sitekey={siteKey} ref={reRef} />{" "}
        </div> 

        <div css={tw`my-2 lg:mt-4 w-full md:w-2/3`}>
          <Button
            disabled={isLoading}
            size="small"
            rel="noopener"
            css={[tw`my-2 lg:mt-4 w-full lg:w-1/4`]}
            fullWidth
            type="submit"
          >
            {isLoading ? (
              <BloomsyLoader css={tw`w-4 h-4`} />
            ) : (
              <span>SEND</span>
            )}
          </Button>
        </div>
      </form>
    </FixedContainer>
  </>;
};
export default CorporateSalesForm;
