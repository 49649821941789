/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockProseFieldsFragment } from "src/generated/datocms-types";
import { useMediaQuery } from "@mui/material";

export type BlockProseProps = {
  data: BlockProseFieldsFragment;
};
export const BlockProse: FC<BlockProseProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const xlUp = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const isMobile = !isDesktop && !xlUp;
  const containerWidth = !isLargeDesktop ? "1200px" : "1360px";
  const { showOnMobileVersion = true, largeTypography = false } = data;
  const displayModule = isDesktop || (!isDesktop && showOnMobileVersion);
  if (!data.proseContent) return null;
  return (
    <FixedContainer
      CustomContainer
      css={[
        tw`mt-4`,
        isMobile
          ? tw`container px-4 sm:px-0`
          : [tw`mx-auto px-0`, { maxWidth: !xlUp ? "980px" : containerWidth }],
      ]}
      >
      {displayModule && (
        <div
          css={[
            !largeTypography
              ? tw`py-1 lg:py-10 prose prose-sm max-w-full px-0 sm:px-5 lg:px-0`
              : [
                  tw`bg-white text-left w-full prose prose-lg text-sm lg:text-base max-w-full font-roboto lg:leading-8`,
                  {
                    "&>h2": [
                      tw`font-normal`,
                      isDesktop ? { fontSize: "26px" } : { fontSize: "20px" },
                      { color: "#394151" },
                    ],
                  },
                ],
          ]}
          dangerouslySetInnerHTML={{ __html: data.proseContent }}
        />
      )}
    </FixedContainer>
  );
};
