/* eslint-disable react/function-component-definition */
import { FC, useCallback } from "react";
import {
  ShopifyMoneyV2,
  ShopifyProductJson,
  ShopifyVariantNode,
} from "src/shared/types/shopify";
import { IMAGE_QUALITY } from "src/constants";
import { formatShopifyPrice, getShopifyIdInt } from "src/shared/helpers";
import tw, { theme as twTheme } from "twin.macro";
import { ProductTag, ProductTagImage } from "src/shared/types";
import {
  slugsPrefixMap,
  freeShippingIcon,
} from "src/services/datocms/constants";
import { ProductFieldsShortFragment } from "src/generated/datocms-types";
import { dataLayer } from "src/features/Analytics";
import Image from "next/legacy/image";
import { ImageCard } from "src/shared/components/ImageCard";
import { useMediaQuery } from "@mui/material";
import { useRouter } from "next/router";
import { useBreadcrumbPath } from "src/features/ProductBreadcrumb/hooks";
import { getProductRecurringLabel } from "src/shared/utils/products";
import { copy } from "src/features/ProductForm/constants";

const productGridMap = {
  default: tw`w-[49%] lg:w-1/4`,
  secondGrid: tw`md:w-1/2`,
  flowerShopPage: tw`w-[49%] lg:w-1/4`,
};
type Props = {
  product: ProductFieldsShortFragment;
  hasSlider: boolean;
  gridType: string;
  totalProducts: number;
  isFlowerShopPage?: boolean;
};

export const FlowerShopCard: FC<Props> = ({
  product,
  hasSlider,
  gridType,
  totalProducts,
  isFlowerShopPage = false,
}) => {
  const router = useRouter();
  const { currentBreadcrumbPath, currentCollectionName } = useBreadcrumbPath();
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const isXl = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  let badgeTag: ProductTagImage | undefined;
  badgeTag = product.tags.find((t) => t.tagType === "Badge");
  const hasCollectionQueryP =
    currentBreadcrumbPath.length > 0 && currentCollectionName.length > 0;
  const collectionQueryP = hasCollectionQueryP
    ? `?collection_page=${currentBreadcrumbPath}&collection_title=${currentCollectionName.replace(
        / /g,
        "_"
      )}`
    : "";
  const hasDefaultGrid = gridType === "default";
  const classNameForSliderPage = hasSlider ? "keen-slider__slide" : "";
  const { isSubscription, gallery } = product;
  const productUrl = `${slugsPrefixMap.product}${product.slug}${collectionQueryP}`;
  const ProductList = isSubscription ? "Subscription" : "One-Time";
  const viewProductDetail = useCallback(
    async (evt) => {
      evt.preventDefault();
      const variant: ShopifyVariantNode =
        product.shopifyProduct?.variants.edges[0].node;
      const variantIdInt = getShopifyIdInt(variant?.id ?? "");
      // Products list - when a product is clicked
      dataLayer({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_name: product.title,
              item_id: variant.sku,
              price: variant.priceV2.amount,
              item_brand: "BloomsyBox",
              item_category: "",
              item_category2: "",
              item_variant: variant.title,
              item_list_name: ProductList,
              item_list_id: ProductList,
              index: 1,
              quantity: "1",
            },
          ],
        },
      });
      dataLayer({
        event: "viewProduct",
        ecommerce: {
          detail: {
            actionField: { list: "Flower Shop" },
            products: [
              {
                name: product.title,
                id: variantIdInt,
                price: variant.priceV2.amount,
                variant: variant.title,
              },
            ],
          },
        },
      });
      await router.push(productUrl).then(() => window.scrollTo(0, 0));
    },
    [
      product.shopifyProduct?.variants.edges,
      product.title,
      ProductList,
      router,
      productUrl,
    ]
  );
  if (!product) return null;
  const shopifyProduct: ShopifyProductJson = product.isSubscription
    ? product.shopifySubscriptionProducts[0]
    : product.shopifyProduct;
  if (!shopifyProduct) return null;
  const hasPrice = Boolean(shopifyProduct?.priceRange);
  if (!hasPrice) return null;
  const minPrice = formatShopifyPrice(
    shopifyProduct?.priceRange.minVariantPrice as ShopifyMoneyV2
  );
  const maxPrice = formatShopifyPrice(
    shopifyProduct?.priceRange.maxVariantPrice as ShopifyMoneyV2
  );
  const hasRangePrice = minPrice !== maxPrice;
  const salesPrice = formatShopifyPrice(
    shopifyProduct.compareAtPriceRange?.minVariantPrice
  );
  const hasSalesPrice =
    Number(shopifyProduct.compareAtPriceRange?.minVariantPrice.amount) > 0;

  const squareCard = product.cardType === "square";
  const subscriptionImage = gallery[1] ?? gallery[0];
  const subscriptionSecondImage = gallery[0];
  const otSecondImage = gallery[1] ?? gallery[0];

  const firstImage = isSubscription ? subscriptionImage : gallery[0];
  const image = isSubscription ? subscriptionSecondImage : otSecondImage;

  const smallerDeskDimensions = isXl ? 300 : 400;
  const smallerDeskDimensionsH = isXl ? 280 : 360;
  const productImageSize = {
    w: isLargeDesktop ? 333 : smallerDeskDimensions,
    h: isLargeDesktop ? 331 : smallerDeskDimensionsH,
  };
  const productImageSizeMobile = {
    w: hasDefaultGrid ? 409 : 650,
    h: squareCard ? 606 : 492,
  };
  const imagemobileSrc = `${firstImage.url}?h=400&w=300&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`;
  const productImage = [
    {
      src: `${firstImage.url}?w=${productImageSize.w}&h=${productImageSize.h}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`,
      width: productImageSize.w,
      height: productImageSize.h,
      alt: `${firstImage.alt}`,
    },
    {
      src: `${image.url}?w=${productImageSize.w}&h=${productImageSize.h}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`,
      width: productImageSize.w,
      height: productImageSize.h,
      alt: `${image.alt}`,
    },
  ];
  const gridTypeValue = isFlowerShopPage ? gridType : "flowerShopPage";
  const gridProductStyle =
    productGridMap[gridTypeValue as keyof typeof productGridMap];

  const labelContainerStyle = tw`py-0.5 sm:py-2 w-auto px-2 sm:px-5`;
  const lableTitleStyle = [{ fontSize: "11px" }, tw`uppercase font-roboto`];
  const hasFreeShipping = product.tags?.find((tag: ProductTag) => {
    return tag?.slug?.includes(freeShippingIcon);
  });
  const recurringFrequency = getProductRecurringLabel(
    product.title ?? "",
    product.tags ?? ""
  );

  const freeShippingCopy = copy.freeShipping.toLowerCase() ?? "";
  if (product.disableDatePicker) return null;
  return (
    <div
      css={[tw`bg-white flex lg:px-0.5 md:mb-8`, gridProductStyle]}
      className={classNameForSliderPage}
    >
      <div css={tw`relative rounded my-4 md:my-2`}>
        <a
          href={productUrl}
          onClick={viewProductDetail}
          css={tw`block relative`}
        >
          {isDesktop ? (
            <ImageCard productImage={productImage} />
          ) : (
            <div css={tw`relative`}>
              <Image
                css={tw`object-cover`}
                src={imagemobileSrc}
                width={productImageSizeMobile.w}
                height={productImageSizeMobile.h}
                quality={IMAGE_QUALITY}
                layout="intrinsic"
                alt={productImage[0].alt}
              />
            </div>
          )}
          {badgeTag && (
            <div
              css={[
                tw` flex absolute bg-white rounded-sm items-center`,
                labelContainerStyle,
                {
                  backgroundColor: badgeTag.backgroundColor?.hex || "#FFFFFF",
                  bottom: "15px",
                  left: "15px",
                },
              ]}
            >
              <div>
                <p
                  css={[
                    lableTitleStyle,
                    { color: badgeTag.textColor?.hex || "#000000" },
                  ]}
                >
                  {badgeTag?.tagMessage ?? badgeTag?.title}
                </p>
              </div>
            </div>
          )}
        </a>
        {hasFreeShipping && (
          <div css={tw`capitalize leading-7 pt-1 w-full text-left`}>
            <span css={[tw`font-roboto block`, { color: "#41A317" }]}>
              {freeShippingCopy}
            </span>
          </div>
        )}
        <div
          css={[
            tw`flex flex-col w-full text-left`,
            tw`h-auto`,
            !hasFreeShipping ? tw`pt-1` : tw`mt-0.5`,
          ]}
        >
          <p
            css={tw`text-base font-roboto hover:text-bloomsy-red w-full text-left`}
          >
            <a href={productUrl} onClick={viewProductDetail}>
              {product.title}
            </a>
          </p>
          {hasSalesPrice ? (
            <div>
              <p css={tw`text-base font-roboto space-x-2 w-full text-left`}>
                <span>{minPrice}</span>
                <span css={tw`line-through text-base text-gray-400`}>
                  {salesPrice}
                </span>
                {product.isSubscription &&
                  isDesktop &&
                  (recurringFrequency.length ?? 0) > 0 && (
                    <span css={tw`lowercase text-base`}>
                      /{recurringFrequency}
                    </span>
                  )}
              </p>
              {product.isSubscription && (
                <div css={tw`text-base font-roboto w-full text-left`}>
                  {hasSalesPrice && !isDesktop && (
                    <span css={[tw`block lowercase text-base`]}>
                      {recurringFrequency}
                    </span>
                  )}
                </div>
              )}
            </div>
          ) : (
            <p
              css={[
                tw`text-base font-roboto`,
                hasRangePrice && !isSubscription && tw`text-start`,
              ]}
            >
              {hasRangePrice && !isSubscription ? (
                <span>
                  {minPrice} - {maxPrice}
                </span>
              ) : (
                <span>{minPrice}</span>
              )}
              {product.isSubscription &&
                !hasSalesPrice &&
                (recurringFrequency.length ?? 0) > 0 && (
                  <span css={tw`lowercase text-base`}>
                    /{recurringFrequency}
                  </span>
                )}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
