/* eslint-disable react/function-component-definition */
import React, { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";
import { IMAGE_QUALITY } from "src/constants";
import { CardData } from "../ProductCenterPage/types";

type Props = {
  cardData: CardData;
};
export const Card: FC<Props> = ({ cardData }) => {
  const {
    name,
    imageUrl,
    slug,
    slugsPrefix,
    alt,
    hasBadge,
    badge,
    product,
  } = cardData;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const isXl = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const smallerDeskDimensions = isXl ? 300 : 400; 
  const smallerDeskDimensionsH = isXl ? 280 : 360; 
  const productImageSize = {
    w: isLargeDesktop ? 333 : smallerDeskDimensions,
    h: isLargeDesktop ? 331 : smallerDeskDimensionsH,
  };
  const imageSize = {
    w: isDesktop ? 600 : 300,
    h: isDesktop ? 600 : 300,
  };
  const image = {
    src: `${product?.gallery[0]?.url ?? imageUrl}?w=${imageSize.w}&h=${imageSize.h}`,
    width: imageSize.w,
    height: imageSize.h,
    alt: product?.gallery[0].alt ?? alt,
  };
  const badgeSrc = {
    src: `${badge}?w=200$h=400`,
    width: 50,
    height: 50,
    alt,
  };
  return (
    <article
      css={tw`bg-white w-1/4 lg:(my-4) lg:px-0.5`}
    >
      <div css={tw`relative rounded my-4 md:my-2`}>
      <a href={`${slugsPrefix}${slug}`} rel="noreferrer">
            <div css={tw`relative`}>
              <Image
                css={tw`object-cover`}
                src={image.src}
                width={productImageSize.w}
                height={productImageSize.h}
                quality={IMAGE_QUALITY}
                layout="intrinsic"
                alt={image.alt}
              />
            </div>
          {hasBadge && badge && (
            <div
              css={[
                tw`flex absolute top-14 rounded-full z-10`,
                {
                  right: "20px",
                  top: "20px",
                  width: "100px",
                  height: "100px",
                },
              ]}
            >
              <Image
                src={badgeSrc.src || ""}
                layout="fill"
                unoptimized
                alt=""
              />
            </div>
          )}

      <div
          css={[
            tw`flex flex-wrap w-full text-left`,
            tw`h-auto`,
          ]}
        >
          <p
            css={tw`text-base font-roboto hover:text-bloomsy-red w-full text-left`}
          >
              {product?.title ?? name}
           
          </p>
        </div>
        </a>
        </div>
    </article>
  );
};
