/* eslint-disable react/no-unescaped-entities */
import { FC, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { BlockFeatTestimonialsCompaniesSliderFieldsFragment } from "src/generated/datocms-types";
import { useKeenSlider } from "keen-slider/react";
import FixedContainer from "src/shared/components/FixedContainer";
import { TestimonialCard } from "src/ui/components/BlockFeatTestimonialCompanySlider/TestimonialCard";
import tw, { theme as twTheme } from "twin.macro";

export type BlockTestimonialCompanySliderprops = {
  data: BlockFeatTestimonialsCompaniesSliderFieldsFragment;
};

export const BlockTestimonialCompanySlider: FC<BlockTestimonialCompanySliderprops> =
  ({ data: { content } }) => {
    const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
      slidesPerView: 1,
      spacing: 1,
      loop: true,
      initial: 0,
      slideChanged(s) {
        setCurrentSlide(s.details().relativeSlide);
      },
    });

    const styles = [
      isDesktop ? tw` w-16` : tw`w-12`,
      tw`absolute top-1/3 cursor-pointer fill-current text-gray-400`,
    ];
    return (
      <FixedContainer>
        <div>
          <div ref={sliderRef} className="keen-slider">
            {content &&
              content.map((testimonial) => {
                if (!testimonial) return null;
                return (
                  <div className="keen-slider__slide" key={testimonial?.id}>
                    <TestimonialCard data={testimonial} />
                  </div>
                );
              })}
          </div>
          {slider && (
            <>
              <div css={[styles, { right: isDesktop ? "12%" : "0" }]}>
                <svg onClick={() => slider.next()} viewBox="0 0 100 125">
                  <path d="M66.254,46.947L38.916,14.152c-1.131-1.359-3.154-1.543-4.512-0.408c-1.361,1.137-1.545,3.152-0.412,4.512L59.621,49   L33.992,79.742c-1.131,1.363-0.947,3.379,0.412,4.514c1.357,1.135,3.381,0.951,4.512-0.408l27.338-32.793   C66.752,50.459,67,49.729,67,49C67,48.27,66.752,47.541,66.254,46.947z" />
                </svg>
              </div>
              <div css={[styles, { left: isDesktop ? "12%" : "0" }]}>
                <svg onClick={() => slider.prev()} viewBox="0 0 100 125">
                  <path d="M66.006,82.742L40.377,52l25.629-30.742c1.133-1.361,0.947-3.379-0.41-4.514s-3.381-0.951-4.514,0.408L33.744,49.947   C33.246,50.541,33,51.271,33,52c0,0.73,0.246,1.459,0.744,2.053l27.338,32.795c1.133,1.359,3.156,1.543,4.514,0.408   C66.957,86.119,67.141,84.105,66.006,82.742z" />
                </svg>
              </div>
            </>
          )}
        </div>
      </FixedContainer>
    );
  };
