/* eslint-disable react/function-component-definition */
import { FC, useEffect, useState } from "react";
import { BlockFeatureReviewCollectionFieldsFragment } from "src/generated/datocms-types";
import tw, { theme as twTheme } from "twin.macro";
import { useKeenSlider } from "keen-slider/react";
import { useMediaQuery } from "@mui/material";
import { FeatureTestimonial } from "src/ui/components/BlockFeatureTestimonial/FeatureTestimonial";

export type BlockFeatureReviewProps = {
  data: BlockFeatureReviewCollectionFieldsFragment;
};

export const FeatureReview: FC<BlockFeatureReviewProps> = ({
  data: { reviewCollection },
}) => {
  const xlUp = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const hasCollection = reviewCollection.length > 1;
  const classNameForSlider = hasCollection ? "keen-slider" : "";
  const classNameForSliderPage = hasCollection ? "keen-slider__slide" : "";
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    loop: true,
    slidesPerView: 1,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
  });

  useEffect(() => {
    if (slider) slider.refresh();
  }, [slider]);

  return (
    <div css={tw`z-10`}>
      <div ref={slideRef} className={classNameForSlider}>
        {reviewCollection?.map((sliderPage) => {
          const {
            hasDefaultCardPerView,
            threeCardPerViewContent,
            sixCardPerViewContent,
            cardsColor,
            cardType,
          } = sliderPage;
          const cardPerView = xlUp ? threeCardPerViewContent : threeCardPerViewContent.slice(0,3)
          return (
            <div
              key={sliderPage.id}
              className={classNameForSliderPage}
              css={tw`flex flex-wrap justify-center`}
            >
              {hasDefaultCardPerView
                ? cardPerView.map((record) => {
                    if (!record) return null;
                    return (
                      <FeatureTestimonial
                        data={record}
                        key={record.id}
                        cardsColor={cardsColor || ""}
                        cardType={cardType || ""}
                      />
                    );
                  })
                : sixCardPerViewContent.map((record) => {
                    if (!record) return null;
                    return (
                      <FeatureTestimonial
                        data={record}
                        key={record.id}
                        cardsColor={cardsColor || ""}
                        cardType={cardType || ""}
                      />
                    );
                  })}
            </div>
          );
        })}
      </div>
      {slider && hasCollection && (
        <div css={tw`flex justify-center py-0 space-x-4`}>
          {/* @ts-ignore */}
          {[...Array(slider.details().size).keys()].map((idx) => {
            return (
              <button
                css={[
                  tw`w-2.5 h-2.5 mx-1 mt-2 bg-bloomsy-gray50 block rounded-full lg:(w-2 h-2) focus:outline-none`,
                  currentSlide === idx && tw`bg-bloomsy-midgray`,
                ]}
                type="button"
                key={idx}
                onClick={() => {
                  slider.moveToSlideRelative(idx);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};
