/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import { BlockFeatureTextImageModuleRecord } from "src/generated/datocms-types";
import Image from "next/legacy/image";
import { IMAGE_QUALITY, SITE_URL } from "src/constants";
import FixedContainer from "src/shared/components/FixedContainer";
import { useMediaQuery } from "@mui/material";
import NextLink from "next/link";

export type BlockFeatureTextImageProps = {
  data: BlockFeatureTextImageModuleRecord;
};

export const BlockFeatureTextImage: FC<BlockFeatureTextImageProps> = ({
  data: { title, image, isMiniBanner, pageLink, imageMobile },
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const imageSize = {
    w: isDesktop ? 950 : 500,
    h: isDesktop ? 400 : 250,
  };

  let imageUrl = "";
  let imageAlt = "";
  if (!isMiniBanner || (isMiniBanner && isDesktop)) {
    imageUrl = image?.url ?? "";
    imageAlt = image?.alt ?? "";

    if (isMiniBanner && isDesktop) {
      imageSize.h = 100;
      imageUrl = imageUrl.concat("?w=3600&h=200");
    }
  } else if (isMiniBanner && !isDesktop) {
    imageSize.h = 150;
    imageUrl = imageMobile?.url ?? "";
    imageAlt = imageMobile?.alt ?? "";
  }

  const pageLinkUrl = (
    pageLink && pageLink.length > 0 && pageLink[0].link
      ? pageLink[0].link.slug
      : ""
  ) as string;
  const modelApiKey = (
    pageLink && pageLink.length > 0 && pageLink[0].link
      ? pageLink[0].link._modelApiKey
      : ""
  ) as string;
  const pathLink = modelApiKey === "product" ? "/p" : "";

  return isMiniBanner ? (
    <div>
      <div css={[tw`flex items-center w-full mb-1`]}>
        <div css={[tw`relative w-full`, { height: imageSize.h }]}>
          <NextLink href={`${SITE_URL}${pathLink}/${pageLinkUrl}`} passHref>
            {imageUrl.length > 0 && (
              <Image
                src={imageUrl}
                layout="fill"
                quality={IMAGE_QUALITY}
                alt={imageAlt}
                objectFit="cover"
              />
            )}
          </NextLink>
        </div>
      </div>
    </div>
  ) : (
    <FixedContainer css={tw`px-5`}>
      <div
        css={[
          !isDesktop && tw`text-center`,
          tw`flex flex-wrap justify-center lg:justify-start`,
        ]}
      >
        <div
          css={[
            tw`relative w-full h-full my-4 w-full`,
            { height: imageSize.h },
          ]}
        >
          <Image
            css={tw`object-cover`}
            src={imageUrl}
            layout="fill"
            quality={IMAGE_QUALITY}
            alt={imageAlt}
          />
        </div>
        <span css={tw`font-roboto text-base`}>{title}</span>
      </div>
    </FixedContainer>
  );
};
