/* eslint-disable react/jsx-no-useless-fragment */
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import Image from "next/legacy/image";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureImageTextFieldsFragment } from "src/generated/datocms-types";
import { IMAGE_QUALITY } from "src/constants";
import { BigImageComponent } from "./BigImageComponent";

export type BlockImageTextProps = {
  data: BlockFeatureImageTextFieldsFragment;
};

// eslint-disable-next-line react/function-component-definition
export const BlockImageText: FC<BlockImageTextProps> = ({ data }) => {
  const {
    title,
    backgroundImage,
    mobileImage,
    hasBigText,
    leftBulletPoints,
    rightBulletPoints,
  } = data;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);

  const renderImage = (
    image:
      | BlockFeatureImageTextFieldsFragment["backgroundImage"]
      | BlockFeatureImageTextFieldsFragment["mobileImage"]
  ) => {
    const bigImageSize = { h: !isDesktop ? 900 : 1800 };
    const bigTextImageSize = { h: !isDesktop ? 400 : 700 };
    const imageSize = {
      h: hasBigText ? bigTextImageSize.h : bigImageSize.h,
    };
    const imageSrc = `${image?.url}?h=${imageSize.h}&crop=focalpoint&fit=crop&fp-x=0.4`;

    return (
      <div css={[tw`relative`, { height: imageSize.h }]}>
        <Image
          css={tw`object-cover xl:object-contain`}
          src={imageSrc}
          layout="fill"
          quality={IMAGE_QUALITY}
          alt={image?.alt ?? ""}
        />
      </div>
    );
  };
  const renderBgImage = () => {
    if (mobileImage?.responsiveImage && !isDesktop) {
      return renderImage(mobileImage);
    }
    return renderImage(backgroundImage);
  };
  return <>
    {hasBigText ? (
      <BigImageComponent data={data} />
    ) : (
      <div
        css={[
          tw`w-full h-auto max-w-largeScreenContainer mx-auto relative overflow-hidden bg-white`,
          { backgroundColor: "#f4f4f4" },
        ]}
      >
        {renderBgImage()}
        <div
          css={[
            tw`w-full h-auto`,
            !isDesktop
              ? [tw`py-8 relative  sm:absolute  `, { top: "0%" }]
              : [
                  tw`absolute`,
                  {
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                  },
                ],
          ]}
        >
          {!isDesktop ? (
            <>
              <FixedContainer css={[tw`w-full h-auto lg:mt-4 xl:mt-0`]}>
                <h2
                  css={tw`font-playfair text-2xl  text-left sm:text-center`}
                >
                  {title}
                </h2>
              </FixedContainer>
              <FixedContainer css={[tw`flex flex-wrap`]}>
                <div css={[tw`flex flex-wrap justify-between`]}>
                  {leftBulletPoints.length > 0 && (
                    <div css={tw`w-full sm:w-5/12 sm:items-end`}>
                      <div>
                        <div
                          css={tw`sm:flex sm:flex-wrap sm:justify-start sm:text-right sm:space-y-36 md:space-y-12 sm:mt-12 md:mt-28 md:pt-20`}
                        >
                          {leftBulletPoints.map((bp) => {
                            const mobileDescription = bp.description?.replace(
                              /<br ?\/?>/g,
                              " "
                            );
                            return (
                              <div
                                key={bp.id}
                                css={tw`w-full sm:w-4/6 md:p-4 md:w-4/5`}
                              >
                                <div css={tw`pt-4`}>
                                  <h3 css={tw`font-roboto font-bold text-lg`}>
                                    {bp.title}
                                  </h3>
                                  <div
                                    css={tw`font-roboto text-sm pt-4 md:pt-2`}
                                    dangerouslySetInnerHTML={{
                                      __html: mobileDescription || "",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                  {rightBulletPoints.length > 0 && (
                    <div css={tw`flex w-full  sm:w-5/12 sm:items-end `}>
                      <div>
                        <div
                          css={tw`sm:flex sm:flex-wrap sm:justify-end text-left sm:space-y-36 md:space-y-36`}
                        >
                          {rightBulletPoints.map((bp) => {
                            const mobileDescription = bp.description?.replace(
                              /<br ?\/?>/g,
                              " "
                            );
                            return (
                              <div
                                key={bp.id}
                                css={tw`w-full sm:w-4/6 md:p-4 md:w-4/5`}
                              >
                                <div css={tw`pt-4`}>
                                  <h3 css={tw`font-roboto font-bold text-lg`}>
                                    {bp.title}
                                  </h3>
                                  <div
                                    css={tw`font-roboto text-sm pt-4 sm:pt-2`}
                                    dangerouslySetInnerHTML={{
                                      __html: mobileDescription || "",
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </FixedContainer>
            </>
          ) : (
            <FixedContainer css={[tw`flex flex-wrap mx-auto`]}>
              <div
                css={tw`w-full relative xl:absolute xl:(-top-10 w-1/2 left-1/4) `}
              >
                <h2 css={tw`font-playfair text-lg lg:text-4xl text-center`}>
                  {title}
                </h2>
              </div>
              <div
                css={[
                  tw`flex justify-between mt-32 xl:mt-2`,
                  { height: 1200 },
                ]}
              >
                {leftBulletPoints.length > 0 && (
                  <div css={tw`flex items-end w-5/12`}>
                    <div>
                      <div css={tw`text-right space-y-28 p-4`}>
                        {leftBulletPoints.map((bp) => {
                          return (
                            <div key={bp.id} css={tw`w-4/5`}>
                              <div css={tw`px-12`}>
                                <h3 css={tw`font-roboto font-bold text-xl`}>
                                  {bp.title}
                                </h3>
                                <div
                                  css={tw`font-roboto text-base mt-6`}
                                  dangerouslySetInnerHTML={{
                                    __html: bp.description || "",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
                {rightBulletPoints.length > 0 && (
                  <div css={tw` flex items-end w-full w-5/12`}>
                    <div>
                      <div css={tw`text-left space-y-96 px-4`}>
                        {rightBulletPoints.map((bp) => {
                          return (
                            <div key={bp.id} css={tw`w-4/5`}>
                              <div css={tw`px-12`}>
                                <h3 css={tw`font-roboto font-bold text-xl`}>
                                  {bp.title}
                                </h3>
                                <div
                                  css={tw`font-roboto text-base mt-6`}
                                  dangerouslySetInnerHTML={{
                                    __html: bp.description || "",
                                  }}
                                />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </FixedContainer>
          )}
        </div>
      </div>
    )}
  </>;
};
