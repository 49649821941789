import { ImageTitleBackgroundColorRecordContentFragment } from "src/generated/datocms-types";
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { useMediaQuery } from "@mui/material";

type ImageTitleProps = {
  data: ImageTitleBackgroundColorRecordContentFragment;
};
export const ImageTitle: FC<ImageTitleProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const BackgroundColor = data?.backgroundColor?.hex || "#FFFFFF";

  const imageSrc = `${data.image?.url}?w=400&dpr=2`;
  const imageSize = [!isDesktop ? { height: 250 } : { height: 400 }];
  return (
    <div
      css={[
        { backgroundColor: BackgroundColor },
        tw`w-full  relative overflow-hidden pb-4 mx-4 mt-8 lg:(w-5/12 h-full mt-0) shadow-md rounded`,
      ]}
    >
      <div css={[tw`relative w-full bg-transparent `, imageSize]}>
        <Image
          css={tw`object-cover`}
          src={imageSrc}
          layout="fill"
          unoptimized
          alt={data.image?.alt ?? ""}
        />
      </div>
      <p
        css={tw`block mt-4 font-roboto tracking-wider uppercase text-sm  text-bloomsy-darkgray text-center`}
      >
        {data.title}
      </p>
    </div>
  );
};
