/* eslint-disable no-console */
/* eslint-disable react/no-array-index-key */
import {
  Drawer,
  IconButton,
  Accordion as AccordionMaterialUI,
  AccordionSummary as AccordionSummaryMaterialUI,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCurrentFilterSelection } from "src/features/Cart/state";
import { ProductFieldsShortFragment } from "src/generated/datocms-types";
import { withStyles } from "tss-react/mui";
import { FC, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import tw, { styled } from "twin.macro";
import { FiChevronDown } from "react-icons/fi";
import { FilterProduct } from "../types";
import BloomsyLoader from "./BloomsyLoader";
import { Button } from "./Button";
import { getFilterListForProductList, setFilterList } from "../utils/products";

const StyledDrawer = styled(Drawer)([
  { ".MuiDrawer-paper": tw`w-full overflow-x-hidden` },
]);

const FILTER_TYPES = {
  COLOR: "Color",
  PRICE: "Price",
  SIZE: "Plant Size",
};

type Props = {
  filterOptions: FilterProduct[];
  allProducts: ProductFieldsShortFragment[];
  openFilterDrawer: boolean;
  handleClickOpenFilterDrawer: () => void;
};

type FilterAccordeonProps = {
  filterOptions: FilterProduct[];
  filterProducts: FilterProduct[];
  filterDisabledOptions: FilterProduct[];
  handleClickCheckSelect: (filter: FilterProduct) => void;
};

type AccordionDetailsOptionProps = {
  type: string;
  filterOptions: FilterProduct[];
  filterProducts: FilterProduct[];
  filterDisabledOptions: FilterProduct[];
  handleClickCheckSelect: (filter: FilterProduct) => void;
};

const Accordion = withStyles(AccordionMaterialUI, {
  root: {
    boxShadow: "none",
    "&::before": {
      backgroundColor: "#D2D5DB",
      transition: "none",
    },
    "&.Mui-expanded": {
      margin: "0px",
    },
  },
});

const AccordionSummary = withStyles(AccordionSummaryMaterialUI, {
  root: {
    borderBottom: "1px solid #D2D5DB",
    "&.Mui-expanded": {
      minHeight: "48px",
      borderBottom: "0px solid #D2D5DB",
    },
  },

  content: {
    "&.Mui-expanded": {
      margin: "0px",
    },
  },
});

const AccordionDetailsOptionColor: FC<AccordionDetailsOptionProps> = ({
  type,
  filterOptions,
  filterProducts,
  filterDisabledOptions,
  handleClickCheckSelect,
}) => {
  return (
    <div css={tw`grid grid-cols-2 gap-4`}>
      {filterOptions
        .filter((f) => f.type === type)
        .map((filter, index) => {
          const isSelected = filterProducts?.some(
            (s) => s.tagId.toString() === filter.tagId.toString()
          );
          const isDisabled = filterDisabledOptions.some(
            (s) => s.tagId === filter.tagId
          );

          return (
            <a
              href={undefined}
              onClick={() => {
                if (!isDisabled) handleClickCheckSelect(filter);
              }}
              key={index}
              css={tw`flex items-center gap-3`}
            >
              <div
                css={[
                  tw`w-[25px] h-[25px] rounded-full bg-gray-500 border border-gray-300 transition duration-300`,
                  { backgroundColor: filter.color },
                  isSelected &&
                    !isDisabled &&
                    tw`w-[29px] h-[29px] border-[3px] border-gray-600`,
                  isDisabled && tw`opacity-40`,
                ]}
              />
              <div>
                <span
                  css={[
                    tw`text-sm leading-7 tracking-wider font-roboto text-bloomsy-darkgray capitalize`,
                    isSelected && tw`underline underline-offset-2`,
                    isDisabled && tw`text-gray-400`,
                  ]}
                >
                  {filter.filterName}
                </span>
              </div>
            </a>
          );
        })}
    </div>
  );
};

const AccordionDetailsOptionCheck: FC<AccordionDetailsOptionProps> = ({
  type,
  filterOptions,
  filterProducts,
  filterDisabledOptions,
  handleClickCheckSelect,
}) => {
  return (
    <FormGroup>
      {filterOptions
        .filter((f) => f.type === type)
        .sort((a, b) => a.priority - b.priority)
        .map((filter, index) => {
          const isSelected = filterProducts?.some(
            (s) => s.tagId.toString() === filter.tagId.toString()
          );
          const isDisabled = filterDisabledOptions.some(
            (s) => s.tagId === filter.tagId
          );

          return (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  onChange={() => handleClickCheckSelect(filter)}
                  size="large"
                  color="default"
                  {...(isDisabled
                    ? {
                        disabled: true,
                        sx: {
                          color: grey[300],
                        },
                      }
                    : {})}
                  checked={!!isSelected}
                />
              }
              css={tw`text-sm leading-7 tracking-wider font-roboto text-bloomsy-darkgray`}
              label={
                <span css={[tw`capitalize`, isDisabled && tw`text-gray-400`]}>
                  {filter.filterName}
                </span>
              }
            />
          );
        })}
    </FormGroup>
  );
};

const FilterAccordion: FC<FilterAccordeonProps> = ({
  filterOptions,
  filterProducts,
  filterDisabledOptions,
  handleClickCheckSelect,
}) => {
  const [activeAccordion, setActiveAccordion] = useState<string>("");
  const ORDER_FILTER_TYPE = {
    Color: 1,
    Price: 2,
  };

  const handleChangeAccordion = (
    e: React.SyntheticEvent<EventTarget>,
    isExpanded: boolean,
    type: string
  ) => {
    setActiveAccordion(() => {
      return isExpanded ? type : "";
    });
  };

  const isActiveAccordion = (type: string) => {
    return activeAccordion === type;
  };

  const filterTypeOptions: string[] = [];
  filterOptions
    .sort(
      (a, b) =>
        ORDER_FILTER_TYPE[a.type as keyof typeof ORDER_FILTER_TYPE] -
        ORDER_FILTER_TYPE[b.type as keyof typeof ORDER_FILTER_TYPE]
    )
    .forEach((filter) => {
      if (!filterTypeOptions.includes(filter.type))
        filterTypeOptions.push(filter.type);
    });

  return (
    <>
      {filterTypeOptions.map((type) => {
        return (
          <Accordion
            key={type}
            css={tw`w-full`}
            onChange={(e, isExpanded) => {
              handleChangeAccordion(e, isExpanded, type);
            }}
            expanded={isActiveAccordion(type)}
          >
            <AccordionSummary
              expandIcon={
                <FiChevronDown
                  css={[tw`font-bold text-base`, { color: "#4F5561" }]}
                  strokeWidth={5}
                />
              }
              css={tw`font-bold text-bloomsy-darkgray text-[15.5px] font-roboto uppercase leading-7 tracking-wider`}
            >
              {type}
            </AccordionSummary>
            <AccordionDetails>
              {type === FILTER_TYPES.COLOR && (
                <AccordionDetailsOptionColor
                  type={FILTER_TYPES.COLOR}
                  filterOptions={filterOptions}
                  filterProducts={filterProducts}
                  filterDisabledOptions={filterDisabledOptions}
                  handleClickCheckSelect={handleClickCheckSelect}
                />
              )}
              {type === FILTER_TYPES.PRICE && (
                <AccordionDetailsOptionCheck
                  type={FILTER_TYPES.PRICE}
                  filterOptions={filterOptions}
                  filterProducts={filterProducts}
                  filterDisabledOptions={filterDisabledOptions}
                  handleClickCheckSelect={handleClickCheckSelect}
                />
              )}
              {type === FILTER_TYPES.SIZE && (
                <AccordionDetailsOptionCheck
                  type={FILTER_TYPES.SIZE}
                  filterOptions={filterOptions}
                  filterProducts={filterProducts}
                  filterDisabledOptions={filterDisabledOptions}
                  handleClickCheckSelect={handleClickCheckSelect}
                />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </>
  );
};

export const FilterAndSortDrawer: FC<Props> = ({
  filterOptions,
  allProducts,
  openFilterDrawer,
  handleClickOpenFilterDrawer,
}) => {
  const { currentFilterSelection, addCurrentFilterSelection } =
    useCurrentFilterSelection();
  const [filterProducts, setFilterProducts] = useState<FilterProduct[]>([]);
  const [filterDisabledOptions, setFilterDisabledOptions] = useState<
    FilterProduct[]
  >([]);
  const [isBusy, setIsBusy] = useState(false);

  const getDisabledFilterOptions = (
    filterProductsSelected: FilterProduct[]
  ) => {
    if (filterProductsSelected && filterProductsSelected.length > 0) {
      let filterOptionsReview: FilterProduct[] = [];
      const filterProductsresultTypes = Array.from(
        new Set(filterProductsSelected.map((m) => m.type) ?? [])
      );

      if (filterProductsresultTypes.length === 1)
        filterOptionsReview = filterOptions.filter(
          (f) => f.type !== filterProductsresultTypes[0]
        );
      else if (filterProductsresultTypes.length > 1)
        filterOptionsReview = filterOptions.filter(
          (f) => !filterProductsSelected.some((s) => s.tagId === f.tagId)
        );

      const productFiltered = setFilterList(
        allProducts,
        filterProductsSelected
      );
      const filterOptDisabled = getFilterListForProductList(
        productFiltered as ProductFieldsShortFragment[],
        filterOptionsReview
      );
      const filterOptDisabledResult = [...filterOptDisabled];
      setFilterDisabledOptions(filterOptDisabledResult);
    } else setFilterDisabledOptions([]);
  };

  const handleClickCheckSelect = (filter: FilterProduct) => {
    const exist = filterProducts?.some((s) => s.tagId === filter.tagId);
    let filterProductsResult: FilterProduct[] = [];

    if (!exist) {
      filterProductsResult = [...(filterProducts ?? []), filter];
      setFilterProducts(filterProductsResult);
    } else {
      const removeFilterProducts = filterProducts?.filter(
        (f) => f.tagId !== filter.tagId
      );
      filterProductsResult = [...(removeFilterProducts ?? [])];
      setFilterProducts(filterProductsResult);
    }

    getDisabledFilterOptions(filterProductsResult);
  };

  const handleClickApplyFilter = () => {
    setIsBusy(true);
    setTimeout(() => {
      addCurrentFilterSelection(
        filterProducts.length > 0 ? filterProducts : undefined
      );
      handleClickOpenFilterDrawer();
      setIsBusy(false);
    }, 300);
  };

  const handleClickClearAll = () => {
    addCurrentFilterSelection(undefined);
    setFilterProducts([]);
    setFilterDisabledOptions([]);
    handleClickOpenFilterDrawer();
  };

  useEffect(() => {
    setFilterProducts([...(currentFilterSelection ?? [])]);

    getDisabledFilterOptions(currentFilterSelection ?? []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilterSelection, openFilterDrawer]);

  useEffect(() => {
    // remove filter options selected, if it is disabled too
    const removeFilterProducts = filterProducts?.filter(
      (f) => !filterDisabledOptions.some((s) => s.tagId === f.tagId)
    );

    if (removeFilterProducts.length > 0)
      setFilterProducts(removeFilterProducts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDisabledOptions, openFilterDrawer]);

  return (
    <div css={tw`md:hidden`}>
      <StyledDrawer open={openFilterDrawer} anchor="left">
        <nav
          css={tw`flex items-center border-b border-[#D2D5DB] h-16 font-roboto`}
        >
          <div css={tw`flex justify-center items-center w-[88%]`}>
            <span
              css={tw`ml-[15%] text-bloomsy-darkgray font-bold text-[15.5px] leading-4 tracking-wider`}
            >
              FILTERS
            </span>
          </div>
          <div css={tw`flex justify-end items-center`}>
            <IconButton onClick={handleClickOpenFilterDrawer} size="large">
              <IoClose size={28} />
            </IconButton>
          </div>
        </nav>
        <div>
          <FilterAccordion
            filterOptions={filterOptions}
            filterProducts={filterProducts}
            filterDisabledOptions={filterDisabledOptions}
            handleClickCheckSelect={handleClickCheckSelect}
          />
          <div css={tw`mt-5 p-6 font-roboto`}>
            <div>
              <Button
                size="small"
                onClick={handleClickApplyFilter}
                rel="noopener"
                css={tw`mb-2 h-[52px]`}
                fullWidth
              >
                {isBusy ? (
                  <BloomsyLoader css={tw`w-4 h-4`} />
                ) : (
                  <span>APPLY</span>
                )}
              </Button>
            </div>
            <div
              css={tw`w-full flex justify-center mt-4 text-bloomsy-midgray text-sm tracking-normal leading-4`}
            >
              <a
                css={tw`underline underline-offset-2`}
                href={undefined}
                onClick={handleClickClearAll}
              >
                Clear All
              </a>
            </div>
          </div>
        </div>
      </StyledDrawer>
    </div>
  );
};
