import { FC } from "react";
import Image from "next/legacy/image";
import { IMAGE_QUALITY } from "src/constants";
import { ProductFieldsFragment } from "src/generated/datocms-types";
import FixedContainer from "src/shared/components/FixedContainer";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { ProductTag } from "src/shared/types";
import {
  monthlyPlanSlug,
  weekyPlanSlug,
  monthlyDealPlanSlug,
  slugsPrefixMap,
} from "src/services/datocms/constants";
import { FiRefreshCw } from "react-icons/fi";
import { SliderComponent } from "./SliderComponent";
import { ScrollSnapComponent } from "../ScrollSnapComponent";

type Props = {
  products: ProductFieldsFragment[];
  children?: React.ReactNode;
  isBestSellerComponent?: boolean;
};

// eslint-disable-next-line react/function-component-definition
export const SliderProducts: FC<Props> = ({
  products,
  children,
  isBestSellerComponent = true,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const hasEnoughSlides = products.length > 4;
  const hasMinProducts = products.length <= 2;
  const oneSlide = products.length === 4;
  const minWidth = !hasEnoughSlides && oneSlide ? "24%" : "32%";
  const productImageSize = {
    w: !isDesktop ? 300 : 340,
    h: !isDesktop ? 310 : 350,
  };
  const card = (product: ProductFieldsFragment) => {
    const productUrl = `${slugsPrefixMap.product}${product.slug}`;
    const hasStemsDescription = product.bouquetSize !== "-";
    const stemsValue = hasStemsDescription ? product.bouquetSize : "";
    const stems = hasStemsDescription && stemsValue?.concat(" stems");

    let description = "";
    if (isBestSellerComponent) {
      if (product.tags.length > 0 && product.isSubscription) {
        product.tags.forEach((tag: ProductTag) => {
          if (
            tag?.slug === monthlyPlanSlug ||
            tag?.slug === monthlyDealPlanSlug
          ) {
            description = tag.slug.replace("-", " ");
          } else if (tag?.slug === weekyPlanSlug) {
            description = tag.slug.replace("-", " ");
          }
        });
      }
      if (description) {
        description = hasStemsDescription
          ? `${description} | ${stems}`
          : description;
      } else description = stems || "";
    }
    const subscriptionImage = product.gallery[1] ?? product.gallery[0];
    const firstImage = product.isSubscription ? subscriptionImage : product.gallery[0];
    return (
      <>
        <div css={tw`lg:bg-[#FAF8F5]`}>
          <a key={product.id} href={productUrl}>
            <Image
              src={`${firstImage.url}?w=${productImageSize.w}&h=${productImageSize.h}&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`}
              width={productImageSize.w}
              height={productImageSize.h}
              quality={IMAGE_QUALITY}
              alt="product"
              css={tw`rounded-t-lg px-4`}
            />
          </a>
          <div css={tw`w-auto text-sm`}>
            <article
              css={tw`flex justify-center font-roboto font-normal text-base py-2 px-1 lg:mx-0 sm:mx-8 font-bold`}
            >
              <div>{product.title}</div>
            </article>
          </div>
        </div>
        {product.isSubscription && isBestSellerComponent && (
          <div>
            <span
              css={tw`flex justify-center capitalize font-roboto font-normal text-sm py-2 px-1 lg:mx-0 sm:mx-8`}
            >
              {description && <FiRefreshCw css={tw`block mr-1 mt-1`} />}
              {description}
            </span>
          </div>
        )}
      </>
    );
  };
  return (
    <>
        {children}
        {hasEnoughSlides ? (
              <SliderComponent
                products={products}
                isBestSellerComponent={isBestSellerComponent}
                  />
        ) : (
          <div css={tw`flex justify-start`}>
            <div
              css={[
                tw`flex`,
                oneSlide ? tw`w-full` : tw`w-3/4`,
                !hasMinProducts && tw`justify-between`,
              ]}
            >
              {products.map((product) => {
                return (
                  <div
                  css={[hasMinProducts && tw`mr-2`, { width: minWidth }]}
                  key={product.id}
                  >
                      {card(product)}
                  </div>
                  );
              })}
            </div>
          </div>)}
      </>
  );
};
