/* eslint-disable react/function-component-definition */
import { FeatureImageFullContentFragment } from "src/generated/datocms-types";
import { FC } from "react";
import Image from "next/legacy/image";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { fontType } from "src/ui/components/BlockFeatureImageFull/index";
import { IMAGE_QUALITY } from "src/constants";

const fontTitleFamilyMap = {
  roboto: tw`font-roboto text-bloomsy-black text-base leading-7 lg:(text-base text-bloomsy-darkgray leading-normal)`,
  condensed: tw`font-roboto text-sm`,
  playfair: tw`font-playfair`,
};
const fontSubsTitleFamilyMap = {
  roboto: tw`font-roboto text-bloomsy-black text-base leading-7 lg:(text-base text-gray-700 leading-normal)`,
  condensed: tw`font-roboto`,
  playfair: tw`font-playfair text-lg  mt-0 lg:mt-6`,
};

type FeatureImageFullProps = {
  data: FeatureImageFullContentFragment;
  fontStyle: fontType;
  hasDividers?: boolean;
};
export const FeatureImageFull: FC<FeatureImageFullProps> = ({
  data,
  fontStyle,
  hasDividers = false,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const imageSize = [
    !isDesktop ? { width: 75, height: 75 } : { width: 110, height: 110 },
  ];
  const renderImageOrSvg = () => {
    if (!data.image) return null;
    const isSvg = data.image.format === "svg";
    const imageSrc = isSvg ? data.image.url : `${data.image.url}?w=110`;
    return (
      <div css={[tw`relative mx-auto w-full h-full`, imageSize]}>
        <Image
          css={tw`block object-cover`}
          src={imageSrc}
          layout="fill"
          quality={IMAGE_QUALITY}
          unoptimized={isSvg}
          alt={data.image?.alt || ""}
        />
      </div>
    );
  };
  const titlefontFamilyStyle =
    fontTitleFamilyMap[fontStyle.title as keyof typeof fontTitleFamilyMap];

  const subtitlefontFamilyStyle =
    fontSubsTitleFamilyMap[
      fontStyle.subtitle as keyof typeof fontSubsTitleFamilyMap
    ];
  const hasDividerStyle = hasDividers
    ? tw`w-7/12 sm:w-1/3`
    : tw`w-full lg:w-1/4`;
  return (
    <div
      css={[
        tw`flex flex-wrap lg:(px-0 items-baseline) `,
        hasDividerStyle,
        !hasDividers && tw`my-4`,
      ]}
    >
      {data.image && <div css={tw`w-full`}>{renderImageOrSvg()}</div>}
      <div
        css={[
          hasDividers
            ? tw`pl-0 text-center w-full py-3`
            : tw`w-[316px] mx-auto text-center h-full lg:(text-center pl-0 w-5/6)`,
        ]}
      >
        <h3
          css={[
            titlefontFamilyStyle,
            tw`block normal-case lg:(mt-4 tracking-wider)`,
            hasDividers ? tw`mt-0 lg:mt-4` : tw`mt-1`,
          ]}
        >
          {data.title}
        </h3>
        <p css={[hasDividers && tw`px-0 sm:px-2`, subtitlefontFamilyStyle]}>
          {data.text}
        </p>
      </div>
    </div>
  );
};
