import wretch from "wretch";

export const getReCaptchaApi = () =>
  wretch("https://www.google.com/recaptcha/api")
    .headers({ Accept: `application/json` })
    .content("application/json");

export const reCaptchaApi = wretch("/api/reCaptcha")
  .content("application/json")
  .accept("application/json");
