/* eslint-disable react/function-component-definition */
import { FC, useEffect, useState } from "react";
import { ProductCollectionSingleFragment } from "src/generated/datocms-types";
import { CollectionProductsMap, FilterProduct } from "src/shared/types";
import { ProductCollectionSection } from "src/features/ProductCollectionSection";
import FixedContainer from "src/shared/components/FixedContainer";
import tw, { theme as twTheme } from "twin.macro";
import { useMediaQuery } from "@mui/material";
import { getFilterList, getFilterOption } from "src/shared/utils/products";

export type BlockProductCollectionProps = {
  data: ProductCollectionSingleFragment;
  collectionProducts: CollectionProductsMap;
};
export const BlockProductCollection: FC<BlockProductCollectionProps> = ({
  data,
  collectionProducts,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const xlUp = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const collections = data.collection ? [data.collection] : [];
  const isMobile = !isDesktop && !xlUp;
  const containerWidth = !isLargeDesktop ? "1200px" : "1360px";
  const [filterOptions, setFilterOptions] = useState<FilterProduct[]>([]);

  useEffect(() => {
    (async () => {
      const collectionsResult = data.collection ? [data.collection] : [];
      const filterList = await getFilterOption();
      const filterResult = getFilterList(
        collectionProducts,
        collectionsResult,
        filterList
      );
      setFilterOptions(filterResult);
    })();
  }, [collectionProducts, data.collection]);

  return (
    <FixedContainer
      CustomContainer
      css={[
        tw`text-center mt-4`,
        isMobile
          ? tw`container px-4 sm:px-0`
          : [tw`mx-auto px-0`, { maxWidth: !xlUp ? "980px" : containerWidth }],
      ]}
    >
      <ProductCollectionSection
        collectionProducts={collectionProducts}
        collections={collections}
        filterOptions={filterOptions}
      />
    </FixedContainer>
  );
};
