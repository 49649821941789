/* eslint-disable react/function-component-definition */
import * as React from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import tw, { theme as twTheme } from "twin.macro";
import { BlockInstagramProductFieldsFragment } from "src/generated/datocms-types";
import { getBasicFourSixtyscript } from "src/shared/utils";
import { useMediaQuery } from "@mui/material";
import { ContentSectionSubtitle } from "src/shared/components/ContentSection";
import {
  instagramProductSliderScript,
  instagramProductSliderStyle,
} from "./embed";

export type BlockFeatureInstagramProductProps = {
  data: BlockInstagramProductFieldsFragment;
};
export const BlockFeatureInstagramProduct: React.FC<
  BlockFeatureInstagramProductProps
> = ({ data }) => {
  const { title, subtitle } = data;
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const instagramStyle = `${instagramProductSliderStyle}`;
  const fourSixtyWidget = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (isDesktop) {
      const currentFourSixtyWidget = fourSixtyWidget.current;
      const script = getBasicFourSixtyscript();
      script.src = instagramProductSliderScript.src;
      script.setAttribute("data-theme", instagramProductSliderScript.dataTheme);
      script.setAttribute(
        "data-cell-size",
        instagramProductSliderScript.dataCellSize || ""
      );
      script.setAttribute(
        "data-has-posts-selector",
        instagramProductSliderScript.dataHasPostSelector || ""
      );
      script.setAttribute(
        "data-for-url",
        instagramProductSliderScript.dataForUrl || ""
      );
      fourSixtyWidget.current?.appendChild(script);
      return () => {
        currentFourSixtyWidget?.removeChild(script);
      };
    }
  }, [isDesktop]);
  if (!isDesktop) return null;
  return (
    <FixedContainer css={tw`text-center pt-1`}>
      <div>
        {title && (
          <h3
            css={tw`text-2xl sm:text-4xl font-roboto leading-tight px-4 sm:px-0`}
          >
            {title}
          </h3>
        )}
        {subtitle && (
          <ContentSectionSubtitle>{subtitle}</ContentSectionSubtitle>
        )}
        <div
          id="foursixty-hideable-container"
          ref={fourSixtyWidget}
          css={[tw`mt-2`, { display: "none" }]}
          dangerouslySetInnerHTML={{ __html: instagramStyle }}
        />
      </div>
    </FixedContainer>
  );
};
