import tw from "twin.macro";
import {
  FC,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import {
  Box,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Popper,
  PopperProps,
  Tab,
  Tabs,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { FiChevronDown } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { FilterProduct } from "../types";
import BloomsyLoader from "./BloomsyLoader";

const FILTER_TYPES = {
  COLOR: "Color",
  PRICE: "Price",
  SIZE: "Plant Size",
};

type Props = {
  filterOptions: FilterProduct[];
  setFilterListSelected: Dispatch<SetStateAction<FilterProduct | undefined>>;
  setRemoveFilterListSelected: Dispatch<
    SetStateAction<FilterProduct | undefined>
  >;
  currentFilterSelection: FilterProduct[];
  filterDisabledOptions: FilterProduct[];
};
type itemType = {
  type: string;
  id: number;
};
type SubMenuProps = {
  menuItem: itemType[];
  subMenuProps: SubMenuStateProps;
  filterOptions: FilterProduct[];
  setFilterListSelected: Dispatch<SetStateAction<FilterProduct | undefined>>;
  setRemoveFilterListSelected: Dispatch<
    SetStateAction<FilterProduct | undefined>
  >;
  currentFilterSelection: FilterProduct[];
  filterDisabledOptions: FilterProduct[];
};
export type SubMenuStateProps = {
  setSubMenuOpen: (status: boolean) => void;
  subMenuOpen: boolean;
  anchorEl: PopperProps["anchorEl"];
  tabName: string;
  container: PopperProps["container"];
};
type SubMenuItemsProps = {
  type: string;
  subMenuItems: FilterProduct[];
  handleSelectedOption: (option: FilterProduct) => void;
  currentFilterSelection: FilterProduct[];
  filterDisabledOptions: FilterProduct[];
};

const SubMenuOptionColor: FC<SubMenuItemsProps> = ({
  type,
  subMenuItems,
  handleSelectedOption,
  currentFilterSelection,
  filterDisabledOptions,
}) => {
  return (
    <>
      {subMenuItems
        .filter((f) => f.type === type)
        .map((option: FilterProduct) => {
          const isChecked = currentFilterSelection.some(
            (s) => s.tagId === option.tagId
          );
          const isDisabled = filterDisabledOptions.some(
            (s) => s.tagId === option.tagId
          );

          return (
            <div
              css={tw`w-full text-sm capitalize font-roboto p-0 h-[25px] mb-3`}
              key={`option ${option.tagId}`}
            >
              <span
                onClick={() => {
                  if (!isDisabled) handleSelectedOption(option);
                }}
                onKeyPress={() => {
                  if (!isDisabled) handleSelectedOption(option);
                }}
                role="button"
                tabIndex={0}
              >
                <div css={tw`flex cursor-pointer p-1`}>
                  <div
                    css={[
                      tw`w-5 h-5 rounded-full bg-gray-500 border`,
                      { backgroundColor: option.color },
                      isChecked &&
                        !isDisabled &&
                        tw`w-6 h-6 border-[2px] border-gray-600`,
                      isDisabled && tw`opacity-40`,
                    ]}
                  />
                  <div
                    css={[
                      tw`pl-2 text-sm capitalize pr-24`,
                      isChecked && tw`underline underline-offset-2`,
                      isDisabled && tw`text-gray-400`,
                    ]}
                  >
                    {option.filterName}
                  </div>
                </div>
              </span>
            </div>
          );
        })}
    </>
  );
};

const SubMenuOptionCheck: FC<SubMenuItemsProps> = ({
  type,
  subMenuItems,
  handleSelectedOption,
  currentFilterSelection,
  filterDisabledOptions,
}) => {
  return (
    <FormGroup>
      {subMenuItems
        .filter((f) => f.type === type)
        .map((option: FilterProduct) => {
          const isChecked = currentFilterSelection.some(
            (s) => s.tagId === option.tagId
          );
          const isDisabled = filterDisabledOptions.some(
            (s) => s.tagId === option.tagId
          );

          return (
            <FormControlLabel
              css={tw`w-full text-sm capitalize font-roboto p-0 h-[25px] mb-3`}
              key={option.tagId}
              control={
                <Checkbox
                  checked={!!isChecked}
                  onChange={() => {
                    if (!isDisabled) handleSelectedOption(option);
                  }}
                  color="default"
                  size="medium"
                  {...(isDisabled
                    ? {
                        disabled: true,
                        sx: {
                          color: grey[300],
                        },
                      }
                    : {
                        sx: {
                          "& .MuiSvgIcon-root": {
                            fontSize: 22,
                            color: "black",
                          },
                        },
                      })}
                />
              }
              label={
                <span css={[tw`capitalize`, isDisabled && tw`text-gray-400`]}>
                  {option.filterName}
                </span>
              }
              sx={{
                "& .MuiTypography-root": {
                  fontSize: 13.5,
                  color: "black",
                },
              }}
            />
          );
        })}
    </FormGroup>
  );
};

const SubMenu: FC<SubMenuProps> = ({
  menuItem,
  subMenuProps,
  filterOptions,
  setFilterListSelected,
  setRemoveFilterListSelected,
  currentFilterSelection,
  filterDisabledOptions,
}) => {
  const [isBusy, setIsBusy] = useState(false);
  const { tabName, anchorEl, setSubMenuOpen, subMenuOpen, container } =
    subMenuProps;

  const handleSelectedOption = (option: FilterProduct) => {
    setIsBusy(true);
    const isSelected =
      currentFilterSelection?.some((s) => s.tagId === option.tagId) ?? false;
    if (!isSelected) {
      setFilterListSelected(option);
    } else {
      setRemoveFilterListSelected(option);
    }

    setTimeout(() => {
      setIsBusy(false);
    }, 400);
  };
  const handleMenuOpen = () => {
    setSubMenuOpen(true);
  };
  const handleMenuClose = () => {
    setSubMenuOpen(false);
  };
  const filterPriority = (a: FilterProduct, b: FilterProduct) => {
    if (
      (a.priority ?? Number.MAX_SAFE_INTEGER) <
      (b.priority ?? Number.MAX_SAFE_INTEGER)
    ) {
      return -1;
    }
    if (
      (a.priority ?? Number.MAX_SAFE_INTEGER) >
      (b.priority ?? Number.MAX_SAFE_INTEGER)
    ) {
      return 1;
    }
    return 0;
  };

  const currentItemTab = menuItem.find((item) => {
    return item?.type?.toLowerCase().trim() === tabName.toLowerCase().trim();
  });

  let subMenuItems = filterOptions.filter(
    (sm) => sm.type === currentItemTab?.type
  );

  subMenuItems.sort(filterPriority);

  useEffect(() => {
    if (!currentItemTab) {
      setSubMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuItem, filterOptions]);

  if (!currentItemTab) {
    return null;
  }

  if (!menuItem) {
    return null;
  }

  return (
    <Popper
      className="popper-root"
      open={subMenuOpen}
      anchorEl={anchorEl}
      container={container}
      id="menu-filter"
      placement="bottom-start"
      css={[tw`z-50`]}
      sx={{
        width: "100%",
      }}
      onMouseEnter={() => handleMenuOpen()}
      onMouseLeave={() => handleMenuClose()}
    >
      <Box>
        <Paper key={currentItemTab.id}>
          <div
            css={tw`bg-bloomsy-white flex justify-between p-8 min-h-[290px]`}
          >
            <div>
              {tabName.toLowerCase().trim() ===
                FILTER_TYPES.COLOR.toLocaleLowerCase() && (
                <SubMenuOptionColor
                  type={FILTER_TYPES.COLOR}
                  subMenuItems={subMenuItems}
                  handleSelectedOption={handleSelectedOption}
                  currentFilterSelection={currentFilterSelection}
                  filterDisabledOptions={filterDisabledOptions}
                />
              )}
              {tabName.toLowerCase().trim() ===
                FILTER_TYPES.PRICE.toLocaleLowerCase() && (
                <SubMenuOptionCheck
                  type={FILTER_TYPES.PRICE}
                  subMenuItems={subMenuItems}
                  handleSelectedOption={handleSelectedOption}
                  currentFilterSelection={currentFilterSelection}
                  filterDisabledOptions={filterDisabledOptions}
                />
              )}
              {tabName.toLowerCase().trim() ===
                FILTER_TYPES.SIZE.toLocaleLowerCase() && (
                <SubMenuOptionCheck
                  type={FILTER_TYPES.SIZE}
                  subMenuItems={subMenuItems}
                  handleSelectedOption={handleSelectedOption}
                  currentFilterSelection={currentFilterSelection}
                  filterDisabledOptions={filterDisabledOptions}
                />
              )}
            </div>
            <div>
              <div css={tw`flex flex-col justify-between items-center h-full`}>
                <IconButton
                  onClick={() => setSubMenuOpen(false)}
                  css={tw`text-gray-500 h-0 px-1 hover:bg-transparent`}
                  size="large"
                >
                  <MdClose />
                </IconButton>
                {isBusy && (
                  <BloomsyLoader css={tw`w-6 h-6 text-bloomsy-darkred`} />
                )}
              </div>
            </div>
          </div>
        </Paper>
      </Box>
    </Popper>
  );
};

export const FilterDesktopComponent: FC<Props> = ({
  filterOptions,
  setFilterListSelected,
  setRemoveFilterListSelected,
  currentFilterSelection,
  filterDisabledOptions,
}) => {
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);
  const [container, setContainer] = useState<PopperProps["container"]>(null);
  const [tabValue, setTabValue] = useState(0);
  const [tabName, setTabName] = useState("");

  const handleMenuOpen = (index: number, name: string) => {
    setSubMenuOpen(true);
    setTabValue(index);
    setTabName(name);
  };
  const handleMenuClose = () => {
    setSubMenuOpen(false);
  };

  const handleScroll = useCallback(() => {
    setSubMenuOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const idElement = document.getElementById("filter-component");
    setAnchorEl(idElement);
    setContainer(idElement);
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const items: itemType[] = [];
  filterOptions.forEach((item) => {
    const type = item.type ?? "";
    const id = item.tagId;
    const exist = items.filter((i) => i.type.includes(type)).length > 0;
    if (!exist) {
      const newFilter = { type, id };
      items.push(newFilter);
    }
  });
  const subMProp: SubMenuStateProps = {
    setSubMenuOpen,
    subMenuOpen,
    anchorEl,
    tabName,
    container,
  };

  return (
    <div>
      <Tabs
        value={tabValue}
        TabIndicatorProps={{
          style: {
            height: 0,
          },
        }}
      >
        {items
          .sort((a, b) => (a.type > b.type ? 1 : -1))
          .map((i, idx) => {
            const isTheFirstEl = idx === 0;
            if (!i) return null;
            return (
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "black",
                  },
                }}
                css={[
                  isTheFirstEl ? tw`pl-0 pr-1` : tw`px-1`,
                  tw`w-1/4 block py-0 font-roboto tracking-tight uppercase border-solid border-transparent hover:(text-bloomsy-red border-red-500) min-w-0`,
                  {
                    borderBottomWidth: "3px",
                    fontSize: "0.85rem",
                    color: "#666666",
                  },
                  tabValue === idx && subMenuOpen && tw`border-red-500`,
                ]}
                key={i.id}
                onMouseEnter={() => handleMenuOpen(idx, i.type ?? "")}
                onMouseLeave={() => handleMenuClose()}
                data-key={idx}
                label={
                  <div
                    css={[
                      tw`flex items-center justify-center gap-2 font-roboto font-bold`,
                    ]}
                  >
                    {i.type}
                    <FiChevronDown css={tw`font-bold`} />
                  </div>
                }
                aria-owns={subMenuOpen ? "menu-filter-grow" : undefined}
                aria-haspopup="true"
              />
            );
          })}
      </Tabs>
      {filterOptions && (
        <SubMenu
          menuItem={items}
          subMenuProps={subMProp}
          filterOptions={filterOptions}
          setFilterListSelected={setFilterListSelected}
          setRemoveFilterListSelected={setRemoveFilterListSelected}
          currentFilterSelection={currentFilterSelection}
          filterDisabledOptions={filterDisabledOptions}
        />
      )}
    </div>
  );
};
