import { FC } from "react";
import tw from "twin.macro";
import FixedContainer from "src/shared/components/FixedContainer";
import { BlockFeatureImageTitleFieldsFragment } from "src/generated/datocms-types";
import { FeatureImageTitle } from "src/ui/components/BlockFeatureImageTitle/FeatureImageTitle";

export type BlockFeatureImageTitleProps = {
  data: BlockFeatureImageTitleFieldsFragment;
};
export const BlockFeatureImageTitle: FC<BlockFeatureImageTitleProps> = ({
  data: { content },
}) => {
  return (
    <FixedContainer>
      <div css={tw`flex flex-wrap mt-2 lg:mt-0`}>
        {content &&
          content.map((record) => {
            if (!record) return null;
            return <FeatureImageTitle data={record} key={record.id} />;
          })}
      </div>
    </FixedContainer>
  );
};
