import { useMutation } from "react-query";
import { corporateSalesRecord } from "src/features/CorporateSale/utils/types";
import { customerApiClient } from "../api/client";

export type UseAddRecordProps = {
  addRecord: corporateSalesRecord;
};
const mutationFn = (opts: UseAddRecordProps) => {
  return customerApiClient.url("/add-record").post(opts).json();
};
export const UseAddRecordMutation = () => {
  return useMutation(mutationFn);
};
