import { FC } from "react";
import NextLink from "next/link";
import tw from "twin.macro";
// import { useRouter } from "next/router";

type LinkProps = {
  isExternal: boolean;
  href: string;
};
const linkStyles = tw`text-inherit hover:text-bloomsy-red`;
const Link: FC<LinkProps> = ({ href, isExternal, children }) => {
  // const router = useRouter();
  // const isActive = router.pathname === link;
  return isExternal ? (
    <a css={linkStyles} target="_blank" rel="noopener noreferrer" href={href}>
      {children}
    </a>
  ) : (
    <NextLink href={href} passHref css={linkStyles}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      {children}
    </NextLink>
  );
};

export default Link;
