import { FeatureImageCardContentFragment } from "src/generated/datocms-types";
import { FC } from "react";
import { getInternalLink } from "src/shared/helpers";
import tw from "twin.macro";
import Link from "src/ui/components/BlockFeatureImageCard/Link";
import Image from "next/legacy/image";
import { SvgIcon } from "@mui/material";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IMAGE_QUALITY } from "src/constants";

type FeatureImageCardProps = {
  data: FeatureImageCardContentFragment;
};
export const FeatureImageCard: FC<FeatureImageCardProps> = ({ data }) => {
  const href = getInternalLink(data.link);
  const imageSrc = `${data.image?.url}?w=400&h=275&dpr=2`;
  return (
    <div css={tw`w-full lg:w-1/3 my-4 lg:px-4`}>
      <div css={tw`shadow-md rounded bg-white`}>
        <Link isExternal={false} href={href}>
          <div css={[tw`relative w-full flex justify-center`, { height: 275 }]}>
            <Image
              css={tw`object-contain`}
              src={imageSrc}
              layout="fill"
              quality={IMAGE_QUALITY}
              alt={data.image?.alt || ""}
            />
          </div>
          <p
            css={tw`flex p-4 items-center justify-between border-t border-solid border-gray-200`}
          >
            <span
              css={tw`block font-normal uppercase text-sm tracking-wider text-bloomsy-darkgray underline underline-offset-4`}
            >
              {data.title}
            </span>
            <SvgIcon
              css={tw`w-6 h-6 text-bloomsy-red`}
              component={HiArrowNarrowRight}
            />
          </p>
        </Link>
      </div>
    </div>
  );
};
