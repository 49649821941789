import { FC } from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import tw, { theme as twTheme } from "twin.macro";
import { BlockFeatureVideoFieldsFragment } from "src/generated/datocms-types";
import { useMediaQuery } from "@mui/material";

export type BlockFeatureVideoProps = {
  data: BlockFeatureVideoFieldsFragment;
};
export const BlockFeatureVideo: FC<BlockFeatureVideoProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const videoSize = { h: isDesktop ? 500 : 200 };
  return (
    <FixedContainer css={tw`text-center`}>
      {data.content?.map((video) => {
        return (
          <div key={video?.id} css={tw`mt-4 pt-4`}>
            <iframe
              title={video?.embeddedVideo?.title || ""}
              width="100%"
              height={videoSize.h}
              src={
                video?.embeddedVideo?.provider === "youtube"
                  ? video?.embeddedVideo?.url?.replace("watch?v=", "embed/")
                  : video?.embeddedVideo?.url || ""
              }
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        );
      })}
    </FixedContainer>
  );
};
