import { FeatureImageCardContentFragment } from "src/generated/datocms-types";
import { FC } from "react";
import { getInternalLink } from "src/shared/helpers";
import tw, { theme as twTheme } from "twin.macro";
import Link from "src/ui/components/BlockFeatureImageCard/Link";
import Image from "next/legacy/image";
import { SvgIcon, useMediaQuery } from "@mui/material";
import { HiArrowNarrowRight } from "react-icons/hi";
import { IMAGE_QUALITY } from "src/constants";

type FeatureRowImageCardProps = {
  data: FeatureImageCardContentFragment;
};
// eslint-disable-next-line react/function-component-definition
export const FeatureRowImageCard: FC<FeatureRowImageCardProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const href = getInternalLink(data.link);
  const imageSize = {
    w: 400,
    h: 400,
  };
  const imageSrc = `${data.image?.url}?w=${imageSize.w}&h=${imageSize.h}&dpr=2`;
  const renderImageCard = (hasLink = false) => {
    return (
      <div>
        <div
          css={[
            tw`relative w-full flex justify-center`,
            { height: imageSize.h },
          ]}
        >
          <Image
            css={tw`object-cover`}
            src={imageSrc}
            layout="fill"
            quality={IMAGE_QUALITY}
            alt={data.image?.alt ?? ""}
          />
        </div>
        {data.title && (
          <p
            css={tw`flex p-4 items-center justify-between border-t border-solid border-gray-200`}
          >
            <span
              css={tw`block font-roboto text-sm lg:text-base text-bloomsy-darkgray`}
            >
              {data.title}
            </span>
            {hasLink && (
              <SvgIcon
                css={tw`w-6 h-6 text-bloomsy-red`}
                component={HiArrowNarrowRight}
              />
            )}
          </p>
        )}
      </div>
    );
  };
  return (
    <div css={tw`w-full my-4 lg:w-1/3 lg:px-2`}>
      <div css={tw`shadow-sm rounded bg-white`}>
        {data.link ? (
          <Link isExternal={false} href={href}>
            {renderImageCard(true)}
          </Link>
        ) : (
          renderImageCard()
        )}
      </div>
    </div>
  );
};
