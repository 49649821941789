import tw, { styled } from "twin.macro";
import { InView } from "react-intersection-observer";

export const FlowerShopSection = styled.div([tw`pb-10 last:pb-0`]);
export const FlowerShopSectionTitle = styled.h2([
  tw`text-2xl font-bold font-playfair border-t border-b border-gray-200 py-2 mb-2`,
]);
export const FlowerShopSectionTitleH1 = styled.h1([
  tw`text-2xl font-bold font-playfair border-t border-b border-gray-200 py-2 mb-2`,
]);
export const FlowerShopSectionItems = styled(InView)([tw`flex flex-wrap`]);
