/* eslint-disable react/function-component-definition */
import { TitleTextFragment, TextFragment } from "src/generated/datocms-types";
import { FC } from "react";
import tw, { theme as twTheme } from "twin.macro";
import { ContentSectionSubtitle } from "src/shared/components/ContentSection";
import { DescriptionType } from "./index";

type DescriptionProps = {
  data: DescriptionType;
};
export const Description: FC<DescriptionProps> = ({
  data: { hasBulletPoints, hasDescriptionAsTable, data, textColorHex },
}) => {
  const renderBulletPoints = (bulletPoints: TitleTextFragment[]) => {
    return (
      <div>
        {bulletPoints &&
          bulletPoints.map((bp, index) => {
            const isLargeDecoration =
              index === 0 || index === bulletPoints.length - 1;
            return (
              <div key={bp.id} css={tw`relative mt-6`}>
                <div
                  css={[
                    isLargeDecoration ? tw`-left-20  w-24` : tw`-left-10  w-14`,
                    tw`absolute  border-b top-3`,
                    { borderColor: textColorHex },
                  ]}
                />
                <div css={[tw`flex flex-wrap`]}>
                  <div
                    css={[
                      tw`absolute list-item list-disc list-outside left-px -top-2`,
                      { fontSize: "24px" },
                      textColorHex
                        ? { color: textColorHex }
                        : tw` text-gray-700`,
                    ]}
                  />
                  <ContentSectionSubtitle
                    css={[
                      tw`ml-6`,
                      textColorHex
                        ? { color: textColorHex }
                        : tw`text-gray-700`,
                    ]}
                  >
                    {bp.title}
                  </ContentSectionSubtitle>
                </div>

                <div
                  css={[
                    { "&>p": tw`mb-4` },
                    tw`font-roboto max-w-3xl text-sm  text-gray-700 ml-6`,
                  ]}
                >
                  {bp.description}
                </div>
              </div>
            );
          })}
      </div>
    );
  };
  const renderRows = (rowData: TextFragment[]) => {
    return (
      <div
        css={[
          tw`w-full flex flex-wrap justify-between px-6 sm:px-12 md:px-32 lg:px-0 h-60 sm:h-96`,
        ]}
      >
        {rowData.length > 0 &&
          rowData.map((r, index) => {
            const idLastIndex = rowData.length - 1 === index;
            return (
              <div
                key={r.id}
                css={[
                  tw`w-full border-t-2 flex items-center`,
                  { borderColor: textColorHex },
                  idLastIndex && tw`border-b-2`,
                ]}
              >
                <span css={tw`pl-2 xl:pl-6 w-full text-center lg:text-left`}>
                  {r.text}
                </span>
              </div>
            );
          })}
      </div>
    );
  };
  return (
    <>
      {hasBulletPoints && renderBulletPoints(data as TitleTextFragment[])}
      {hasDescriptionAsTable && renderRows(data as TextFragment[])}
    </>
  );
};
